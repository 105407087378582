import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import MUIPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import {DebounceInput} from 'react-debounce-input';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import DateFnsUtils from '@date-io/date-fns';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';


import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Send from "@material-ui/icons/Send";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Help from "@material-ui/icons/Help";
import Undo from "@material-ui/icons/Undo";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import OpenInNew from "@material-ui/icons/OpenInNew";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"




// import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import AddAlert from '@material-ui/icons/AddAlert';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class UpdateMaterials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancel: [],
    loading:{materials:false, materials_search: false},
      requestMaterialAddition: false,
      currentlySelecting: "",
      // materialsSelection: "finalize", //yes, options ** DEVELPOPMERNT **
      materialsSelection: "yes", //yes, options
      families: [],
      audits:[],
      listings: [],
      listing: [],
      accepts: [],
      saveRequired: false,
      changes: {existing:[], removed: [], added:[]},
      family: [],
      suggestedPlace: [],
      formattedAddress:{},
      last_updated: {listing: {}, updated_url: ''},
      updateState: {current: "viewing_all", listing: {
        current: {},
        all: [],
        update_all: true,
        updated_all: false,

      }},
      errors: {},
      locations: [],
      edit_locations: [],
      location_updates: [],
      location_update_action: [],
      current_task: {completed: true},
      all_listings_completed: false,
      adding_location: false,
      listingSelect: "",
      locationSelect: "",
      programSelect: "",
      listing_name: "",
      enterAddressManually: false,
      new_url: '',
      open: false, suggestion: null ,
      selectedDate: new Date('2014-08-18T21:11:54'), 
      nothing_found: false,
      expanded: 'panel1',
      // setSelectedDate,
    //   !this.state.tasks.contacts[0].completed
      tasks: {listings: [
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
      ]},
      openDialog: false,
      completedAddress: false,
      materials: [],
      added_materials: [],
    };
    this.addUser = this.addUser.bind(this);
    this.getCurrentMaterials = this.getCurrentMaterials.bind(this);
    this.markAsValid = this.markAsValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.addProfile = this.updateProfile.bind(this);
  }
  
  loading = (what, value) => {
    
    let loading = this.state.loading
    console.log(loading)
    loading[what] = value;
    this.setState({loading:loading})

    console.log(this.state.loading)
  }
  backToAllFamilies = () =>{
    this.setState({family: []})
  }
  handleDateChange = date => {
    this.setState({selectedDate: date});
  };
  moveOnToMaterials = (value)=>{
    this.setState({materialsSelection: value})

  }
      
 
//  handleClose = () => {
//     onClose(selectedValue);
//   };

//  handleListItemClick = value => {
//     onClose(value);
//   };
addMaterial=(material)=>{
  console.log(material)

  // material.disabled = true
  let removedMaterial = this.state.materials
  removedMaterial.splice(material.index, 1)
  let materials = this.state.added_materials
  let accepts = this.state.accepts
  
  let changes = this.state.changes
  this.state.changes.added.push(material.material_id)


  materials.unshift(material)
  accepts.unshift({residential: 'none', business: 'none', notes: '', material_id: material.material_id, update: true})
  material.index = 0
  for(let i=1; i<materials.length;i++)
  materials[i].index = i
  this.setState({added_materials: materials, materials: removedMaterial, accepts: accepts, changes: changes, saveRequired: true})

  
  this.setState({ successAlert: true, alertMessage: material.description+" has been added." })
  setTimeout(()=>{this.setState({ successAlert: false}) 
  // this.props.back({completed: false})
}
  , 3000)
}

cancel = ()=>{
  
  // let removedMaterial = this.state.materials
  let materials = this.state.added_materials
  let accepts = this.state.accepts
  let changes = this.state.changes

  this.state.added_materials.forEach(()=>{

    accepts.shift()
    changes.added.pop()
    materials.shift()
  })
  
  
  for(let i=1; i<materials.length;i++)
  materials[i].index = i
  
  this.setState({accepts: accepts, changes: changes, addingMaterial: false})

}

removeMaterial=(material)=>{
  console.log(material)
  // material.disabled = true
  let removedMaterial = this.state.added_materials
  removedMaterial.splice(material.index, 1)
  let removedAccepts = this.state.accepts
  removedAccepts.splice(material.index, 1)


  for(let i=material.index; i<removedMaterial.length; i++)
  removedMaterial[i].index = removedMaterial[i].index - 1
  // let materials = this.state.materials
  // materials.push(material)
  let changes = this.state.changes
  this.state.changes.removed.push(material.material_id)
  this.setState({added_materials: removedMaterial, accepts: removedAccepts, changes: changes, saveRequired: true})
  
  this.setState({ dangerAlert: true, dangerMessage: material.description+" has been removed." })
  setTimeout(()=>{this.setState({ dangerAlert: false}) 
  // this.props.back({completed: false})
}
  , 3000)
}
  onClose=() =>{
    this.setState({ open: false })
  }
  enterAddressManually=() =>{
    // if(!this.state.enterAddressManually)
    this.setState({ enterAddressManually: !this.state.enterAddressManually, suggestedPlace: [], formattedAddress: {}, suggestion: null})
  }
  onSuggestionSelected=(suggestion) =>{
    // Add your business logic here. In this case we just log...
    this.setState({ currentlySelecting: "searching", open: true, suggestion })
    geocodeByPlaceID(suggestion.place_id).then((results) => {

      console.log(results)

      let formattedAddress = {}
      results[0].address_components.forEach(address_component =>{
        formattedAddress[address_component.types[0]] = {s: address_component.short_name, l:address_component.long_name}

      })
      this.setState({suggestedPlace: results, formattedAddress: formattedAddress})
      // Add your business logic here. In this case we simply set our state with the coordinates of
      // the selected suggestion...

      // Just use the first result in the list to get the geometry coordinates
      // const { geometry } = results[0]

      // const coordinates = {
      //   lat: geometry.location.lat(),
      //   lng: geometry.location.lng(),
      // }

      // this.setState({ coordinates })
    }).catch((err) => {
      // Handle any errors that occurred when we tried to get geospatial data for a selected
      // suggestion...
    })
    
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleFamily = event =>{
    let family_id = event.target.value
    this.setState({familySelect: family_id, materials:[]})
    this.getFamily(family_id)
  }
  
  handleListing = event =>{
    let listing_id = event.target.value
    this.setState({listingSelect: listing_id, materials:[]})
    this.getListing(listing_id)
  }
  resetLocation = (location) =>{
    
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'action_required'

    this.setState({location_update_action: location_update_action})
  }
  checkAllLocationsCompleted = ()=>{
    let completed = true;
    console.log(this.state.locations.length)
    for(let i = 0; i<this.state.locations.length; i++){
      console.log(this.state.locations[i].updated)
    if(!this.state.locations[i].updated)
      { console.log(this.state.locations[i])
        completed = false
      break;}
    }
    if(completed)
    this.setState({all_listings_completed: true})

  }
  backToListings = (updated_all) => {
    let updateState =  {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }}
    if(updated_all){
      this.state.locations[this.state.updateState.listing.current.index].updated = true
      this.checkAllLocationsCompleted()

      
    let location_update_action = this.state.location_update_action
    if(this.state.new_url !== "mixed")
    if(this.state.updateState.listing.all.length > 1)
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all to: ' + this.state.new_url
    else
    location_update_action[this.state.updateState.listing.current.index] = 'Updated to: ' + this.state.new_url
    else{
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all URLs individually'

    }

    this.setState({updateState: updateState, last_updated: {listing: this.state.updateState.listing.current, updated_url: this.state.new_url}, new_url: '', location_update_action: location_update_action})
  }

    else{
    this.setState({updateState: updateState, new_url: ''})}

  }
handleUpdateRadio = (id) =>{

  console.log(id)
  let accepts = this.state.accepts

  for(let i=0; i<accepts.length; i++){
    if(accepts[i].material_id == id){
      accepts[i].update = !accepts[i].update
    }
  }
  this.setState({accepts: accepts})
}
handleUpdateDetails = (id) =>{
  
    console.log(id)
    let added_materials = this.state.added_materials
  
    for(let i=0; i<added_materials.length; i++){
      if(added_materials[i].material_id == id){
        added_materials[i].more_details = !added_materials[i].more_details
      }
    }
    this.setState({added_materials: added_materials})
  }

  async getCurrentMaterials(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/`+this.props.listing.type+`/`+this.props.listing.uuid+`/materials`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    // let location_update_action = []
    for(let i = 0; i<taskRequestData.materials.length; i++){
      // if(taskRequestData.locations[i].updated)
      // location_update_action[i] = 'This listing(s) has been completed.'      
      // else
      // location_update_action[i] = 'action_required'
      taskRequestData.materials[i].index = i
      taskRequestData.materials[i].more_details = false
      taskRequestData.accepts[i].update = false
    }
    setTimeout(()=>{   this.setState({added_materials: taskRequestData.materials, accepts: taskRequestData.accepts, audits: taskRequestData.audits})
    this.loading('materials', false)}, 1000)
    if(taskRequestData.materials.length == 0)
    this.setState({addingMaterial: true})
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getListings(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listings`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    let outbound = []
    for(let i = 0; i < taskRequestData.listings.programs.length; i++){
      taskRequestData.listings.programs[i].type = "program"
      outbound.push(taskRequestData.listings.programs[i])
    }
    for(let i = 0; i < taskRequestData.listings.locations.length; i++){
      taskRequestData.listings.locations[i].type = "location"
      outbound.push(taskRequestData.listings.locations[i])
    }
    this.setState({currentlySelecting: "listings", listings: outbound, materials: []})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getListing(listing_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listing/`+listing_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({materials: taskRequestData.listing})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getFamilies(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/families/`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({currentlySelecting: "families", families: taskRequestData.families, materials: []})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getFamily(family_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/family/`+family_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({materials: taskRequestData.family})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  saveUpdates = async ()=>{
    
    // const fields = ["new_url"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/materials/`+ this.props.listing.uuid,
        {
          updates:  this.state.changes
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    // console.log(taskRequest.data)
    // this.backToListings(true)

    // let updateState = this.state.updateState
    // location = updateState.listing
    // location.updated_all = true
    // // location.url = formValues.new_url
    
    // this.setState({updateState: updateState})

    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    this.props.listing.updated = true
    this.props.back({listing: this.props.listing, completed: 'completed'})
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  updateAllIndividually = (value)=>{
    let updateState = this.state.updateState
    updateState.listing.update_all = value;
    this.setState({updateState: updateState})
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  componentDidMount(){
    this.loading('materials', true)//}, 1000)
    document.getElementById('topAppBar').scrollIntoView();
    this.getCurrentMaterials()
    // this.getProfileData()
    // let tasks = JSON.parse(localStorage.getItem('tasks'))
    // this.setState({tasks: tasks})
    
    // let listing_task = tasks['listings']
    // console.log(listing_task)

    // for(let i=0; i<listing_task.length; i++){
    //  if(!listing_task[i].completed){
    //     this.setState({current_task: listing_task[i]})
    //     // console.log("set current to", listing_task[i])
    //     // console.log(this.state)
    //     if(listing_task[i].task.id == 9){
    //       this.setState({adding_location: true})
    //     }else{
    //     this.getCurrentTaskData(listing_task[i]);
    //     }
    //     break;
    //  }
    // }

  }
  componentDidUpdate(prevProps, prevState, snapshot){
    // if(this.props.last_updated !== prevProps.last_updated && this.props.last_updated.listing.index !== null){
      
    // let location_update_action = this.state.location_update_action
    // location_update_action[this.props.last_updated.listing.index] = 'Updated all to: ' + this.props.last_updated.new_url
    // this.setState({location_update_action: location_update_action})
    // }

  }
  handleClose = () => {

    this.setState({openDialog: !this.state.openDialog})

    // setOpen(false);
    // setSelectedValue(value);
  };
  handlePanelChange = (panel) => {
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    this.setState({expanded: panel});
  }
  handleChange = (event) => {


    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    let accepts = this.state.accepts
    let target = event.target.name.split('_')
    
    let changes = this.state.changes
    if(target[0] == "residential"){
      for(let i=0; i<accepts.length; i++){
      if(accepts[i].material_id == target[1]){
      accepts[i].residential = event.target.value
      changes.existing.push({material_id: accepts[i].material_id, residential: event.target.value})
    }}
    }else{

      if(target[0] == "business"){
      for(let i =0; i<accepts.length; i++){
      if(accepts[i].material_id == target[1]){
      accepts[i].business = event.target.value
      
      changes.existing.push({material_id: accepts[i].material_id, business: event.target.value})}}
      }else{
        for(let i=0; i<accepts.length; i++){
        if(accepts[i].material_id == target[1]){
        accepts[i].notes = event.target.value
        changes.existing.push({material_id: accepts[i].material_id, notes: event.target.value})}}
      }
    }
    this.setState({[event.target.name]: event.target.value, accepts: accepts, changes: changes, saveRequired: true});
  }

  searchMaterials = async (event) => {
    event.preventDefault();
    this.loading("materials_search", true)
    this.setState({materials: [], currentlySelecting: "searching"})
    let taskRequest;
    try {
      taskRequest = await axios.get(
        
        `${REACT_APP_SERVER_URL}/v1/materials?q=`+event.target.value,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        // {
          // url: location.url
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    console.log(taskRequestData)
    let nothing_found = false
    if(taskRequestData.materials.length == 0)
    nothing_found = true
    this.setState({materials: taskRequestData.materials, nothing_found: nothing_found})
    
    this.loading("materials_search", false)
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    // this.setState({[event.target.name]: 
    
      
      // https://search.earth911.com/suggest/materials/?q=%231
  }
  updateEmail = async(e) => {
    e.preventDefault();
    const { history } = this.props;

    const fields = ["new_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/profile/change_email`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    if(registerRequest.status > 205){
        console.log(registerRequestData) // do something here
    }else{
        
        apiStoreHeaders(registerRequest.headers)
        return history.push("/update/earth911");
        
    }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  async addUser(e) {
    e.preventDefault();

    const fields = ["first_name", "last_name", "added_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/profile/add_user`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;

    if (!registerRequestData.success) {
      this.setState({
        errors:
          registerRequestData.messages && registerRequestData.messages.errors
      });
    }
  }
   markTaskAsValid = async () => {
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/task`,
        {
          task: {category: 'listing', id: this.state.current_task.task.id, completed: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

    // console.log(taskRequest)
    
    // const { data: taskRequestData } = taskRequest;
    this.state.tasks.listings[this.state.current_task.task.id-4].completed = true
    
    let all_tasks_completed = true
    for(let i=this.state.current_task.task.id-4; i<this.state.tasks.listings.length; i++){
      if(!this.state.tasks.listings[i].completed){
        this.resetAllData()
        all_tasks_completed = false
        this.setState({current_task: this.state.tasks.listings[i]})
        this.getCurrentTaskData(this.state.tasks.listings[i])   
        break;
      }
    }
    
    const { history } = this.props;
    if(all_tasks_completed)    
    history.push("/update/earth911");
    // console.log(taskRequestData)


    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  resetAllData = () => {
    this.setState({
    last_updated: {listing: {}, updated_url: ''},
    updateState: {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }},
    locations: [],
    edit_locations: [],
    location_updates: [],
    location_update_action: [],
    all_listings_completed: false,    
    new_url: ''})
  }
  updateLocation = async (e, location) => {
    
    const fields = ["new_url"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));
    e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.uuid,
        {
          location:  formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)

    let updateState = this.state.updateState
    location = updateState.listing.all[location.index]
    location.updated = true
    location.url = formValues.new_url
    
    this.setState({updateState: updateState})

    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    let completed = true
    for(let i=0; i<updateState.listing.all.length; i++)
    {
      if(updateState.listing.all[i].updated == false)
        completed = false
    }
    if(completed){
      if(updateState.listing.all.length == 1)
      this.setState(formValues)
      else
      this.setState({new_url: "mixed"})
      
      this.backToListings(true)

    }
    // const { data: taskRequestData } = taskRequest;

    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  markAsValid = async (location) => {
    // console.log("marked as valid", location, index)
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'Marked as Valid (no further updates to listings will be made)'
    let locations = this.state.locations
    locations[location.index].updated = true
    this.setState({location_update_action: location_update_action, locations: locations})
    
    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.uuid,
        {
          location:  {markAsValid: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)
    apiStoreHeaders(taskRequest.headers)
    this.checkAllLocationsCompleted()

  }
  updateURL = async (location) => {
    // console.log("marked as valid", location, index)
    // let location_update_action = this.state.location_update_action
    // location_update_action[location.index] = 'Update'
    // this.setState({location_update_action: location_update_action})
    let updateState = this.state.updateState
    updateState.current = 'editing'
    updateState.listing.current = location
    console.log(location)
    if(location.effects_count == "1")
    {
      
      updateState.listing.update_all = false
      console.log(updateState)
      
      // last_updated: {listing: {}, updated_url: ''},
      // updateState: {current: "viewing_all", listing: {
      //   current: {},
      //   all: [],
      //   update_all: true,
      //   updated_all: false,

      // }},


    }
    

    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.uuid,
        // {
          // url: location.url
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    console.log(taskRequestData)
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

    // const { data: taskRequestData } = taskRequest;
    // let location_update_action = []
    for(let i = 0; i<taskRequestData.locations.length; i++){
      // location_update_action[i] = 'action_required'
      taskRequestData.locations[i].index = i
      taskRequestData.locations[i].updated = false
    }

    updateState.listing.all = taskRequestData.locations
    this.setState({updateState: updateState})


  }
  viewMaterials=(location) =>{
    
    const { history } = this.props;
    history.push('/materials/'+location.location_id)

  }
  getProfileData = async e => {
    apiSetupHeaders()
    // e.preventDefault();

    // const { history } = this.props;

    // // const fields = ["email", "password"];
    // // const formElements = e.target.elements;

    // // const formValues = fields
    // //   .map(field => ({
    //     // [field]: formElements.namedItem(field).value
    // //   }))
    // //   .reduce((current, next) => ({ ...current, ...next }));

    // if(!apiSetupHeaders()){
    //     return history.push("/auth/login-page");
    // }

    let profileRequest;
    
    try {
      profileRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/profile`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    if (profileRequest.status == 200) {
        // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        this.setState({profile: profileRequestData.profile})
    }else{
        // return history.push("/auth/login-page");
    }
    this.setState({
    //   errors: loginRequestData.messages && loginRequestData.messages.errors
    });
  };
  requestMaterial = async () =>{
    let mn = document.getElementById('material-name').value
    let md = document.getElementById('material-description').value
    
    this.setState({requestMaterialAddition: false})
    let payload = {material:{description: md, name:mn}}
    let taskRequest;
    try {
      taskRequest = await axios.post(
        
        `${REACT_APP_SERVER_URL}/v1/material`,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        {
          payload
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    this.setState({ successAlert: true, alertMessage: "The new material request is being processed. Thank you." })
    setTimeout(()=>{this.setState({ successAlert: false}) 
    // this.props.back({completed: false})
  }
    , 3000)

    // const { data: taskRequestData } = taskRequest;
    // console.log(taskRequestData)
    // let nothing_found = false
    // if(taskRequestData.materials.length == 0)
    // nothing_found = true
    // this.setState({materials: taskRequestData.materials, nothing_found: nothing_found})
  }
  handleSimpleResidential = event => {
    let changes = this.state.changes

    let am = this.state.accepts
    am.forEach((m)=>{
      m.residential = event.target.value
      changes.existing.push({material_id: m.material_id, residential: event.target.value})    
    })
    this.setState({ [event.target.name]: event.target.value, accepts: am, changes: changes, saveRequired: true, residentialSelect: ''})
  };
  handleSimpleBusiness = event => {
    let changes = this.state.changes
    let am = this.state.accepts
    am.forEach((m)=>{
      m.business = event.target.value      
      changes.existing.push({material_id: m.material_id, business: event.target.value})    
    })
    this.setState({ [event.target.name]: event.target.value, accepts: am, changes: changes, saveRequired: true, businessSelect: ''})
  };
  
addAll=()=>{
  // console.log(material)
  // his.state.changes.added.push(material.material_id)
  
  
  //   materials.unshift(material)
  //   accepts.unshift({residential: 'none', business: 'none', notes: '', material_id: material.material_id, update: true})
  // material.disabled = true
  let accepts = this.state.accepts
  let removedMaterial = this.state.materials
  let changes = this.state.changes
  // removedMaterial.splice(material.index, 1)
  let materials = this.state.added_materials
  for(let i=0; i<removedMaterial.length; i++)
  {
    materials.unshift(removedMaterial[i])
    removedMaterial.index = materials.length - 1
    accepts.unshift({residential: 'none', business: 'none', material_id: removedMaterial[i].material_id, notes: ''})
    changes.added.push(removedMaterial[i].material_id)
  }
  
  
  this.setState({added_materials: materials, materials: [], listingSelect: 0, accepts: accepts, changes: changes})
  
  this.setState({ successAlert: true, alertMessage:"All materials have been added." })
  setTimeout(()=>{this.setState({ successAlert: false}) 
  // this.props.back({completed: false})
}
  , 3000)
}
  render() {
    const { classes, name, email } = this.props;
    const { errors, locationSelect, programSelect, listingSelect,  open, suggestion, formattedAddress, selectedDate } = this.state
    let newly_added_materials = []
    let listing_type = ""
      if(listingSelect == "1"){
        listing_type = "A location is a recycling opportunity with a permanent address that allows drop-off service at least once per month. Examples include transfer stations, retailers and 24-hour recycling bins in high-traffic areas."
      }else{
        // if(simpleSelect == "1")
      if(listingSelect == "2")
          listing_type = "A program is a recycling opportunity that does not meet all of the requirements of a location. Examples include curbside programs, mail-in programs, mobile collection opportunities and seasonal collection events."
          // else          
          // listing_type = "National recycling opportunity via mail."
      

      }
      let location_types = [
        "Commercial: Operated by a for-profit business that accepts from public.",
        "Government: Operated with city, county or regional government funding.",
        "Household Hazardous Waste: Site open at least once per month that accepts materials such as paint or batteries.",
        "Non-Profit: Operated by non-profit or community-based organization, such as a church or school.",
        "National Retail: Operated by a retailer that exists in 20 or more U.S. states.",
        "Regional Retail: Operated by a retailer that exists in less than 20 U.S. states.",
        "Unstaffed Bin: Hauler-operated bin placed in high-traffic parking lot for drop-offs of material like paper and clothing.",
        "Christmas Tree Events: Seasonal drop-off event for Christmas trees.",
        "ABOP: Short for Antifreeze, Batteries, Oil, Paint, these drop-off sites provide disposal for the recyclable forms of HHW.",
        "Location Type Not Listed: Please use the form below to suggest a new Location type.",
      ]
    let location_type = ""
    if(locationSelect !== "") 
    location_type = location_types[locationSelect]
    let program_types = [
      "Resource Contact Information: Reserved for state DEQ listings and other solid waste contact listings.",
      "Other: Reserved for programs that don’t fit another category, such as a company with drop-off bins where we’re unable to obtain the location of bins, or events until we have the event system active.	",
      "Government Packaging Curbside: A program operated with city, county or regional government funding that accepts packaging materials, such as glass, metal, paper or plastic (defaults as first listing in search results).",
      "Household Hazardous Waste: A program operated with city, county or regional government funding that collects household hazardous waste, either through a series of events or mobile collection.",
      "Mail-in: National recycling opportunity via mail (shows up for all material searches regardless of location).",
      "Commercial Curbside: Operated by a for-profit business that collects from public.",
      "Government Organics Curbside: A program operated with city, county or regional government funding that accepts organic materials, such as yard waste or food waste (defaults as first listing in search results).",
      "Government Christmas Tree Curbside: A program operated with city, county or regional government funding that accepts Christmas trees at the curb (defaults as first listing in search results).",
      "Government Bulky Waste Curbside: A program operated with city, county or regional government funding that accepts bulky waste at the curb, such as furniture, appliances or electronics (defaults as first listing in search results).",
      "Program Type Not Listed: Please use the form below to suggest a new Program type"
    ]
  let program_type = ""
  if(programSelect !== "") 
  program_type = program_types[programSelect]

    // console.log(this.state.tasks.contacts[0].completed)
    // console.log(this.state.tasks.contacts[1].completed)
    // console.log(this.state.tasks.contacts[0].completed)
    let edit_locations = [[]]
    let families = []

      
              
    let listings_select = []
    if(this.state.listings.length > 0){
          // console.log(this.state.families)
          let loc_index = 0
            listings_select = 
            this.state.listings.map((listing)=>{
              if(listing !== null){
              return [
              <MenuItem
      classes={{
        root: classes.selectMenuItem,
        selected: classes.selectMenuItemSelected
      }}
      value={listing.uuid}
    >
      {listing.description + " - " + listing.city}
    </MenuItem>]
              }})}


let families_select = []
    if(this.state.families.length > 0){
          console.log(this.state.families)
          let loc_index = 0
            families_select = 
            this.state.families.map((material)=>{
              if(material !== null){
              return [
              <MenuItem
      classes={{
        root: classes.selectMenuItem,
        selected: classes.selectMenuItemSelected
      }}
      value={material.family_id}
    >
      {material.description}
    </MenuItem>]
              }})}

    
        if(this.state.families.length > 0){
          console.log(this.state.families)
          let loc_index = 0
            families = 
            this.state.families.map((material)=>{
              // let image = ""
                  loc_index += 1
              if(material !== null){
                
              material.index = loc_index-1
               let image = "http://manage.earth911.com/media/"+material.image
                return [
                  // families.description,
                  // families.long_description
                  <Card className={classes.card} key={material}>
                  <CardActionArea>
                    <CardMedia
                      style={{width:"200px",height:"200px"}}
                      className={classes.media}
                      image={image}
                      title={material.description}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                      {material.description}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      {material.long_description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button color="warning" onClick={()=>this.getFamily(material.family_id)}>
                      View Materials
                    </Button>
                    {/* <Button size="small" color="primary">
                      Learn More
                    </Button> */}
                  </CardActions>
                </Card>
                ]
              }
                
              })
              console.log(families)
            }
            let family = []
            
                if(this.state.family.length > 0){
                  console.log(this.state.family)
                  let loc_index = 0
                    family = 
                    this.state.family.map((material)=>{
                      // let image = ""
                          loc_index += 1
                      if(material !== null){
                        
                      material.index = loc_index-1
                       let image = "http://manage.earth911.com/media/"+material.image
                        return [
                          // family.description,
                          // family.long_description
                          <Card className={classes.card} key={material}>
                          <CardActionArea>
                            <CardMedia
                              style={{width:"200px",height:"200px"}}
                              className={classes.media}
                              image={image}
                              title={material.description}
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h5" component="h2">
                              {material.description}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" component="p">
                              {material.long_description}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                            <Button color="warning" onClick={()=>this.addMaterial(material)}>
                              Add Material
                            </Button>
                            {/* <Button size="small" color="primary">
                              Learn More
                            </Button> */}
                          </CardActions>
                        </Card>
                        ]
                      }
                        
                      })
                      console.log(family)
                    }

    let materials = []

    if(this.state.materials.length > 0){
      console.log(this.state.materials)
      let loc_index = 0
        materials = 
        this.state.materials.map((material)=>{
          // let image = ""
              loc_index += 1
          if(material !== null){
            
          material.index = loc_index-1
           let image = "http://manage.earth911.com/media/"+material.image
            return [
              // materials.description,
              // materials.long_description
              <Card className={classes.card} key={material}>
              <CardActionArea>
                <CardMedia
                  style={{width:"200px",height:"200px"}}
                  className={classes.media}
                  image={image}
                  title={material.description}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                  {material.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                  {material.long_description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button color="warning" onClick={()=>this.addMaterial(material)}>
                  Add Material
                </Button>
                {/* <Button size="small" color="primary">
                  Learn More
                </Button> */}
              </CardActions>
            </Card>
            ]
          }
            
          })
          console.log(materials)
        }
        let added_materials = []
        
            if(this.state.added_materials.length > 0){
              // console.log(this.state.materials)
              let loc_index = 0
                added_materials = 
                this.state.added_materials.map((material)=>{
                  // let image = ""
                  if(material !== null){
                   let image = "http://manage.earth911.com/media/"+material.image
                      loc_index += 1
                    return [
                      materials.description,
                      // materials.long_description
                    //   <Card className={classes.card} key={material}>
                    //   <CardActionArea>
                    //     <CardMedia
                    //       style={{width:"200px",height:"200px"}}
                    //       className={classes.media}
                    //       image={image}
                    //       title={material.description}
                    //     />
                    //     <CardContent>
                    //       <Typography gutterBottom variant="h5" component="h2">
                    //       {material.description}
                    //       </Typography>
                    //       <Typography variant="body2" color="textSecondary" component="p">
                    //       {material.long_description}
                    //       </Typography>
                    //     </CardContent>
                    //   </CardActionArea>
                    //   <CardActions>
                    //     <Button color="danger" onClick={()=>this.removeMaterial(material)}>
                    //      Remove Material
                    //     </Button>
                    //     {/* <Button size="small" color="primary">
                    //       Learn More
                    //     </Button> */}
                    //   </CardActions>
                    // </Card>
                    ]
                  }
                    
                  })
                  console.log(materials)
                }


             let added_materials_table = []    
    if(this.state.added_materials.length > 0){
      let loc_index = 0
        added_materials_table = 
        this.state.added_materials.map((location)=>{
          let added_material_residential = "residential_"+location.material_id
          let added_material_business = "business_"+location.material_id
          let added_material_notes= "notes_"+location.material_id
          let image = "http://manage.earth911.com/media/"+location.image
          let suggestion = 'Special Notes'
          let placeHolder = location.description+" is only accepted if.."
              loc_index += 1
            return [ 
              <span>
                
                {location.description}
                <Button color="warning" onClick={()=>{this.handleUpdateDetails(location.material_id)}} simple={true}>
                {location.more_details ? "Less Details": "More Details"}
                 </Button>
              
                 </span>,

              ,
                                     <span>      
                                     { !location.more_details &&   
                                       <Button color="danger" onClick={()=>this.removeMaterial(location)} simple={true}>
                  Remove
                 </Button>
                                     }
                 { location.more_details &&
              <Card className={classes.card} key={locations}>
              <CardActionArea>
                <CardMedia
                  style={{width:"200px",height:"200px"}}
                  className={classes.media}
                  image={image}
                  title={location.description}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                  {location.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                  {location.long_description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button color="danger" onClick={()=>this.removeMaterial(location)}>
                 Remove
                </Button>
                {/* <Button size="small" color="primary">
                  Learn More
                </Button> */}
              </CardActions>
            </Card>
              
              }
                 </span>,
                                <span>
                                  
                                  { !this.state.accepts[loc_index-1].update &&
                                  <span>
                                    <Button onClick={()=>this.handleUpdateRadio(location.material_id)} simple={true} color="warning"> Update</Button>
                                    Residential: {this.state.accepts[loc_index-1].residential}
                                     /  Business: {this.state.accepts[loc_index-1].business}
                                    </span>
                                  }
                                  { this.state.accepts[loc_index-1].update &&
                                  <div>
                            <FormControl component="fieldset" className={classes.formControl}>
                            Residential
        <RadioGroup aria-label="residential option" name={added_material_residential} value={this.state.accepts[loc_index-1].residential } onChange={this.handleChange}>
          <FormControlLabel value="none" control={<Radio />} label="None" />
            <FormControlLabel value="pickup" control={<Radio />} label="Pick-up" />
          <FormControlLabel value="dropoff" control={<Radio />} label="Drop-off" />
          <FormControlLabel value="both" control={<Radio />} label="Both" />
        </RadioGroup>
      </FormControl>
      </div>
                                 }
                                              
                                  </span>
                                    ,
                                    <span>
                                       { this.state.accepts[loc_index-1].update &&
                                         
                                  <div>
                                <FormControl component="fieldset" className={classes.formControl}>
                                Business
            <RadioGroup aria-label="business option" name={added_material_business} value={this.state.accepts[loc_index-1].business} onChange={this.handleChange}>
            <FormControlLabel value="none" control={<Radio />} label="None" />
            <FormControlLabel value="pickup" control={<Radio />} label="Pick-up" />
            <FormControlLabel value="dropoff" control={<Radio />} label="Drop-off" />
            <FormControlLabel value="both" control={<Radio />} label="Both" />
            </RadioGroup>
          </FormControl>
                                  </div>
                                  }   
                                  </span>,
                                    <GridItem xs={12} sm={12} md={8} lg={6}>
                                    <DebounceInput
           style={{width:"300px", margin: "30px"}}
           minLength={2}
           debounceTimeout={1500}
           element={TextField}
           id="outlined-multiline-static"
          label={suggestion}
          multiline
          rows="4"
          name={added_material_notes}
          fullWidth={true}
          placeholder={placeHolder}
          onChange={this.handleChange}
          value={this.state.accepts[loc_index-1].notes}
          variant="outlined"
           /* element={CustomInput} */
           />
                                    {/* <TextField
          id="outlined-multiline-static"
          label={suggestion}
          multiline
          rows="4"
          name={added_material_notes}
          fullWidth={true}
          placeholder={placeHolder}
          onChange={this.handleChange}
          defaultValue={this.state.accepts[loc_index-1].notes}
          variant="outlined"
        /> */}
                                    {/* <CustomInput
                                      labelText={suggestion}
                                      id="location-name"
                                      rowsMax="3"                                      
                                      fullWidt={true}
                                      error={errors.username}
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        
                                      multiline
                                        type:"text-area",
                                        // disabled: true,
                                        required: true,
                                        defaultValue: this.state.accepts[loc_index-1].notes,
                                        name: "location-type-description"
                                      }}
                                    /> */}
                                  </GridItem>
            ]
          })
        }
    
    if(this.state.updateState.listing.all.length > 0){
      let loc_index = 0
        edit_locations = 
        this.state.updateState.listing.all.map((location)=>{
              
              loc_index += 1
            return [ 
              location.description, location.city, //location.status_id, 
           
              //  <span>
               
              //          <a style={{"color":"red"}} target="_blank"
              //                  href={location.url}>
              //          {location.url}
                       
              //          </a>
              //                </span>,
              // location.effects_count,
                              // <span style={{"color":"blue"}} >
                              
                              //                 {this.state.location_updates[loc_index-1]}
                              //               </span>,
                                            
                              //   <span>
                              //                 {this.state.location_update_action[loc_index-1] == "Update" && 
                                              
                                <span>
                                  {this.state.updateState.listing.update_all &&
                      <CustomInput
                        labelText="New URL"
                        id="new_url"
                        color="success"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.new_url,
                          disabled: this.state.updateState.listing.update_all ? true : false,
                          // required: true,
                          // defaultValue: "",
                          name: "new_url",
                        }}
                      />
                                  }
                          {!this.state.updateState.listing.update_all && !location.updated &&
                          
            <form onSubmit={(e)=>this.updateLocation(e, location)}>
                      <CustomInput
                        labelText="Update URL"
                        id="new_url"
                        color="warning"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // value: this.state.new_url,
                          // disabled: this.state.updateState.listing.update_all ? true : false,
                          // required: true,
                          // defaultValue: "",
                          name: "new_url",
                        }}
                      />
                      <Button color="primary" type="submit" aria-label="Update" className={classes.tableActionButton}> Update
                                                    </Button>
                                                    
                                                    </form>
                                  }
                                   {!this.state.updateState.listing.update_all && location.updated &&
                          
                      <CustomInput
                        labelText="New URL"
                        id="new_url"
                        color="warning"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: "Updated to: "+location.url,
                          disabled: true,
                          // required: true,
                          // defaultValue: "",
                          name: "new_url",
                        }}
                      />
                                                    
                                  }
                                                    
                                </span>
                                ,
                                            
                                            // }
                                            
                              // <span>
                              
                              //                 {this.state.location_update_action[loc_index-1] == "action_required" ? "" : this.state.location_update_action[loc_index-1]}
                              //               </span>

                              //               </span>
                              //               ,
                                            
                                            
                              //               <span>
                                            
                              //               {this.state.location_update_action[loc_index-1] != "action_required" &&            
                              //                <Tooltip
                              //                       id="tooltip-top"
                              //                       title="Undo"
                              //                       placement="top"
                              //                       classes={{tooltip:classes.tooltip}}>
                              //                       <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}>
                              //                         <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                              //                       </IconButton>
                              //                     </Tooltip>
                                                  
                              //               }
                                                            
                              //               {this.state.location_update_action[loc_index-1] == 'action_required' &&   
                              //               <Tooltip
                              //                       id="tooltip-top"
                              //                       title="Agree with action we took."
                              //                       placement="top"
                              //                       classes={{tooltip:classes.tooltip}}>
                              //                       <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}>
                              //                         <Check className={classes.tableActionButtonIcon + " " + classes.edit}/>
                              //                       </IconButton>
                              //                     </Tooltip>
                              //               }
                                                  
                              //                     </span>,
                              //                  <span>
                                               
                                                  
                              //               {this.state.location_update_action[loc_index-1] == "action_required" &&                  
                                                               
                              //                  <Tooltip
                              //                          id="tooltip-top"
                              //                          title="Update"
                              //                          placement="top"
                              //                          classes={{tooltip:classes.tooltip}}>
                                                      
                              //                          <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.updateURL(location)}>
                              //                            <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                              //                          </IconButton>
                                                       
                              //                        </Tooltip>
                              //               }
                                                     
                              //                        </span>,
                              <span>
                              
                      <Button onClick={this.viewMaterials(location)} color="primary" simple={true} aria-label="View" className={classes.tableActionButton}> View Materials
                      </Button>
                                            </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    let locations = [[]];
    if(this.state.locations.length > 0){
      let loc_index = 0
        locations = 
            this.state.locations.map((location)=>{
              
              loc_index += 1
              let updated_msg = ""
              if(this.state.location_updates[loc_index-1] == "Removed URL")
              {
                updated_msg = <span style={{"color":"red"}} >Removed the URL</span>
              }
              else{
                if(this.state.location_updates[loc_index-1] == "200")
                updated_msg = "No change committed"
                else
                updated_msg = "Updated to: " + this.state.location_updates[loc_index-1]
              }
            return [ 
              // location.description, location.city, location.status_id, 
           
               <span>
               
                       <a style={{"color":"red"}} target="_blank"
                               href={location.url}>
                       {location.url}
                       
                       </a>
                             </span>,
              location.effects_count,
                              <span style={{"color":"green"}} >
                              {updated_msg}
                                            </span>,
                                            
                                <span>
                                              {this.state.location_update_action[loc_index-1] == "Update" && 
                                              
                                <span>
                      <CustomInput
                        labelText="New URL"
                        id="new_url"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          // defaultValue: "",
                          name: "new_url",
                        }}
                      />
                      
                      <Button color="success" aria-label="Update" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}> Save
                                                    </Button>
                                                    
                                </span>
                                            
                                            }
                                            
                              <span>
                              
                                              {this.state.location_update_action[loc_index-1] == "action_required" ? "" : this.state.location_update_action[loc_index-1]}
                                            </span>

                                            </span>
                                            ,
                                            
                                            
                                            <span>
                                            
                                            {this.state.location_update_action[loc_index-1] != "action_required" &&            
                                             <Tooltip
                                                    id="tooltip-top"
                                                    title="Undo"
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetLocation(location)}>
                                                      <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                    </IconButton>
                                                  </Tooltip>
                                                  
                                            }
                                                            
                                            {this.state.location_update_action[loc_index-1] == 'action_required' &&   
                                            <Tooltip
                                                    id="tooltip-top"
                                                    title="Agree with action we took."
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}>
                                                      <Check className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                    </IconButton>
                                                  </Tooltip>
                                            }
                                                  
                                                  </span>,
                                               <span>
                                               
                                                  
                                            {this.state.location_update_action[loc_index-1] == "action_required" &&                  
                                                               
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="Update"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                      
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.updateURL(location)}>
                                                         <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                     </Tooltip>
                                            }
                                                     
                                                     </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    return (
      <div>
        
        <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message={this.state.alertMessage}
        open={this.state.successAlert}
        closeNotification={() => this.setState({ successAlert: false })}
        close
      />

      <Snackbar
                    place="tr"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.dangerMessage}
                    open={this.state.dangerAlert}
                    closeNotification={() => this.setState({ dangerAlert: false })}
                    close
                  />
                  <Dialog onClose={()=>this.setState({viewUpdatesDialog: false})} aria-labelledby="simple-dialog-title" open={this.state.viewUpdatesDialog}>
      <DialogTitle id="simple-dialog-title">Material Updates</DialogTitle>
      <List>
        {this.state.audits.map( listing_change=> {          

          let when = listing_change.date          
          let from = listing_change.user
          let what = []
          if(listing_change.action == "update")
          for(let change in listing_change.changes){
            console.log(change)
            console.log(listing_change.changes[change])
            what.push(
          <ListItem key={listing_change.date}> Changed {listing_change.what}: <span style={{fontWeight: "bold"}}>{change}</span></ListItem>)
          what.push(
          <ListItem key={listing_change.date}> from:{listing_change.changes[change][0]}</ListItem>)
          what.push(
          <ListItem key={listing_change.date}> to:{listing_change.changes[change][1]}</ListItem>)
          }
          else
          if(listing_change.action=="create")
          what.push(
          <ListItem key={listing_change.date}> Added: <span style={{fontWeight: "bold"}}>{listing_change.what}</span></ListItem>)
          else          
          what.push(
          <ListItem key={listing_change.date}> Removed: <span style={{fontWeight: "bold"}}>{listing_change.what}</span></ListItem>)
          // console.log(listing_change.changes)
          // let what = listing_change.changes.map( change => {
          //   let change.key
          //   return(
          //   <div>
              
          //   </div>
          // )})
          return(
            <div>
          <ListItem key={listing_change.date}>
            {/* <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar> */}
            Date: <ListItemText primary={when} />
            
          </ListItem>
          <ListItem key={listing_change.date}>

            From: <ListItemText primary={from} />
            
          </ListItem>
            {what}
          </div>
        )
      }
      )
      }
      </List>
    </Dialog>
        
        <GridContainer>
                    <GridItem xs={12} sm={12} md={12}> 
        {/* <ExpansionPanel square>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        > */}
        
        {!this.state.addingMaterial &&
        <div>
          
          {this.state.loading.materials &&
                    <LinearProgress variant="query" />
                    }
          <Typography className={classes.heading}>Current Materials</Typography>
        {/* </ExpansionPanelSummary>
        <ExpansionPanelDetails> */}
        
        {this.state.audits.length > 0 &&
  
  <Button color="warning" onClick={()=>{this.setState({viewUpdatesDialog: true})}}>
  View Changes
  </Button>
  
}
            {this.props.task && !this.state.saveRequired &&
        <Button color="success" size="small" aria-label="Nothing to Update" className={classes.tableActionButton} onClick={() => this.saveUpdates()}>Everything is correct
                                                    </Button>
        }
            {this.state.saveRequired &&
                <Button onClick={()=>this.saveUpdates()} color="success">Save</Button>
                  }
            <Button onClick={()=>{this.setState({addingMaterial: true})
    document.getElementById('topAppBar').scrollIntoView();}} color="warning">Add Material</Button>  
    
    
    <Button onClick={()=>this.props.back({listing: this.props.listing, completed: 'needs_materials'})} color="danger" simple={true}>{this.props.task ? "Cancel" : !this.state.saveRequired ? "Back" : "Cancel all changes, go back"}</Button> 
     
        <GridContainer>
    <GridItem xs={12} sm={12} md={3}>
   <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Set All Residential
                          </InputLabel>
            <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.residentialSelect}
                            onChange={this.handleSimpleResidential}
                            inputProps={{
                              name: "residentialSelect",
                              id: "simple-select"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              Select
                            </MenuItem>
                            
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="none"
                          >
                          None
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="pickup"
                          >
                          Pick-up
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="dropoff"
                          >
                          Drop-off
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="both"
                          >
                          Both
                          </MenuItem>
                          </Select>
                            </FormControl>
                            </GridItem>
   <GridItem xs={12} sm={12} md={3}>
   <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Set All Business
                          </InputLabel>
            <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.businessSelect}
                            onChange={this.handleSimpleBusiness}
                            inputProps={{
                              name: "businessSelect",
                              id: "simple-select"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                            Select
                            </MenuItem>
                            
                            
                            
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="none"
                          >
                          None
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="pickup"
                          >
                          Pick-up
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="dropoff"
                          >
                          Drop-off
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="both"
                          >
                          Both
                          </MenuItem>
                            </Select>
                            </FormControl>
   </GridItem>
        </GridContainer>
       
   <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    // "Name", "City", "Status", 
                    "Material", "", "",  "", "Special Notes"]}
                  tableData={ added_materials_table } 
                />
                {this.props.task && !this.state.saveRequired &&
        <Button color="success" size="small" aria-label="Nothing to Update" className={classes.tableActionButton} onClick={() => this.saveUpdates()}>Everything is correct
                                                    </Button>
        }
                  {this.state.saveRequired &&
                <Button onClick={()=>this.saveUpdates()} color="success">Save</Button>
                  }
                <Button onClick={()=>{this.setState({addingMaterial: true})
    document.getElementById('topAppBar').scrollIntoView();}}  color="warning">Add Material</Button>

{!this.state.saveRequired &&
                  <Button color={"danger"} onClick={()=>this.props.back({listing: this.props.listing, completed: 'needs_materials'})} simple={true}>{this.props.task ? "Cancel" : "Back"}</Button>   
}
                  </div>
                  }
        {/* </ExpansionPanelDetails>
        </ExpansionPanel> */}

        {/* <ExpansionPanel square>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        > */}
        {this.state.addingMaterial &&
        <div>
        <div>
          <Typography className={classes.heading}>Add New Materials</Typography>
          
        </div>
        
    {this.state.changes.added.length > 0 &&
        <Button onClick={()=>{this.setState({addingMaterial: false})
    document.getElementById('topAppBar').scrollIntoView();}} color="success">Done</Button>
    }
    
    <Button onClick={()=>{this.cancel()
    document.getElementById('topAppBar').scrollIntoView();}} color="danger">Cancel</Button>

    {!this.props.task && this.state.changes.added.length == 0 &&
    <Button onClick={()=>this.props.back({listing: this.props.listing, completed: 'needs_materials'})} color="warning" simple={true}>{"Back"}</Button>   
    }
        {/* </ExpansionPanelSummary>
        <ExpansionPanelDetails> */}
        
        <GridContainer>
   
                  <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}> 
                    <FormControlLabel
           style={{width:"100%"}}
                    labelPlacement="start"
        control={
           <DebounceInput
           style={{width:"100%", height:"30px", margin: "30px"}}
           minLength={2}
           debounceTimeout={700}
           onChange={event => this.searchMaterials(event)} 
           element={TextField}
           /* element={CustomInput} */
           />
        }
        label="Search for a material:  "
      />
      
      {this.state.loading.materials_search &&
                    <LinearProgress variant="query" />
                    }
      {!this.state.nothing_found && !this.state.requestMaterialAddition &&
      <small style={{fontStyle: 'italic'}}>
                  Can't find your material? <Button color="primary" simple={true} onClick={()=>this.setState({requestMaterialAddition: true})}>
Request it be added.
                                                         <AddAlert className={classes.tableActionButtonIcon + " " + classes.edit}/>
    </Button>
                    </small>
      }
      {this.state.nothing_found && 
                    <p>
                      The search didn't return any results. Please try another search term. {!this.state.requestMaterialAddition && 
                      <Button color="primary" simple={true} onClick={()=>this.setState({requestMaterialAddition: true})}>
Request Material to be added.
                                                         <AddAlert className={classes.tableActionButtonIcon + " " + classes.edit}/>
    </Button>
                      }
                    </p>
                    }    
                    {this.state.requestMaterialAddition && 
                    
                    <div>
                      <h4>
                      Please enter the material name and description that you would like added
                      </h4>
                        </div>
                    }
                    {this.state.requestMaterialAddition && 
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={4} lg={3}>
                      <CustomInput
                        labelText="Material Name"
                        id="material-name"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // disabled: true,
                          required: true,
                          defaultValue: "",
                          name: "program-type-name"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={7}>
                      <CustomInput
                        labelText="Material Description"
                        id="material-description"
                        multiline
                        rowsMax="3"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // disabled: true,
                          required: true,
                          defaultValue: "",
                          name: "program-type-description"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}>
                    
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<Send size={"large"}className={classes.tableActionButtonIcon + " " + classes.edit}/>}
        onClick={()=>this.requestMaterial()}>
                    Send Request   
                                                     </Button>
                    </GridItem>
                    </GridContainer> 
                    }
                      {/*  */}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
           style={{width:"100%"}}
                    labelPlacement="start"
        control={
           <Button simple={true}  color="warning" onClick={(e)=>this.getListings()}>
                    Select Listing
                  </Button>
        }
        label="Or you can select from another listing's materials:  "
      />
                    
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
           style={{width:"100%"}}
                    labelPlacement="start"
        control={
           <Button simple={true}  color="warning" onClick={(e)=>this.getFamilies()}>
                    Select through category
                  </Button>
        }
        label="Or you can select through the categories:  "
      />
                    
                    </GridItem>
                    
                    {listings_select.length > 0 && this.state.currentlySelecting == "listings" &&
                    <GridItem xs={12} sm={12} md={4}>
                     <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select a Listing
                          </InputLabel>
                    <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    value={this.state.listingSelect}
    onChange={this.handleListing}
    fullWidth={true}
    inputProps={{
      name: "listingSelect",
      id: "simple-select"
    }}
  >
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      Listing
    </MenuItem>
    {listings_select}
    </Select>
    
    </FormControl>
    {this.state.listingSelect != 0 &&
    <Button onClick={()=>this.addAll()} color="primary">
    Add all from listing
    </Button>
    }
                       </GridItem>
                    }
                    {families.length > 0 && this.state.currentlySelecting == "families" &&
                    <GridItem xs={12} sm={12} md={4}>
                     <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select a Category
                          </InputLabel>
                    <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    value={this.state.familySelect}
    onChange={this.handleFamily}
    fullWidth={true}
    inputProps={{
      name: "familySelect",
      id: "simple-select"
    }}
  >
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      Type of Category
    </MenuItem>
    {families_select}
    </Select>
    
    </FormControl>
                       </GridItem>
                    }
                  </GridContainer>
                  </div>
                  

                  <div>
              <GridContainer>
                
                    {materials.map(m=>{                      
                    return <GridItem xs={12} sm={12} md={4} key={m}>
                    {m}
                    </GridItem>
                    })}
                  </GridContainer>

                  {family.length == 0 && materials.length == 0 &&
                  
                  <div>
                  <GridContainer>

                    
                    {families.map(m=>{                      
                    return <GridItem xs={12} sm={12} md={4} key={m}>
                    {m}
                    </GridItem>
                    })}
                   
                       
                  </GridContainer>
                  </div>
                  }
                  {family.length > 0 &&
                  <GridContainer>
                    {family.map(m=>{                      
                    return <GridItem xs={12} sm={12} md={4} key={m}>
                    {m}
                    </GridItem>
                    })}
                    <Button simple={true} onClick={this.backToAllFamilies} color="warning">Back to all Categories</Button>
                  </GridContainer>
                  }
                       
                       </div>
                       
                  
                  </GridContainer>
                  
                  </div>
        }
        {/* </ExpansionPanelDetails>
        </ExpansionPanel> */}
   
        </GridItem> 
                  </GridContainer>
                  


                
      </div>
    );
  }
}

UpdateMaterials.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(UpdateMaterials);
