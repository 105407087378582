// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import Register from "@material-ui/icons/GroupAdd";
import Login from "@material-ui/icons/LockOpen";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import EarthDashboardPage from "views/EarthDashboard/EarthDashboard.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import EarthUserProfile from "views/UserProfile/EarthUserProfile.jsx";
import OrganizationTask from "views/Tasks/OrganizationTask.jsx";
import VisibilityTask from "views/Tasks/VisibilityTask.jsx";
import SupportForm from "views/Support/SupportForm.jsx";
import ListingTask from "views/Tasks/ListingTask.jsx";
import AllListings from "views/Listings/AllListings.jsx";
import Visibility from "views/Visibility/Visibility.jsx";
import TableList from "views/TableList/TableList.jsx";
import Typography from "views/Typography/Typography.jsx";
import Icons from "views/Icons/Icons.jsx";
import Maps from "views/Maps/Maps.jsx";
import NotificationsPage from "views/Notifications/Notifications.jsx";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.jsx";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.jsx";
// core components/views for Auth layout
import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";

const earthRoutes = [
  {
    path: "/earth911",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Language,
    component: EarthDashboardPage,
    layout: "/update"
  },
  
  {
    path: "/support",
    name: "Support",
    redirect: true,
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: SupportForm,
    layout: "/update"
  },
// ]
// const dashboardRoutes = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     rtlName: "لوحة القيادة",
//     icon: Dashboard,
//     component: DashboardPage,
//     layout: "/admin"
//   },
  {
    path: "/organization",
    name: "Organization",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: EarthUserProfile,
    layout: "/update"
  },
  
  {
    path: "/listings",
    name: "Listings",
    rtlName: "ملف تعريفي للمستخدم",
    icon: LocationOn,
    component: AllListings,
    layout: "/update"
  },
  
  {
    path: "/tasks/organization-task",
    name: "Organization Task",
    redirect: true,
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: OrganizationTask,
    layout: "/update"
  },
  {
    path: "/tasks/visibility-task",
    name: "Visibility Task",
    redirect: true,
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: VisibilityTask,
    layout: "/update"
  },
  
  
  {
    path: "/tasks/listing-task",
    name: "Listing Task",
    redirect: true,
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: ListingTask,
    layout: "/update"
  },
//   {
//     path: "/table",
//     name: "Table List",
//     rtlName: "قائمة الجدول",
//     icon: "content_paste",
//     component: TableList,
//     layout: "/admin"
//   },
//   {
//     path: "/typography",
//     name: "Typography",
//     rtlName: "طباعة",
//     icon: LibraryBooks,
//     component: Typography,
//     layout: "/admin"
//   },
  // {
  //   path: "/locations",
  //   name: "Locations",
  //   rtlName: "الرموز",
  //   icon: LocationOn,
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tasks",
  //   name: "Tasks",
  //   rtlName: "الرموز",
  //   icon: "content_paste",
  //   component: Icons,
  //   layout: "/admin"
  // },
  {
    path: "/visibility",
    name: "Increase Visibility",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Visibility,
    layout: "/update"
  },
//   {
//     path: "/maps",
//     name: "Maps",
//     rtlName: "خرائط",
//     icon: LocationOn,
//     component: Maps,
//     layout: "/admin"
//   },
//   {
//     path: "/notifications",
//     name: "Notifications",
//     rtlName: "إخطارات",
//     icon: Notifications,
//     component: NotificationsPage,
//     layout: "/admin"
//   },
//   {
//     path: "/upgrade-to-pro",
//     name: "Upgrade To PRO",
//     rtlName: "التطور للاحترافية",
//     icon: Unarchive,
//     component: UpgradeToPro,
//     layout: "/admin"
//   },
//   {
//     path: "/rtl-page",
//     name: "RTL Support",
//     rtlName: "پشتیبانی از راست به چپ",
//     icon: Language,
//     component: RTLPage,
//     layout: "/rtl"
//   },
//   {
//     path: "/login-page",
//     name: "Login Page",
//     rtlName: "پشتیبانی از راست به چپ",
//     icon: Login,
//     component: LoginPage,
//     layout: "/auth"
//   },
//   {
//     path: "/register-page",
//     name: "Register Page",
//     rtlName: "پشتیبانی از راست به چپ",
//     icon: Register,
//     component: RegisterPage,
//     layout: "/auth"
//   }
];

export default earthRoutes;
