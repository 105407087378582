import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import logo from "assets/img/logo_white.png";

import AddAlert from "@material-ui/icons/AddAlert";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
//import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import registerPageStyle from "assets/jss/material-dashboard-react/views/registerPageStyle.jsx";

const { REACT_APP_SERVER_URL } = process.env;

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      errors: {},
      invitation_token: ""
    };
  }
  componentDidMount = () => {
localStorage.removeItem('current_login');
localStorage.removeItem('logged_in_data');
localStorage.removeItem('logged_isssn_data');
let token =this.props.location.search;
if( token !== ""){

  let target = token.split('=')
  // console.log(target[0])
  if(target[0].indexOf('invitation_token') !== -1){
    this.setState({invitation_token: target[1]})
  }
  // console.log(true)
  
}
// }else{
  // console.log(false)
// }
  }
  register = async e => {
    e.preventDefault();

    const { history } = this.props;
    let targets = ""
    if(this.state.invitation_token == "")
    targets = ["email", "password", "password_confirmation"];
    else
    targets = ["password", "password_confirmation"];

    const fields = targets

    const formElements = e.target.elements;

    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

      let endpoint = `${REACT_APP_SERVER_URL}/auth`
    if(this.state.invitation_token !== ""){
    formValues.invitation_token = this.state.invitation_token
    endpoint = `${REACT_APP_SERVER_URL}/auth/invitation`
    }
    // console.log(formValues)

    let registerRequest;
    try {

    if(this.state.invitation_token !== ""){
      registerRequest = await axios.put(
        endpoint,
        {
          ...formValues
        }
      );
    }else{
      registerRequest = await axios.post(
        endpoint,
        {
          ...formValues
        }
      );

    }
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    // console.log(registerRequest)
    // console.log(registerRequestData)
    if (registerRequest.status == 200 || registerRequest.status == 202) {

      this.setState({ successAlert: true, alertMessage: "Great, your are all set! Redirecting to login page."})
      setTimeout(()=>{this.setState({ successAlert: false}) 
      // history.push('/auth/login-page');
    }
      , 3000)
      // setter
localStorage.setItem('current_login', JSON.stringify(registerRequest.headers));
localStorage.setItem('logged_in_data', JSON.stringify(registerRequestData.data));
// getter
// localStorage.getItem('myData');
// remove
// localStorage.removeItem('myData');
// remove all
// localStorage.clear();
      return history.push("/login");
    }

    this.setState({
      errors:
        registerRequestData.errors
    });
    
        this.setState({ errorAlert: true, alertMessage: registerRequestData.errors.full_messages})
        setTimeout(()=>{this.setState({ errorAlert: false}) 
        // history.push('/auth/login-page');
      }
        , 3000)
  };
  handleToggle = value => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };
  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    console.log(errors)
    return (
      <div className={classes.container}>
       <Dialog onClose={()=>this.setState({viewDialog: false})} aria-labelledby="simple-dialog-title" open={this.state.viewDialog}>
      <DialogTitle id="simple-dialog-title">Terms of Service</DialogTitle>
      <List>
      By clicking "Register" you agree to the terms below:
Earth911.com, Inc.

License Agreement

This License Agreement (“Agreement”) governs the use of this website (the “Website”) and the confidential database accessible from this Website and the associated software “manager” tool (collectively, “Software”) which are owned or licensable by Earth911.com, Inc. (“E9I”), by the person or entity (“Licensee”) that has clicked on the “Register” button below.

Your click of the “Register” button indicates that you are a natural person either representing yourself or acting as an agent for a principal legal entity, you are over eighteen (18) years of age, that you may legally enter into this Agreement, that all information provided or to be provided to E9I is accurate and complete, and that you agree with all the terms of this Agreement.

Grant of License.
License. Subject to all other terms of this Agreement, E9I hereby grants to Licensee a royalty-free, non-exclusive, non-transferable license, without the right to grant sublicenses, and solely for the term of this Agreement, to access the Software via a user identification code consisting of a valid email address to be provided by Licensee and an alphanumeric password (“Password”) to be suggested by Licensee and agreed to by E9I (subject to E9I’s data security requirements), solely obtain the services available on the Website and to post on the Website, edit and modify as necessary from time to time, certain information to be provided by Licensee regarding Licensee’s business, including without limitation location, contact information and hours of operation (“Licensee Information”). Licensee may not make or transfer any copies of the Website or Software. The foregoing license shall terminate immediately and without prior notice for any breach of this Agreement by Licensee. The Software is and shall remain the sole and confidential property of E9I. Licensee agrees not to modify the Software, remove any notices or markings on the Software, or reverse compile, reverse assemble, reverse engineer or otherwise attempt to learn, use or disclose the trade secrets contained in the Software or permit any third party to do any of the foregoing. There are no implied licenses under this Agreement. E9I may make certain technical support available to Licensee regarding the Software via the Website or by toll-free telephone number from time to time (“Support”).
Confidentiality. Licensee acknowledges and agrees that use of the Website, the Software and the Password are furnished during the term of the Agreement to Licensee on a confidential basis for the sole and exclusive internal use of Licensee and not for redistribution. Licensee shall not use, publish, disclose, or otherwise divulge to any person, at any time, either during or after the term of this Agreement, any non-public information regarding the Website, the Software or the Password (including without limitation the contents of the database portion of the Software). Without limiting the generality of the foregoing, Licensee agrees that it shall not directly or indirectly “scrape,” “spider,” monitor or otherwise seek to obtain the contents of the foregoing database, and shall use the Software solely with respect to Licensee’s own information contained in such database, and that all information provided by Licensee shall be accurate and complete. Licensee agrees that E9I shall be free to use, display, copy, modify and disclose all information received from Licensee, including without limitation Licensee Information, during and after the term of this Agreement, as E9I sees fit.
Disclaimer.

THE WEBSITE, THE SOFTWARE, SUPPORT AND PASSWORDS ARE PROVIDED ON AN “AS IS” BASIS, WITHOUT ANY WARRANTY WHATSOEVER. ALL EXPRESS, IMPLIED OR STATUTORY CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, TITLE, PERFORMANCE OR NON-INFRINGEMENT, ARE DISCLAIMED.

Limitation of Liability.

IN NO EVENT SHALL E9I BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR ANY INJURY TO PERSONS OR PROPERTY, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, LOSS OF PRIVACY FOR FAILURE TO MEET ANY DUTY INCLUDING OF GOOD FAITH OR OF REASONABLE CARE, OR NEGLIGENCE) ARISING UNDER THIS AGREEMENT OR IN CONNECTION WITH THE WEBSITE, THE SOFTWARE, SUPPORT OR PASSWORDS WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, REGARDLESS OF WHETHER ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE TOTAL LIABILITY OF E9I EXCEED THE SUM OF ONE HUNDRED DOLLARS ($100), REGARDLESS OF THE CAUSE OF ACTION.

Indemnity.

Licensee agrees to indemnify, defend and hold harmless E9I, its parent, subsidiaries, directors, officers, employees, agents, customers and affiliates from and against any claims, demands, threats, suits, proceedings, losses or liabilities arising from or related to any breach of this Agreement by Licensee, including without limitation any provision by Licensee of information that is not accurate or complete.

General.

This Agreement shall be governed by the laws of the State of Arizona without regard to conflicts of laws principles. The State and Federal courts located in Maricopa County, Arizona, shall have sole jurisdiction over any dispute arising hereunder, and the parties hereby consent to the personal jurisdiction of such courts. The United Nations Convention on Contracts for the International Sale of Goods shall not apply to this Agreement. Neither this Agreement, nor any rights hereunder, may be assigned by operation of law or otherwise, in whole or in part, by Licensee without the prior, written permission of E9I. Any purported assignment without such permission shall be void. E9I may assign this Agreement, including any rights hereunder, by operation of law or otherwise, in whole or in part, without notice. Any waiver of any rights of E9I under this Agreement must be in writing, signed by E9I, and any such waiver shall not operate as a waiver of any future breach of this Agreement. E9I may terminate this Agreement upon notice for any reason or for no reason. This Agreement is the entire agreement between the parties with respect to this subject matter, and supersedes any and all prior or contemporaneous, conflicting or additional communications, negotiations or agreements with respect thereto.

Severability.

Should any provision of this Agreement be declared void or unenforceable, the validity of the remaining provisions shall not be affected thereby.

Please direct any questions or comments about the Website or Software to:

Earth911.com, Inc.

1375 N. Scottsdale Rd., Suite 140

Scottsdale, AZ 85257

Email: clientrelations@earth911.com
      </List>
    </Dialog>
      
      <Snackbar
                    place="tr"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.alertMessage}
                    open={this.state.errorAlert}
                    closeNotification={() => this.setState({ errorAlert: false })}
                    close
                  />
        <Snackbar
                    place="tr"
                    color="success"
                    icon={AddAlert}
                    message={this.state.alertMessage}
                    open={this.state.successAlert}
                    closeNotification={() => this.setState({ successAlert: false })}
                    close
                  />
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.register}>
              <Card className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <div className={classes.socialLine}>
                  <a
        href="https://earth911.com"
       
      >
        
      <img style={{width: '200px'}} src={logo} alt="logo" className={classes.img} />
      </a>
                    {/* {[
                      "fa fa-facebook-square",
                      "fa fa-twitter",
                      "fa fa-google-plus"
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })} */}
                  </div>
                </CardHeader>
                <CardBody>
                  <p className={classes.cardDescription}>Earth911 - New Management Portal</p>
                  {/* <CustomInput
                    labelText="Name..."
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    inputProps={{
                      required: true,
                      name: "name",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  /> */}
                  {this.state.invitation_token == "" &&
                  <CustomInput
                    labelText="Email..."
                    id="email"                    
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    error={errors.email}
                    inputProps={{
                      // disabled: true,
                      required: true,
                      type: "email",
                      name: "username",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  }

                  
                  <CustomInput
                    labelText="Password..."
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    error={errors.password}
                    inputProps={{
                      required: true,
                      name: "password",
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password Confirmation..."
                    id="password_confirmation"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    error={errors.password_confirmation}
                    inputProps={{
                      required: true,
                      name: "password_confirmation",
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        classes.checkboxLabelControl +
                        " " +
                        classes.checkboxLabelControlClassName,
                      label: classes.checkboxLabel
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        required
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label={
                      <span>
                        I agree with the <Button color={"primary"}simple={true} onClick={()=>this.setState({viewDialog: true})}>Privacy Policy</Button>.
                      </span>
                    }
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type="submit" color="primary" simple size="lg" block>
                    Register
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object
};

export default withStyles(registerPageStyle)(RegisterPage);
