import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from '@material-ui/core/Snackbar'
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import MUIPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import UpdateMaterials from 'components/Materials/UpdateMaterials'
import ValidateListing from 'components/Listings/ValidateListing'
import AddListing from 'components/Listings/AddListing'

import {DebounceInput} from 'react-debounce-input';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import DateFnsUtils from '@date-io/date-fns';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';


import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Send from "@material-ui/icons/Send";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Help from "@material-ui/icons/Help";
import Undo from "@material-ui/icons/Undo";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import OpenInNew from "@material-ui/icons/OpenInNew";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"




// import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class ListingTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childComponent: false,
      viewingMaterials: '',
      validatingListing: '',
      // materialsSelection: "finalize", //yes, options ** DEVELPOPMERNT **
      materialsSelection: "no", //yes, options
      families: [],
      family: [],
      listings: [],
      listing: [],
      // listingSelect: ''
      suggestedPlace: [],
      formattedAddress:{},
      last_updated: {listing: {}, updated_url: ''},
      updateState: {current: "viewing_all", listing: {
        current: {},
        all: [],
        update_all: true,
        updated_all: false,

      }},
      p_left: 0,
      l_left: 0,
      errors: {},
      locations: [],
      edit_locations: [],
      location_updates: [],
      location_update_action: [],
      programs: [],
      edit_programs: [],
      program_updates: [],
      program_update_action: [],
      current_task: {completed: true},
      all_listings_completed: false,
      adding_location: false,
      listingSelect: "",
      locationSelect: "",
      programSelect: "",
      listing_name: "",
      enterAddressManually: false,
      new_url: '',
      open: false, suggestion: null ,
      selectedDate: new Date('2014-08-18T21:11:54'), 
      nothing_found: false,
      // setSelectedDate,
    //   !this.state.tasks.contacts[0].completed
      tasks: {listings: [
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
      ]},
      openDialog: false,
      completedAddress: false,
      materials: [],
      added_materials: [],
    };
    this.addUser = this.addUser.bind(this);
    this.getCurrentTaskData = this.getCurrentTaskData.bind(this);
    this.markAsValid = this.markAsValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.myRef = React.createRef()   // Create a ref object 
    // this.addProfile = this.updateProfile.bind(this);
  }
  backToAllFamilies = () =>{
    this.setState({family: []})
  }
  handleDateChange = date => {
    this.setState({selectedDate: date});
  };
  moveOnToMaterials = (value)=>{
    this.setState({materialsSelection: value})

  }
      
 
//  handleClose = () => {
//     onClose(selectedValue);
//   };

//  handleListItemClick = value => {
//     onClose(value);
//   };
addMaterial=(material)=>{
  console.log(material)

  // material.disabled = true
  let removedMaterial = this.state.materials
  removedMaterial.splice(material.index, 1)
  let materials = this.state.added_materials
  materials.push(material)
  material.index = materials.length - 1
  this.setState({added_materials: materials, materials: removedMaterial})
}

removeMaterial=(material)=>{
  console.log(material)
  // material.disabled = true
  let removedMaterial = this.state.added_materials
  removedMaterial.splice(material.index, 1)
  let materials = this.state.materials
  materials.push(material)
  this.setState({added_materials: removedMaterial, materials: materials})
}
  onClose=() =>{
    this.setState({ open: false })
  }
  enterAddressManually=() =>{
    // if(!this.state.enterAddressManually)
    this.setState({ enterAddressManually: !this.state.enterAddressManually, suggestedPlace: [], formattedAddress: {}, suggestion: null})
  }
  onSuggestionSelected=(suggestion) =>{
    // Add your business logic here. In this case we just log...
    this.setState({ open: true, suggestion })
    geocodeByPlaceID(suggestion.place_id).then((results) => {

      console.log(results)

      let formattedAddress = {}
      results[0].address_components.forEach(address_component =>{
        formattedAddress[address_component.types[0]] = {s: address_component.short_name, l:address_component.long_name}

      })
      this.setState({suggestedPlace: results, formattedAddress: formattedAddress})
      // Add your business logic here. In this case we simply set our state with the coordinates of
      // the selected suggestion...

      // Just use the first result in the list to get the geometry coordinates
      // const { geometry } = results[0]

      // const coordinates = {
      //   lat: geometry.location.lat(),
      //   lng: geometry.location.lng(),
      // }

      // this.setState({ coordinates })
    }).catch((err) => {
      // Handle any errors that occurred when we tried to get geospatial data for a selected
      // suggestion...
    })
    
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleFamily = event =>{
    let family_id = event.target.value
    this.setState({familySelect: family_id, materials:[]})
    this.getFamily(family_id)
  }
  
  handleListing = event =>{
    let listing_id = event.target.value
    this.setState({listingSelect: listing_id, materials:[]})
    this.getListing(listing_id)
  }
  resetLocation = (location) =>{
    
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'reset_location'

    this.setState({location_update_action: location_update_action})
  }
  resetProgram = (program) =>{
    
    let program_update_action = this.state.program_update_action
    program_update_action[program.index] = 'reset_program'

    this.setState({program_update_action: program_update_action})
  }
  checkAllLocationsCompleted = ()=>{
    let completed = true;
    console.log(this.state.locations.length)
    for(let i = 0; i<this.state.locations.length; i++){
      console.log(this.state.locations[i].updated)
    if(!this.state.locations[i].updated)
      { console.log(this.state.locations[i])
        completed = false
      break;}
    }
    for(let i = 0; i<this.state.programs.length; i++){
      console.log(this.state.programs[i].updated)
    if(!this.state.programs[i].updated)
      { console.log(this.state.programs[i])
        completed = false
      break;}
    }
    if(completed)
    this.setState({all_listings_completed: true})

  }
  backToListings = (updated_all) => {
    let updateState =  {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }}
    if(updated_all){
      this.state.locations[this.state.updateState.listing.current.index].updated = true
      this.checkAllLocationsCompleted()

      
    let location_update_action = this.state.location_update_action
    if(this.state.new_url !== "mixed")
    if(this.state.updateState.listing.all.length > 1)
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all to: ' + this.state.new_url
    else
    location_update_action[this.state.updateState.listing.current.index] = 'Updated to: ' + this.state.new_url
    else{
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all URLs individually'

    }

    this.setState({updateState: updateState, last_updated: {listing: this.state.updateState.listing.current, updated_url: this.state.new_url}, new_url: '', location_update_action: location_update_action})
  }

    else{
    this.setState({updateState: updateState, new_url: ''})}

  }


  async getCurrentTaskData(task){


    const { history } = this.props;
    
    if(!apiSetupHeaders()){
      return history.push("/auth/login-page");
  }
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/task/`+task.task.id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    let location_update_action = []
    for(let i = 0; i<taskRequestData.locations.length; i++){
      taskRequestData.locations[i].type = 'location'
      if(taskRequestData.locations[i].updated == 'completed')
      location_update_action[i] = 'completed'      
      else
      if(taskRequestData.locations[i].updated == 'updated_listing')
      location_update_action[i] = 'needs_materials'
      else
      if(taskRequestData.locations[i].updated == 'request_removal')
      location_update_action[i] = 'request_removal'
      else
      location_update_action[i] = 'action_required'
      taskRequestData.locations[i].index = i
    }
    
    let program_update_action = []
    for(let i = 0; i<taskRequestData.programs.length; i++){
      taskRequestData.programs[i].type = 'program'
      if(taskRequestData.programs[i].updated == 'completed')
      program_update_action[i] = 'completed'      
      else
      if(taskRequestData.programs[i].updated == 'updated_listing')
      program_update_action[i] = 'needs_materials'
      else      
      if(taskRequestData.programs[i].updated == 'request_removal')
      program_update_action[i] = 'request_removal'
      else
      program_update_action[i] = 'action_required'
      taskRequestData.programs[i].index = i
    }
    this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action,
      programs: taskRequestData.programs, program_updates: taskRequestData.program_updates, program_update_action: program_update_action, p_left: taskRequestData.p_left,  l_left: taskRequestData.l_left, })
    
    this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  
  viewChanges = async (listing) => {
    
        
        let taskRequest;
        try {
          taskRequest = await axios.get(
            `${REACT_APP_SERVER_URL}/v1/audit?`+listing.type+"="+listing.uuid,
            // {
            //   listing_type: listing.type
            // },
            {
            //   withCredentials: true
            }
          );
        } catch ({ response }) {
          taskRequest = response;
        }
    
        const { data: taskRequestData } = taskRequest;
        // console.log(taskRequestData)
    
        this.setState({listing_name: listing.description, listing_changes: taskRequestData, viewDialog: true})
        
        apiStoreHeaders(taskRequest.headers)
    
    
        // this.setState({
        //   childComponent: true,
        //   viewingMaterials:
        // <UpdateMaterials
        // task={false}
        //   listing={listing}
        //   back={this.backFromUpdatingMaterials}
        //  />})
        
        // const { history } = this.props;
        // history.push('/materials/'+location.location_id)
    
      }
  
  async getFamilies(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/families/`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({families: taskRequestData.families, materials: []})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getListings(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listings`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({listings: taskRequestData.listings, materials: []})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getFamily(family_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/family/`+family_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({materials: taskRequestData.family})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getListing(listing_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listing/`+listing_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({materials: taskRequestData.listing})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  setAllListingsToURL = async ()=>{
    
    // const fields = ["new_url"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+this.state.updateState.listing.current.UUID,
        {
          location:  {new_url: this.state.new_url, all: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)
    this.backToListings(true)

    // let updateState = this.state.updateState
    // location = updateState.listing
    // location.updated_all = true
    // // location.url = formValues.new_url
    
    // this.setState({updateState: updateState})

    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  updateAllIndividually = (value)=>{
    let updateState = this.state.updateState
    updateState.listing.update_all = value;
    this.setState({updateState: updateState})
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  componentDidMount(){
    
    // console.log("test")
    document.getElementById('topAppBar').scrollIntoView();
    // this.getProfileData()
    let tasks = JSON.parse(localStorage.getItem('tasks'))
    this.setState({tasks: tasks})
    
    let listing_task = tasks['listings']
    console.log(listing_task)

    for(let i=0; i<listing_task.length; i++){
      listing_task[i].index = i
     if(!listing_task[i].completed){
        this.setState({current_task: listing_task[i]})
        // console.log("set current to", listing_task[i])
        // console.log(this.state)
        if(listing_task[i].task.id == 9){
          this.setState({
            childComponent: true, addListing: <AddListing task={true} back={this.backFromAddingListing}/>})

          
        }else{
        this.getCurrentTaskData(listing_task[i]);
        }
        break;
     }
    }

  }
  componentDidUpdate(prevProps, prevState, snapshot){
    // if(this.props.last_updated !== prevProps.last_updated && this.props.last_updated.listing.index !== null){
      
    // let location_update_action = this.state.location_update_action
    // location_update_action[this.props.last_updated.listing.index] = 'Updated all to: ' + this.props.last_updated.new_url
    // this.setState({location_update_action: location_update_action})
    // }

  }
  handleClose = () => {

    this.setState({openDialog: !this.state.openDialog})

    // setOpen(false);
    // setSelectedValue(value);
  };
  
  handleChange = (event) => {
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    this.setState({[event.target.name]: event.target.value});
  }

  searchMaterials = async (event) => {
    this.setState({materials: [], currentlySelecting: "searching"})
    let taskRequest;
    try {
      taskRequest = await axios.get(
        
        `${REACT_APP_SERVER_URL}/v1/materials?q=`+event.target.value,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        // {
          // url: location.url
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    console.log(taskRequestData)
    let nothing_found = false
    if(taskRequestData.materials.length == 0)
    nothing_found = true
    this.setState({materials: taskRequestData.materials, nothing_found: nothing_found})
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    // this.setState({[event.target.name]: 
    
      
      // https://search.earth911.com/suggest/materials/?q=%231
  }
  updateEmail = async(e) => {
    e.preventDefault();
    const { history } = this.props;

    const fields = ["new_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/profile/change_email`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    if(registerRequest.status > 205){
        console.log(registerRequestData) // do something here
    }else{
        
        apiStoreHeaders(registerRequest.headers)
        return history.push("/update/earth911");
        
    }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  async addUser(e) {
    e.preventDefault();

    const fields = ["first_name", "last_name", "added_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/profile/add_user`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;

    if (!registerRequestData.success) {
      this.setState({
        errors:
          registerRequestData.messages && registerRequestData.messages.errors
      });
    }
  }
  backFromAddingListing = () =>{
    
    const { history } = this.props;
    
    // if(all_tasks_completed)    
    history.push("/update/earth911?completed=true");
  }
   markTaskAsValid = async () => {
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/task`,
        {
          task: {category: 'listing', id: this.state.current_task.task.id, completed: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

    // console.log(taskRequest)
    
    // const { data: taskRequestData } = taskRequest;
    this.state.tasks.listings[this.state.current_task.index].completed = true
    let previous_index = this.state.current_task.index
    
    let all_tasks_completed = true

    this.state.tasks.listings.some(task =>{
      if(!task.completed){
        console.log(task)
        task.index = previous_index + 1
        this.resetAllData()
        all_tasks_completed = false
        this.setState({current_task: task})
        // console.log(task.id)
        // console.log(task.id == 9)
        if(task.task.id == 9){
          this.setState({
            childComponent: true, addListing: <AddListing task={true} back={this.backFromAddingListing}/>})
        }else{
        this.getCurrentTaskData(task);
        } 
        // break
      return true
      }

    })
    // for(let i=this.state.current_task.task.id-4; i<this.state.tasks.listings.length; i++){
      // if(!this.state.tasks.listings[i].completed){
      //   this.resetAllData()
      //   all_tasks_completed = false
      //   this.setState({current_task: this.state.tasks.listings[i]})
      //   if(this.state.tasks.listings[i] == 9){
      //     this.setState({
      //       childComponent: true, addListing: <AddListing back={this.backFromAddingListing}/>})
      //   }else{
      //   this.getCurrentTaskData(this.state.tasks.listings[i]);
      //   } 
      //   break;
      // }
    // }
    
    const { history } = this.props;
    if(all_tasks_completed)    
    history.push("/update/earth911");
    // console.log(taskRequestData)


    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  resetAllData = () => {
    this.setState({
    last_updated: {listing: {}, updated_url: ''},
    updateState: {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }},
    locations: [],
    edit_locations: [],
    location_updates: [],
    location_update_action: [],
    all_listings_completed: false,    
    new_url: ''})
  }
  updateLocation = async (e, location) => {
    
    const fields = ["new_url"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));
    e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.UUID,
        {
          location:  formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)

    let updateState = this.state.updateState
    location = updateState.listing.all[location.index]
    location.updated = true
    location.url = formValues.new_url
    
    this.setState({updateState: updateState})

    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    let completed = true
    for(let i=0; i<updateState.listing.all.length; i++)
    {
      if(updateState.listing.all[i].updated == false)
        completed = false
    }
    if(completed){
      if(updateState.listing.all.length == 1)
      this.setState(formValues)
      else
      this.setState({new_url: "mixed"})
      
      this.backToListings(true)

    }
    // const { data: taskRequestData } = taskRequest;

    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  markAsValid = async (location) => {
    // console.log("marked as valid", location, index)
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'Marked as Valid (no further updates to listings will be made)'
    let locations = this.state.locations
    locations[location.index].updated = true
    this.setState({location_update_action: location_update_action, locations: locations})
    
    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.UUID,
        {
          location:  {markAsValid: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)
    apiStoreHeaders(taskRequest.headers)
    this.checkAllLocationsCompleted()

  }
  updateURL = async (location) => {
    // console.log("marked as valid", location, index)
    // let location_update_action = this.state.location_update_action
    // location_update_action[location.index] = 'Update'
    // this.setState({location_update_action: location_update_action})
    let updateState = this.state.updateState
    updateState.current = 'editing'
    updateState.listing.current = location
    console.log(location)
    if(location.effects_count == "1")
    {
      
      updateState.listing.update_all = false
      console.log(updateState)
      
      // last_updated: {listing: {}, updated_url: ''},
      // updateState: {current: "viewing_all", listing: {
      //   current: {},
      //   all: [],
      //   update_all: true,
      //   updated_all: false,

      // }},


    }
    

    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.UUID,
        // {
          // url: location.url
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    console.log(taskRequestData)
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

    // const { data: taskRequestData } = taskRequest;
    // let location_update_action = []
    for(let i = 0; i<taskRequestData.locations.length; i++){
      // location_update_action[i] = 'action_required'
      taskRequestData.locations[i].index = i
      taskRequestData.locations[i].updated = false
    }

    updateState.listing.all = taskRequestData.locations
    this.setState({updateState: updateState})


  }
  viewMaterials=(listing) =>{
    this.setState({
      childComponent: true,
      viewingMaterials:
    <UpdateMaterials
      listing={listing}
      back={this.backFromUpdatingMaterials}
      task={true}
     />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
  
  validateListing=(listing) =>{
    console.log(listing)
    this.setState({
      childComponent: true,
      validatingListing:
    <ValidateListing
      listing={listing}
      back={this.backFromValidatingListing}
     />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
  backFromUpdatingMaterials = (payload) =>{
    if(payload.completed){
    console.log(payload)
    document.getElementById('topGo').scrollIntoView();    
    let lua = ''
    let listing_type = payload.listing.type
    if(listing_type == "location"){
    lua = this.state.location_update_action
    let l_left = this.state.l_left
    l_left = l_left -1
    this.setState({l_left: l_left})
    console.log(this.state.l_left)

  }
    else{
    lua = this.state.program_update_action
    let p_left = this.state.p_left
    p_left = p_left -1
    this.setState({p_left: p_left})
  }
    if(payload.completed){
      lua[payload.listing.index] = payload.completed

    }
    if(listing_type == "location")
    this.setState({childComponent: false, viewingMaterials: "", location_update_action: lua})
    else
    this.setState({childComponent: false, viewingMaterials: "", program_update_action: lua})
  
  }else{
    this.setState({childComponent: false, validatingMaterials: ""})
  }
  this.checkAllLocationsCompleted()
  }
  
  backFromValidatingListing = (payload) =>{    
    if(payload.completed){
    console.log(payload)
    document.getElementById('topGo').scrollIntoView();
    let lua = ''
    let listing_type = payload.listing.type
    if(listing_type == "location"){
    lua = this.state.location_update_action
    let l_left = this.state.l_left
    l_left = l_left -1
    this.setState({l_left: l_left})
    console.log(this.state.l_left)

  }
    else{
    lua = this.state.program_update_action
    let p_left = this.state.p_left
    p_left = p_left -1
    this.setState({p_left: p_left})
  }

      lua[payload.listing.index] = payload.completed

    // }
    if(listing_type == "location")
    this.setState({childComponent: false, validatingListing: "", location_update_action: lua})
    else
    this.setState({childComponent: false, validatingListing: "", program_update_action: lua})
    }else{
      this.setState({childComponent: false, validatingListing: ""})
    }
    this.checkAllLocationsCompleted()
  }
  getProfileData = async e => {
    apiSetupHeaders()
    // e.preventDefault();

    // const { history } = this.props;

    // // const fields = ["email", "password"];
    // // const formElements = e.target.elements;

    // // const formValues = fields
    // //   .map(field => ({
    //     // [field]: formElements.namedItem(field).value
    // //   }))
    // //   .reduce((current, next) => ({ ...current, ...next }));

    // if(!apiSetupHeaders()){
    //     return history.push("/auth/login-page");
    // }

    let profileRequest;
    
    try {
      profileRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/profile`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    if (profileRequest.status == 200) {
        // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        this.setState({profile: profileRequestData.profile})
    }else{
        // return history.push("/auth/login-page");
    }
    this.setState({
    //   errors: loginRequestData.messages && loginRequestData.messages.errors
    });
  };
  render() {
    const { classes, name, email } = this.props;
    const { errors, locationSelect, programSelect, listingSelect,  open, suggestion, formattedAddress, selectedDate } = this.state
    let edit_locations = []
    
    if(this.state.updateState.listing.all.length > 0){
      let loc_index = 0
        edit_locations = 
        this.state.updateState.listing.all.map((location)=>{
              
              loc_index += 1
            return [ 
              location.description, location.city, //location.status_id, 
           
              //  <span>
               
              //          <a style={{"color":"red"}} target="_blank"
              //                  href={location.url}>
              //          {location.url}
                       
              //          </a>
              //                </span>,
              // location.effects_count,
                              // <span style={{"color":"blue"}} >
                              
                              //                 {this.state.location_updates[loc_index-1]}
                              //               </span>,
                                            
                              //   <span>
                              //                 {this.state.location_update_action[loc_index-1] == "Update" && 
                                              
                                <span>
                                  {this.state.updateState.listing.update_all &&
                      <CustomInput
                        labelText="New URL"
                        id="new_url"
                        color="success"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.new_url,
                          disabled: this.state.updateState.listing.update_all ? true : false,
                          // required: true,
                          // defaultValue: "",
                          name: "new_url",
                        }}
                      />
                                  }
                          {!this.state.updateState.listing.update_all && !location.updated &&
                          
            <form onSubmit={(e)=>this.updateLocation(e, location)}>
                      <CustomInput
                        labelText="Update URL"
                        id="new_url"
                        color="warning"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // value: this.state.new_url,
                          // disabled: this.state.updateState.listing.update_all ? true : false,
                          // required: true,
                          // defaultValue: "",
                          name: "new_url",
                        }}
                      />
                      <Button color="primary" type="submit" aria-label="Update" className={classes.tableActionButton}> Update
                                                    </Button>
                                                    
                                                    </form>
                                  }
                                   {!this.state.updateState.listing.update_all && location.updated &&
                          
                      <CustomInput
                        labelText="New URL"
                        id="new_url"
                        color="warning"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: "Updated to: "+location.url,
                          disabled: true,
                          // required: true,
                          // defaultValue: "",
                          name: "new_url",
                        }}
                      />
                                                    
                                  }
                                                    
                                </span>
                                ,
                                            
                                            // }
                                            
                              // <span>
                              
                              //                 {this.state.location_update_action[loc_index-1] == "action_required" ? "" : this.state.location_update_action[loc_index-1]}
                              //               </span>

                              //               </span>
                              //               ,
                                            
                                            
                              //               <span>
                                            
                              //               {this.state.location_update_action[loc_index-1] != "action_required" &&            
                              //                <Tooltip
                              //                       id="tooltip-top"
                              //                       title="Undo"
                              //                       placement="top"
                              //                       classes={{tooltip:classes.tooltip}}>
                              //                       <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}>
                              //                         <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                              //                       </IconButton>
                              //                     </Tooltip>
                                                  
                              //               }
                                                            
                              //               {this.state.location_update_action[loc_index-1] == 'action_required' &&   
                              //               <Tooltip
                              //                       id="tooltip-top"
                              //                       title="Agree with action we took."
                              //                       placement="top"
                              //                       classes={{tooltip:classes.tooltip}}>
                              //                       <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}>
                              //                         <Check className={classes.tableActionButtonIcon + " " + classes.edit}/>
                              //                       </IconButton>
                              //                     </Tooltip>
                              //               }
                                                  
                              //                     </span>,
                              //                  <span>
                                               
                                                  
                              //               {this.state.location_update_action[loc_index-1] == "action_required" &&                  
                                                               
                              //                  <Tooltip
                              //                          id="tooltip-top"
                              //                          title="Update"
                              //                          placement="top"
                              //                          classes={{tooltip:classes.tooltip}}>
                                                      
                              //                          <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.updateURL(location)}>
                              //                            <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                              //                          </IconButton>
                                                       
                              //                        </Tooltip>
                              //               }
                                                     
                              //                        </span>,
                              <span>
                              
                      <Button onClick={()=>this.viewMaterials(location)} color="primary" simple={true} aria-label="View" className={classes.tableActionButton}> Update Materials
                      </Button>
                                            </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    let locations = [[]];
    if(this.state.locations.length > 0){
      let loc_index = 0
        locations = 
            this.state.locations.map((location)=>{
              
              loc_index += 1
              let updated_msg = ""
              if(this.state.location_updates[loc_index-1] == "Removed URL")
              {
                updated_msg = <span style={{"color":"red"}} >Removed the URL</span>
              }
              else{
                if(this.state.location_updates[loc_index-1] == "200")
                updated_msg = "No change committed"
                else
                updated_msg = "Updated to: " + this.state.location_updates[loc_index-1]
              }
            return [ 
              // location.description, location.city, location.status_id, 
           
              //  <span>
               
              //          <a style={{"color":"red"}} target="_blank"
              //                  href={location.url}>
              //          {location.url}
                       
              //          </a>
              //                </span>
              <span>{location.description}
                </span>
              ,
              
              <span>{location.city}
              </span>
            ,
            
            <span>{location.province}
            </span>
          ,
          <span>
          
             
       {this.state.location_update_action[loc_index-1] == "action_required" &&                  
                          
          <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button color="success" aria-label="Edit" className={classes.tableActionButton} onClick={() => this.validateListing(location)}>
                    Verify 
                  </Button>
                  
                </Tooltip>
       }
       {this.state.location_update_action[loc_index-1] == "needs_materials" &&
 <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button color="warning" aria-label="Edit" className={classes.tableActionButton} onClick={() =>this.viewMaterials(location)}>
                    Verify Materials
                  </Button>
                  
                </Tooltip>
       }
       {this.state.location_update_action[loc_index-1] == "reset_location" &&                  
                          
          <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button color="primary" aria-label="Edit" className={classes.tableActionButton} onClick={() => this.validateListing(location)}>
                    Update 
                  </Button>
                  
                </Tooltip>
       }
       {this.state.location_update_action[loc_index-1] == "completed" &&
       <span>     
         <Button color="primary" aria-label="Edit" className={classes.tableActionButton} disabled={true}>
                    Completed
                  </Button>
                                             <Tooltip
                                                    id="tooltip-top"
                                                    title="Continue Editing"
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetLocation(location)}>
                                                      <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                    </IconButton>
                                                  </Tooltip>
                                                  
       </span>     
                                            }
                                             {this.state.location_update_action[loc_index-1] == "request_removal" &&
       <span>     
         <Button color="primary" aria-label="Edit" className={classes.tableActionButton} disabled={true}>
                    Removal Pending
                  </Button>
                                             <Tooltip
                                                    id="tooltip-top"
                                                    title="Continue Editing"
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetLocation(location)}>
                                                      <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                    </IconButton>
                                                  </Tooltip>
                                                  
       </span>     
                                            }
                
                </span>,
                
                
                <span>
                <Tooltip
                                 id="tooltip-top"
                                 title="View Changes"
                                 placement="top"
                                 classes={{tooltip:classes.tooltip}}>
                                
                                 <Button  simple={true} disabled={location.updates? false:true}  color="danger" aria-label="View Changes" className={classes.tableActionButton} onClick={() =>this.viewChanges(location)}>
                                   {location.updates? "View Changes":"  "}
                                 </Button>
                                 
                               </Tooltip>
                      
                               
                               </span>
              // location.effects_count,
                              // <span style={{"color":"green"}} >
                              // {updated_msg}
                              //               </span>,
                                            
                      //           <span>
                      //                         {this.state.location_update_action[loc_index-1] == "Update" && 
                                              
                      //           <span>
                      // <CustomInput
                      //   labelText="New URL"
                      //   id="new_url"
                      //   /* error={errors.name} */
                      //   formControlProps={{
                      //     fullWidth: true
                      //   }}
                      //   inputProps={{
                      //     // required: true,
                      //     // defaultValue: "",
                      //     name: "new_url",
                      //   }}
                      // />
                      
                      // <Button color="success" aria-label="Update" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}> Save
                      //                               </Button>
                                                    
                      //           </span>
                                            
                      //                       }
                                            
                              // <span>
                              
                              //                 {this.state.location_update_action[loc_index-1] == "action_required" ? "" : this.state.location_update_action[loc_index-1]}
                              //               </span>

                              //               </span>
                                            ,
                                            
                                            
                                            // <span>
                                            
                                            // {this.state.location_update_action[loc_index-1] != "action_required" &&            
                                            //  <Tooltip
                                            //         id="tooltip-top"
                                            //         title="Undo"
                                            //         placement="top"
                                            //         classes={{tooltip:classes.tooltip}}>
                                            //         <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetLocation(location)}>
                                            //           <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //         </IconButton>
                                            //       </Tooltip>
                                                  
                                            // }
                                                            
                                            // {this.state.location_update_action[loc_index-1] == 'action_required' &&   
                                            // <Tooltip
                                            //         id="tooltip-top"
                                            //         title="Agree with action we took."
                                            //         placement="top"
                                            //         classes={{tooltip:classes.tooltip}}>
                                            //         <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}>
                                            //           <Check className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //         </IconButton>
                                            //       </Tooltip>
                                            // }
                                                  
                                            //       </span>
                                                  ,
                                            //    <span>
                                               
                                                  
                                            // {this.state.location_update_action[loc_index-1] == "action_required" &&                  
                                                               
                                            //    <Tooltip
                                            //            id="tooltip-top"
                                            //            title="Update"
                                            //            placement="top"
                                            //            classes={{tooltip:classes.tooltip}}>
                                                      
                                            //            <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.updateURL(location)}>
                                            //              <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //            </IconButton>
                                                       
                                            //          </Tooltip>
                                            // }
                                                     
                                            //          </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    let programs = [[]];
    if(this.state.programs.length > 0){
      let loc_index = 0
        programs = 
            this.state.programs.map((program)=>{
              
              loc_index += 1
              let updated_msg = ""
              if(this.state.program_updates[loc_index-1] == "Removed URL")
              {
                updated_msg = <span style={{"color":"red"}} >Removed the URL</span>
              }
              else{
                if(this.state.program_updates[loc_index-1] == "200")
                updated_msg = "No change committed"
                else
                updated_msg = "Updated to: " + this.state.program_updates[loc_index-1]
              }
            return [ 
              // program.description, program.city, program.status_id, 
           
              //  <span>
               
              //          <a style={{"color":"red"}} target="_blank"
              //                  href={program.url}>
              //          {program.url}
                       
              //          </a>
              //                </span>
              <span>{program.description}
                </span>
              ,
              
              <span>{program.city}
              </span>
            ,
            
            <span>{program.province}
            </span>
          ,
          <span>
          
             
       {this.state.program_update_action[loc_index-1] == "action_required" &&                  
                          
          <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button color="success" aria-label="Edit" className={classes.tableActionButton} onClick={() => this.validateListing(program)}>
                    Verify 
                  </Button>
                  
                </Tooltip>
       }
       {this.state.program_update_action[loc_index-1] == "needs_materials" &&
 <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button color="warning" aria-label="Edit" className={classes.tableActionButton} onClick={() =>this.viewMaterials(program)}>
                    Verify Materials
                  </Button>
                  
                </Tooltip>
       }
       {this.state.program_update_action[loc_index-1] == "reset_program" &&                  
                          
          <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button color="primary" aria-label="Edit" className={classes.tableActionButton} onClick={() => this.validateListing(program)}>
                    Update 
                  </Button>
                  
                </Tooltip>
       }
        {this.state.program_update_action[loc_index-1] == "request_removal" &&
       <span>     
         <Button color="primary" aria-label="Edit" className={classes.tableActionButton} disabled={true}>
                    Removal Pending
                  </Button>
                                             <Tooltip
                                                    id="tooltip-top"
                                                    title="Continue Editing"
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetProgram(program)}>
                                                      <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                    </IconButton>
                                                  </Tooltip>
                                                  
       </span>     
                                            }
       {this.state.program_update_action[loc_index-1] == "completed" &&
       <span>     
         <Button color="primary" aria-label="Edit" className={classes.tableActionButton} disabled={true}>
                    Completed
                  </Button>
                                             <Tooltip
                                                    id="tooltip-top"
                                                    title="Continue Editing"
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetProgram(program)}>
                                                      <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                    </IconButton>
                                                  </Tooltip>
                                                  
       </span>     
                                            }
                                            
                
                </span>,
                
                
                <span>
                <Tooltip
                                 id="tooltip-top"
                                 title="View Changes"
                                 placement="top"
                                 classes={{tooltip:classes.tooltip}}>
                                
                                 <Button  simple={true} disabled={program.updates? false:true}  color="danger" aria-label="View Changes" className={classes.tableActionButton} onClick={() =>this.viewChanges(program)}>
                                   {program.updates? "View Changes":"  "}
                                 </Button>
                                 
                               </Tooltip>
                      
                               
                               </span>
              // program.effects_count,
                              // <span style={{"color":"green"}} >
                              // {updated_msg}
                              //               </span>,
                                            
                      //           <span>
                      //                         {this.state.program_update_action[loc_index-1] == "Update" && 
                                              
                      //           <span>
                      // <CustomInput
                      //   labelText="New URL"
                      //   id="new_url"
                      //   /* error={errors.name} */
                      //   formControlProps={{
                      //     fullWidth: true
                      //   }}
                      //   inputProps={{
                      //     // required: true,
                      //     // defaultValue: "",
                      //     name: "new_url",
                      //   }}
                      // />
                      
                      // <Button color="success" aria-label="Update" className={classes.tableActionButton} onClick={() => this.markAsValid(program)}> Save
                      //                               </Button>
                                                    
                      //           </span>
                                            
                      //                       }
                                            
                              // <span>
                              
                              //                 {this.state.program_update_action[loc_index-1] == "action_required" ? "" : this.state.program_update_action[loc_index-1]}
                              //               </span>

                              //               </span>
                                            ,
                                            
                                            
                                            // <span>
                                            
                                            // {this.state.program_update_action[loc_index-1] != "action_required" &&            
                                            //  <Tooltip
                                            //         id="tooltip-top"
                                            //         title="Undo"
                                            //         placement="top"
                                            //         classes={{tooltip:classes.tooltip}}>
                                            //         <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetprogram(program)}>
                                            //           <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //         </IconButton>
                                            //       </Tooltip>
                                                  
                                            // }
                                                            
                                            // {this.state.program_update_action[loc_index-1] == 'action_required' &&   
                                            // <Tooltip
                                            //         id="tooltip-top"
                                            //         title="Agree with action we took."
                                            //         placement="top"
                                            //         classes={{tooltip:classes.tooltip}}>
                                            //         <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(program)}>
                                            //           <Check className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //         </IconButton>
                                            //       </Tooltip>
                                            // }
                                                  
                                            //       </span>
                                                  ,
                                            //    <span>
                                               
                                                  
                                            // {this.state.program_update_action[loc_index-1] == "action_required" &&                  
                                                               
                                            //    <Tooltip
                                            //            id="tooltip-top"
                                            //            title="Update"
                                            //            placement="top"
                                            //            classes={{tooltip:classes.tooltip}}>
                                                      
                                            //            <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.updateURL(program)}>
                                            //              <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //            </IconButton>
                                                       
                                            //          </Tooltip>
                                            // }
                                                     
                                            //          </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={program.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    return (
      <div id="topGo">
         { this.state.viewDialog &&
          <Dialog onClose={()=>this.setState({viewDialog: false})} aria-labelledby="simple-dialog-title" open={this.state.viewDialog}>
      <DialogTitle id="simple-dialog-title">Updates: {this.state.listing_name}</DialogTitle>
      <List>
        {this.state.listing_changes.map( listing_change=> {          

          let when = listing_change.date          
          let from = listing_change.user
          let what = []
          for(let change in listing_change.changes){
            console.log(change)
            console.log(listing_change.changes[change])
            what.push(
          <ListItem key={listing_change.date}> Changed: <span style={{fontWeight: "bold"}}>{change}</span></ListItem>)
          what.push(
          <ListItem key={listing_change.date}> <span style={{fontWeight: "bold"}}>from</span>:{listing_change.changes[change][0]}</ListItem>)
          what.push(
          <ListItem key={listing_change.date}> <span style={{fontWeight: "bold"}}>to</span>:<span style={{fontStyle: "italic"}}>{listing_change.changes[change][1]}</span></ListItem>)
          }
          console.log(listing_change.changes)
          // let what = listing_change.changes.map( change => {
          //   let change.key
          //   return(
          //   <div>
              
          //   </div>
          // )})
          return(
            <div>
          <ListItem key={listing_change.date}>
            {/* <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar> */}
            Date: <ListItemText primary={when} />
            
          </ListItem>
          <ListItem key={listing_change.date}>

            From: <ListItemText primary={from} />
            
          </ListItem>
            {what}
          </div>
        )
      }
      )
      }
      </List>
    </Dialog>
         }
        {this.state.viewingMaterials}
        {this.state.validatingListing}
        {this.state.addListing}
        {this.state.current_task.completed == false && this.state.updateState.current == "viewing_all" && !this.state.childComponent &&
        <GridContainer>     
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Listings</h4>
                <p className={classes.cardCategoryWhite}>
                <span style={{fontStyle: "italic"}}>{this.state.l_left > 0 ? "Locations remaining: " + this.state.l_left : ""} {this.state.p_left > 0 ? " Programs remaining: " + this.state.p_left : ""}</span>
                  {/* New employees on 15th September, 2016 */}
                </p>
              </CardHeader>
              <CardBody>
                  {this.state.locations.length > 0 &&
                <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    // "Name", "City", "Status", 
                    "Location Name", "City", "State", "Status", "", "View on Earth911"]}
                  tableData={ locations } 
                />
                  }
                  {this.state.programs.length > 0 &&
                <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    // "Name", "City", "Status", 
                    "Program Name", "City", "State", "Status", "", "View on Earth911"]}
                  tableData={ programs } 
                />
                  }

              </CardBody>
            </Card>
          </GridItem>
          {this.state.all_listings_completed && 
          <GridItem xs={12} sm={12} md={12}>
            
          <Button 
                  onClick={(e)=>this.markTaskAsValid()} color="primary">
                  Move on to the next Task <ArrowRightAlt className={classes.tableActionButtonIcon + " " + classes.edit}/>  
                  </Button>
          </GridItem>
          }
          
        </GridContainer>
        } 

{this.state.current_task.completed == false &&  this.state.updateState.current == "editing" && !this.state.childComponent &&
<GridContainer>     
          { this.state.updateState.listing.current.effects_count > 1 &&
          <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Update All URLs</h4>
                  
                </CardHeader>
                <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Original URL"
                        //id="organization"
                        //error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          // required: true,
                          defaultValue: this.state.updateState.listing.current.url,
                          name: "Current URL"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="We updated it to"
                        //id="organization"
                        //error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          // required: true,
                          defaultValue: this.state.location_updates[this.state.updateState.listing.current.index],
                          name: "Updated to"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="New URL"
                        //id="first_name"
                        //error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          //defaultValue: this.state.profile.first_name,
                          // value: this.state.profile.first_name,
                          name: "new_url",
                          value: this.state.new_url, 
                          onChange: this.handleChange 
                        }}
                      />
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Organization Admin Last Name"
                        id="last_name"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          defaultValue: this.state.profile.last_name,
                          // value: this.state.profile.last_name,
                          name: "last_name"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Organization Admin Phone Number"
                        id="phone"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          defaultValue: this.state.profile.phone,
                          name: "phone"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Organization Admin Email address"
                        id="email-address"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          disabled: true,
                          defaultValue: this.state.profile.email,
                          name: "username"
                        }}
                      />
                    </GridItem> */}
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button disabled={this.state.updateState.listing.update_all ? false: true}
                  onClick={(e)=>this.setAllListingsToURL(e)} color="primary">
                    Update All Listings
                  </Button>
                  Or..
                  {this.state.updateState.listing.update_all && 
                  <Button color="warning" onClick={()=>this.updateAllIndividually(false)}>
                    Update Individually
                  </Button>
                  }
                  
                  {!this.state.updateState.listing.update_all && 
                  <Button color="danger" onClick={()=>this.updateAllIndividually(true)}>
                    Cancel Update Individually
                  </Button>
                  }
                </CardFooter>
              </Card>
          </GridItem>
          }
          
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Locations - {this.state.current_task.task.title} - All Listings for URL: <a style={{color: "white"}} href={this.state.updateState.listing.current.url} target="_blank">{this.state.updateState.listing.current.url}</a></h4>
                <p className={classes.cardCategoryWhite}>
                  {/* New employees on 15th September, 2016 */}
                </p>
              </CardHeader>
              <CardBody>
                  {this.state.updateState.listing.all.length > 0 &&
                <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    "Name", "City", "New URL","Materials", "View on Earth911"]}
                  tableData={ edit_locations } 
                />
                  }
              </CardBody>
            </Card>
          </GridItem>
          
          <GridItem xs={12} sm={12} md={12}>
            
                                 <IconButton aria-label="Go Back" className={classes.tableActionButton} 
                                 onClick={() => this.backToListings(false)}>
                                                       <KeyboardArrowLeft className={classes.tableActionButtonIcon + " " + classes.edit}/> Go back to Listings
                                         </IconButton>
          </GridItem>
        </GridContainer>
      }
      

      </div>
    );
  }
}

ListingTask.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(ListingTask);
