// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  "Update/Change email address",
  "Add other people",
  'Verify Contact Information'
];
var website = [
  "Verify Listing Changes",
  // "Verify Listing Changes",
  // "Verify Listing Changes",
  // "Verify Changes - 404s",
  // "Verify Changes - bad URLs - resulted in an error",
  // "Verify Changes - redirected URLs",
  "Update Listings",
  // "Update Listings",
  // "Update - No URLs",
  // "View URLs that remained valid",
  'Add any missing Listings'
];
var server = [
  "Add your organization Logo and other assets",  
  "Select target audience",  
  "Select a recurring plan"  
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server
};
