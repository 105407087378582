import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import logo from "assets/img/logo_white.png";
import LogRocket from 'logrocket';
// LogRocket.init('berdek/e911-listings');


import AddAlert from "@material-ui/icons/AddAlert";
//import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import loginPageStyle from "assets/jss/material-dashboard-react/views/loginPageStyle.jsx";

const { REACT_APP_SERVER_URL } = process.env;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      errors: {}
    };
  }

  componentDidMount = () => {
    localStorage.removeItem('current_login');
    localStorage.removeItem('logged_in_data');
    localStorage.removeItem('logged_isssn_data');
      }
  login = async e => {
    e.preventDefault();

    const { history } = this.props;

    const fields = ["email", "password"];
    const formElements = e.target.elements;

    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let loginRequest;
    try {
      loginRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/auth/sign_in`,
        {
          ...formValues
        },
        {
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      loginRequest = response;
    }
    const { data: loginRequestData } = loginRequest;
    console.log(loginRequest)
    if (loginRequest.status == 200) {
      let header_data = JSON.stringify(loginRequest.headers)
      // console.log(header_data)
      // This is an example script - don't forget to change it!
LogRocket.identify(loginRequest.headers.uid, {
  name: loginRequest.headers.uid,
  email: loginRequest.headers.uid,

  // Add your own custom user variables here, ie:
  // subscriptionType: 'pro'
});
// console.log(header_data.uid)
      // setter
localStorage.setItem('current_login', header_data);
localStorage.setItem('logged_in_data', JSON.stringify(loginRequestData.data));
      return history.push("/update/earth911");
    }

    // this.setState({
    //   errors: loginRequestData.messages && loginRequestData.messages.errors
    // });

    this.setState({ errorAlert: true, alertMessage: loginRequestData.errors})
    setTimeout(()=>{this.setState({ errorAlert: false}) 
    // history.push('/auth/login-page');
  }
    , 3000)
  };
  handleToggle = value => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };
  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <div className={classes.container}>

<Snackbar
                    place="tr"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.alertMessage}
                    open={this.state.errorAlert}
                    closeNotification={() => this.setState({ errorAlert: false })}
                    close
                  />
        <GridContainer justify="center">
          <GridItem xs={12} sm={8}>
            <h4 className={classes.textCenter} style={{ marginTop: 0 }}>
              Log in to manage Earth911 resources{" "}
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.login}>
              <Card className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <div className={classes.socialLine}>
                  <a
        href="https://earth911.com"
       
      >
        
      <img style={{width: '200px'}} src={logo} alt="logo" className={classes.img} />
      </a>
                    {/* {[
                      "fa fa-facebook-square",
                      "fa fa-twitter",
                      "fa fa-google-plus"
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })} */}
                  </div>
                </CardHeader>
                <CardBody>
                  <p
                    className={`${classes.textCenter} ${classes.checkboxLabel}`}
                  >
                    Welcome to the new Management portal{" "}
                  </p>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    error={errors.email || errors.invalidEmailOrPassword}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    inputProps={{
                      required: true,
                      name: "email",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    error={errors.password || errors.invalidEmailOrPassword}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    inputProps={{
                      type: "password",
                      required: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        classes.checkboxLabelControl +
                        " " +
                        classes.checkboxLabelControlClassName,
                      label: classes.checkboxLabel
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label={<span>Remember me</span>}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type="submit" color="primary" simple size="lg" block>
                    Let's Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  errors: PropTypes.object
};

export default withStyles(loginPageStyle)(LoginPage);
