import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import OpenInNew from "@material-ui/icons/OpenInNew";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LinearProgress from '@material-ui/core/LinearProgress';


import CustomInput from "components/CustomInput/CustomInput.jsx";

import { Link } from 'react-router-dom';
import { bugs, website, server } from "variables/general.jsx";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
const { REACT_APP_SERVER_URL } = process.env;

class EarthDashboard extends React.Component {
  state = {
    listings_count: {new_programs: 0, new_locations: 0, locations: 0, programs: 0},
    value: 0,
    locations: [],
    programs: [],
    groups: [],
    changes_l_left: 0,
    changes_p_left: 0,
    update_l_left: 0,
    update_p_left: 0,
    url_validation: [],
    location_results: {four_o_fours: 0, four_o_fours_updated: 0, three_hundreds: 0, not_changed: 0, changed: 0, no_url: 0, bad_urls: 0, total: 0},
    location_updates: [],
    program_results: {four_o_fours: 0, four_o_fours_updated: 0, three_hundreds: 0, not_changed: 0, changed: 0, no_url: 0, bad_urls: 0, total: 0},
    program_updates: [],
    group_locations: [],
    contact_tasks: [],
    actual_contact_tasks: [],
    listings_tasks: [],
    actual_listings_tasks: [],
    api_response:false,
    visibility_tasks: [],
    actual_visibility_tasks: [],
    tasks_completed: {organization: false, locations: false, visibility: false},
    assignments_completed: false,
    errors: {},
    profile:{
      first_name: "",
      last_name: "",
      organization: "",
      phone: "",
      email: ""}
  };
  
  componentDidMount(){    
    this.getDashbaordData()

  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  getDashbaordData = async e => {
    // e.preventDefault();

    const { history } = this.props;

    // const fields = ["email", "password"];
    // const formElements = e.target.elements;

    // const formValues = fields
    //   .map(field => ({
        // [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    if(!apiSetupHeaders()){
        return history.push("/auth/login-page");
    }

    let dashboardRequest;
    
    try {
      dashboardRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/dashboard`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      dashboardRequest = response;
    }
    const { data: dashboardRequestData } = dashboardRequest;
    if (dashboardRequest.status == 200) {
        apiStoreHeaders(dashboardRequest.headers)
        let contact_tasks = []
        let actual_contact_tasks = []
        let listings_tasks = []
        let actual_listings_tasks = []
        let actual_visibility_tasks = []
        let visibility_tasks = []

        let listings_count = {new_program: dashboardRequestData.program_results.new_total, new_location:dashboardRequestData.location_results.new_total, new_total: ( dashboardRequestData.program_results.new_total +  dashboardRequestData.location_results.new_total), total: ( dashboardRequestData.program_results.total +  dashboardRequestData.location_results.total), programs: dashboardRequestData.program_results.total, locations: dashboardRequestData.location_results.total}

        // console.log(dashboardRequestData.tasks)
        
        let not_completed = false;
        localStorage.setItem('tasks', JSON.stringify(dashboardRequestData.tasks));
        localStorage.setItem('tasks_completed', JSON.stringify(dashboardRequestData.tasks_completed));
        localStorage.setItem('listings_count', JSON.stringify(listings_count));
        if(!dashboardRequestData.tasks_completed['organization'])
        for(let i = 0; i < dashboardRequestData.tasks['contact'].length ; i++){
          // console.log(dashboardRequestData.tasks.length, i, dashboardRequestData.tasks[i])
          if(dashboardRequestData.tasks['contact'][i].completed|| not_completed)       
          {
            contact_tasks.push(i)
          
            if(!not_completed)
            actual_contact_tasks.push(i)
          }
          else{
            not_completed = true
          }
        }
      
        let not_completed2 = false;
        for(let i = 0; i < dashboardRequestData.tasks['listings'].length ; i++){
          // console.log(dashboardRequestData.tasks.length, i, dashboardRequestData.tasks[i])
          if(dashboardRequestData.tasks['listings'][i].completed || not_completed2)
          {
            listings_tasks.push(i)
            if(!not_completed2)
            actual_listings_tasks.push(i)
          }
          else{
            not_completed2 = true
          }
        }
        let completed_logo = false
        let not_completed3 = false;
        for(let i = 0; i < dashboardRequestData.tasks['visibility'].length ; i++){
          console.log(dashboardRequestData.tasks['visibility'][i])
          // console.log(dashboardRequestData.tasks.length, i, dashboardRequestData.tasks[i])
          if(dashboardRequestData.tasks['visibility'][i].completed || not_completed3)
          {
            completed_logo = true
            visibility_tasks.push(i)
            if(!not_completed3)
            actual_visibility_tasks.push(i)
          }
          else{
            not_completed3 = true
          }
        }

        console.log(contact_tasks)
        this.setState({ 
          profile: dashboardRequestData.profile,
          api_response:true,
          listings_count: listings_count,
    changes_l_left: dashboardRequestData.changes_l_left,
    changes_p_left: dashboardRequestData.changes_p_left,
    update_l_left: dashboardRequestData.update_l_left,
    update_p_left: dashboardRequestData.update_p_left,
          assignments_completed: true,
          contact_tasks: contact_tasks,
          listings_tasks: listings_tasks,
          actual_listings_tasks: actual_listings_tasks,          
          visibility_tasks: visibility_tasks,
          actual_visbility_tasks: actual_visibility_tasks,
          tasks_completed: dashboardRequestData.tasks_completed,
          locations: dashboardRequestData.locations, 
          location_results: dashboardRequestData.location_results, 
          location_updates: dashboardRequestData.location_updates,
          
          // programs: dashboardRequestData.programs, 
          program_results: dashboardRequestData.program_results, 
          program_updates: dashboardRequestData.program_updates,
          group_locations: dashboardRequestData.group_locations
        })
    }else{
        return history.push("/auth/login-page");
    }
    this.setState({
    //   errors: loginRequestData.messages && loginRequestData.messages.errors
    });
    
    let token =this.props.location.search;
    if(token.indexOf('completed=true') !== -1)
    return history.push("/update/organization");
    // this.setState({fromTask: true})


  };
  render() {
    const { classes } = this.props;
    const {errors} = this.state

    let tasks = []
    if(!this.state.tasks_completed.organization)
    tasks.push({
      tabName: "Company",
      tabIcon: Accessibility,
      tabContent: (
        <Tasks
          checkedIndexes={this.state.contact_tasks}
          actualCheckedIndexes={this.state.actual_contact_tasks}
          tasksIndexes={[0, 1, 2]}
          tasks={bugs}
          task_url={"/update/tasks/organization-task"}
        />
      )
    })
    if(!this.state.tasks_completed.locations && this.state.tasks_completed.organization)
    tasks.push(
    {
      tabName: "Locations",
      tabIcon: Code,
      tabContent: (
        <Tasks
          checkedIndexes={this.state.listings_tasks}
          actualCheckedIndexes={this.state.actual_listings_tasks}
          tasksIndexes={[0,1,2]}
          tasks={[
  "Verify Listing Changes - " + (this.state.changes_l_left > 0 ? " Locations remaining: " + this.state.changes_l_left : "") + (this.state.changes_p_left > 0 ? ", Programs remaining: " + this.state.changes_p_left : ""),
  "Update Listings - "+ (this.state.update_l_left > 0 ? " Locations remaining: " + this.state.update_l_left : "") + (this.state.update_p_left > 0 ? ", Programs remaining: " + this.state.update_p_left : ""),
  'Add any missing Listings'
]}
          task_url={"/update/tasks/listing-task"}
        />
      )
    })
    
    if(!this.state.tasks_completed.visibility && this.state.tasks_completed.locations)
    tasks.push(
    {
      tabName: "Increase Visibility",
      tabIcon: Code,
      tabContent: (
        <Tasks
          checkedIndexes={this.state.visibility_tasks}
          actualCheckedIndexes={this.state.actual_visibility_tasks}
          tasksIndexes={[0,1,2]}
          tasks={server}
          task_url={"/update/visibility?from_listing=true"}
        />
      )
    })
  
  //   {
  //     tabName: "Increase Visibility",
  //     tabIcon: Cloud,
  //     tabContent: (
  //       <Tasks
  //         checkedIndexes={[]}
  //         tasksIndexes={[0, 1, 2]}
  //         tasks={server}
  //         task_url={"/update/tasks/visibility-task"}
  //       />
  //     )
  //   }


    let locations = [[]];
    if(this.state.locations.length > 0){
      let loc_index = 0
        locations = 
            this.state.locations.map((location)=>{
              
              loc_index += 1
            return [ 
              location.description, location.city, location.status_id, 
           
               <span>
               
                       <a target="_blank"
                               href={location.url} className={classes.tdNameAnchor}>
                       {location.url}
                       
                       </a>
                             </span>,
                              <span>
                              
                                      <a target="_blank"
                                              href={this.state.location_updates[loc_index-1]} className={classes.tdNameAnchor}>
                                              {this.state.location_updates[loc_index-1]}
                                      </a>
                                            </span>,
                                               <span>
                                               
                                                               
                                                               
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="Update"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                     
                                                     </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    let group_locations = [[]];
    if(this.state.locations.length > 0){
      let loc_index = 0
        locations = 
            this.state.locations.map((location)=>{
              
              loc_index += 1
            return [ 
              location.description, location.city, location.status_id, 
           
               <span>
               
                       <a target="_blank"
                               href={location.url} className={classes.tdNameAnchor}>
                       {location.url}
                       
                       </a>
                             </span>,
                              <span>
                              
                              {this.state.location_updates[loc_index-1] == "removed" && 
                              <i>
                                This URL was removed
                                </i>

                            }
                              {this.state.location_updates[loc_index-1] == "not_changed" && 
                              <i>
                                No change was made
                                </i>

                            }
                              {this.state.location_updates[loc_index-1] != "not_changed" && this.state.location_updates[loc_index-1] != "removed" && 
                            
                                      <a target="_blank"
                                              href={this.state.location_updates[loc_index-1]} className={classes.tdNameAnchor}>
                                              {this.state.location_updates[loc_index-1]}
                                      </a>
                                      }
                                            </span>
                          ,
                                               <span>
                                               
                                                               
                                                               
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="Update"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                     
                                                     </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    return (
      <div>
        
        {!this.state.api_response &&
                    <LinearProgress variant="query" />
                    }
        {this.state.api_response &&
        <GridContainer>
          {this.state.location_results.total == 0 && this.state.program_results.total == 0 && !this.state.tasks_completed.organization &&
       <GridItem xs={12} sm={6} md={4} lg={4}>
        <Card>
        <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>
          Create your organization's profile 
          </h4>
          </CardHeader>
          <CardBody>
                  <Link to={`/update/organization?task=true&new=true`} activeClassName="active">
                                                       <Button color="primary" aria-label="Go">
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/> Get Started
                                                       </Button>
                                                       </Link>
                                                       </CardBody>
                </Card>     
        </GridItem>
          }
            {this.state.location_results.total == 0 && this.state.program_results.total == 0 && this.state.tasks_completed.organization && !this.state.tasks_completed.locations && 
        <GridItem xs={12} sm={6} md={4} lg={4}>
        <Card>
              <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
          Add a new listing
          </h4>
              </CardHeader>
              <CardBody>
              <Link to={`/update/tasks/listing-task`} activeClassName="active">
                                                       <Button color="success" aria-label="Go">
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/> Get Started
                                                       </Button>
                                                       </Link>
                </CardBody>
                </Card>                  
        </GridItem>
          }
           {this.state.tasks_completed.organization && this.state.tasks_completed.locations && 
        <GridItem xs={12} sm={6} md={4} lg={4}>
        <Card>
              <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
          Add a new listing
          </h4>
          </CardHeader>
          <CardBody>
                  <Link to={`/update/listings?add=true`} activeClassName="active">
                                                       <Button color="success" aria-label="Go">
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/> Get Started
                                                       </Button>
                                                       </Link>
                                                       </CardBody>
                </Card>      
        </GridItem>
           }
          
        </GridContainer>
        }
        {(this.state.location_results.total > 0 || this.state.program_results.total > 0) && !this.state.tasks_completed.organization && this.state.profile.email !== "" &&
          <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.updateProfile}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>My Organization</h4>
                  
                </CardHeader>
                <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Organization Name"
                        id="organization-name"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          required: true,
                          defaultValue: this.state.profile.organization,
                          name: "organization-name"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText=" First Name"
                        id="name"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          disabled: true,
                          defaultValue: this.state.profile.first_name,
                          // value: this.state.profile.first_name,
                          name: "first_name"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="name"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          disabled: true,
                          defaultValue: this.state.profile.last_name,
                          // value: this.state.profile.last_name,
                          name: "last_name"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Phone Number"
                        id="Phone Number"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          disabled: true,
                          defaultValue: this.state.profile.phone,
                          name: "phone"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email address"
                        id="email-address"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          disabled: true,
                          defaultValue: this.state.profile.email,
                          name: "username"
                        }}
                      />
                    </GridItem>
                    
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Link to={`/update/tasks/organization-task`} activeClassName="active">
                  <Button type="submit" color="primary">
                    Update Profile
                  </Button>
                  </Link>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>   
        }
         
        <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card style={{height:"140px"}}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Total Locations</p>
              <h3 className={classes.cardTitle}>{this.state.location_results.total}</h3>
            </CardHeader>              
            <CardFooter stats>
            <div className={classes.stats}>
              <Update />
              
              {(this.state.listings_count.new_location - this.state.listings_count.locations == 0) && 
              <span>
              Just Updated
              </span>
              }
              {(this.state.listings_count.new_location - this.state.listings_count.locations !== 0) && 
              <span>{this.state.listings_count.new_location - this.state.listings_count.locations} listing(s) are pending approval </span>}
            </div>
          </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card style={{height:"140px"}}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>Locations Changed</p>
                <h3 className={classes.cardTitle}>{this.state.location_results.changed}</h3>
              </CardHeader>              
              <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
            </Card>
            </GridItem>           
        <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card style={{height:"140px"}}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Total Programs</p>
              <h3 className={classes.cardTitle}>{this.state.program_results.total}</h3>
            </CardHeader>              
            <CardFooter stats>
            <div className={classes.stats}>
              <Update />
              {(this.state.listings_count.new_program- this.state.listings_count.programs == 0) && 
              <span>Just Updated
                </span>
              }
              {(this.state.listings_count.new_program- this.state.listings_count.programs !== 0) && 
              <span>{this.state.listings_count.new_program - this.state.listings_count.programs} listing(s) are pending approval </span>} 
            </div>
          </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card style={{height:"140px"}}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>Programs Changed</p>
                <h3 className={classes.cardTitle}>{this.state.program_results.changed}</h3>
              </CardHeader>              
              <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
            </Card>
            </GridItem> 
            
        </GridContainer>
        
        {this.state.assignments_completed && 
         (this.state.location_results.total !== 0 || this.state.program_results.total !== 0) &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <CustomTabs
              title="Tasks:"
              headerColor="primary"
              tabs={tasks}
            />
          </GridItem>
        </GridContainer>
        }
        {/* <GridContainer>


          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Location URLs</h4>
                <p className={classes.cardCategoryWhite}>
                Updated as of December 2019
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["404s""Bad(resulted in error)", "Redirected",  "No URL", "Not Updated"]}
                  tableData={ [[
                    
                    <h3 className={classes.cardTitle}>
                  {this.state.location_results.four_o_fours_updated > 0 &&
                  <span>{this.state.location_results.four_o_fours_updated} <small>Updated</small><br/></span>
                  }
                  { this.state.location_results.four_o_fours > 0 &&
                   <span>{this.state.location_results.four_o_fours} <small>No Update Found, Removed</small></span>
                  }
                  
                  { this.state.location_results.four_o_fours == 0 &&
                   <span>{this.state.location_results.four_o_fours} <small>Removed/Updated</small></span>
                  }
                </h3>,
                
                <h3 className={classes.cardTitle}>{this.state.location_results.bad_urls} <small>Removed</small></h3>,
                <h3 className={classes.cardTitle}>{this.state.location_results.three_hundreds} <small>Updated</small></h3>,
                
                <h3 className={classes.cardTitle}>{this.state.location_results.no_url}<small> Not Changed</small></h3>,
                
                <h3 className={classes.cardTitle}>{this.state.location_results.not_changed} <small>Remained Valid</small></h3>,
                ]] }
                />         
              
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer> */}
        {/* <GridContainer>


          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Program URLs</h4>
                <p className={classes.cardCategoryWhite}>
                Updated as of December 2019
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["404s", "Bad(resulted in error)", "Redirected",  "No URL", "Not Updated"]}
                  tableData={ [[
                    
                    <h3 className={classes.cardTitle}>
                  {this.state.program_results.four_o_fours_updated > 0 &&
                  <span>{this.state.program_results.four_o_fours_updated} <small>Updated</small><br/></span>
                  }
                  { this.state.program_results.four_o_fours > 0 &&
                   <span>{this.state.program_results.four_o_fours} <small>No Update Found, Removed</small></span>
                  }
                  
                  { this.state.program_results.four_o_fours == 0 &&
                   <span>{this.state.program_results.four_o_fours} <small>Removed/Updated</small></span>
                  }
                </h3>,
                
                <h3 className={classes.cardTitle}>{this.state.program_results.bad_urls} <small>Removed</small></h3>,
                <h3 className={classes.cardTitle}>{this.state.program_results.three_hundreds} <small>Updated</small></h3>,
                
                <h3 className={classes.cardTitle}>{this.state.program_results.no_url}<small> Not Changed</small></h3>,
                
                <h3 className={classes.cardTitle}>{this.state.program_results.not_changed} <small>Remained Valid</small></h3>,
                ]] }
                />         
              
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer> */}
        
        {
        //   this.state.locations.length > 0 &&
        // <GridContainer>     
        //   <GridItem xs={12} sm={12} md={12}>
        //     <Card>
        //       <CardHeader color="warning">
        //         <h4 className={classes.cardTitleWhite}>Locations - You created</h4>
        //         <p className={classes.cardCategoryWhite}>
        //           {/* New employees on 15th September, 2016 */}
        //         </p>
        //       </CardHeader>
        //       <CardBody>
        //           {this.state.locations.length > 0 &&
        //         <Table
        //           tableHeaderColor="warning"
        //           tableHead={["Name", "City", "Status", "Original", "Updated"]}
        //           tableData={ locations }
        //         />
        //           }
        //       </CardBody>
        //     </Card>
        //   </GridItem>
          
        // </GridContainer>
        }
        

      </div>
    );
  }
}

EarthDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(EarthDashboard);
