import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from '@material-ui/core/Snackbar'
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import MUIPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import UpdateMaterials from 'components/Materials/UpdateMaterials'
import ValidateListing from 'components/Listings/ValidateListing'
import AddListing from 'components/Listings/AddListing'

import {DebounceInput} from 'react-debounce-input';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import DateFnsUtils from '@date-io/date-fns';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';


import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Send from "@material-ui/icons/Send";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Help from "@material-ui/icons/Help";
import Undo from "@material-ui/icons/Undo";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import OpenInNew from "@material-ui/icons/OpenInNew";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"




// import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class AllListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_reponse: false,
      listing_changes: [],
      viewDialog: false,
      childComponent: false,
      viewingMaterials: '',
      validatingListing: '',
      // materialsSelection: "finalize", //yes, options ** DEVELPOPMERNT **
      materialsSelection: "no", //yes, options
      families: [],
      family: [],
      listings: [],
      listing: [],
      // listingSelect: ''
      suggestedPlace: [],
      formattedAddress:{},
      last_updated: {listing: {}, updated_url: ''},
      updateState: {current: "viewing_all", listing: {
        current: {},
        all: [],
        update_all: true,
        updated_all: false,

      }},
      errors: {},
      locations: [],
      locations_new: [],
      programs_new: [],
      edit_locations: [],
      location_updates: [],
      location_update_action: [],
      programs: [],
      edit_programs: [],
      program_updates: [],
      program_update_action: [],
      current_task: {completed: true},
      all_listings_completed: false,
      adding_location: false,
      listingSelect: "",
      locationSelect: "",
      programSelect: "",
      listing_name: "",
      enterAddressManually: false,
      new_url: '',
      open: false, suggestion: null ,
      selectedDate: new Date('2014-08-18T21:11:54'), 
      nothing_found: false,
      // setSelectedDate,
    //   !this.state.tasks.contacts[0].completed
      tasks: {listings: [
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
      ]},
      openDialog: false,
      completedAddress: false,
      materials: [],
      added_materials: [],
    };
    this.getListings = this.getListings.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.myRef = React.createRef()   // Create a ref object 
    // this.addProfile = this.updateProfile.bind(this);
  }
  backToAllFamilies = () =>{
    this.setState({family: []})
  }
  handleDateChange = date => {
    this.setState({selectedDate: date});
  };
  moveOnToMaterials = (value)=>{
    this.setState({materialsSelection: value})

  }
      
 
//  handleClose = () => {
//     onClose(selectedValue);
//   };

//  handleListItemClick = value => {
//     onClose(value);
//   };
addMaterial=(material)=>{
  console.log(material)

  // material.disabled = true
  let removedMaterial = this.state.materials
  removedMaterial.splice(material.index, 1)
  let materials = this.state.added_materials
  materials.push(material)
  material.index = materials.length - 1
  this.setState({added_materials: materials, materials: removedMaterial})
}

removeMaterial=(material)=>{
  console.log(material)
  // material.disabled = true
  let removedMaterial = this.state.added_materials
  removedMaterial.splice(material.index, 1)
  let materials = this.state.materials
  materials.push(material)
  this.setState({added_materials: removedMaterial, materials: materials})
}
  onClose=() =>{
    this.setState({ open: false })
  }
  enterAddressManually=() =>{
    // if(!this.state.enterAddressManually)
    this.setState({ enterAddressManually: !this.state.enterAddressManually, suggestedPlace: [], formattedAddress: {}, suggestion: null})
  }
  onSuggestionSelected=(suggestion) =>{
    // Add your business logic here. In this case we just log...
    this.setState({ open: true, suggestion })
    geocodeByPlaceID(suggestion.place_id).then((results) => {

      console.log(results)

      let formattedAddress = {}
      results[0].address_components.forEach(address_component =>{
        formattedAddress[address_component.types[0]] = {s: address_component.short_name, l:address_component.long_name}

      })
      this.setState({suggestedPlace: results, formattedAddress: formattedAddress})
      // Add your business logic here. In this case we simply set our state with the coordinates of
      // the selected suggestion...

      // Just use the first result in the list to get the geometry coordinates
      // const { geometry } = results[0]

      // const coordinates = {
      //   lat: geometry.location.lat(),
      //   lng: geometry.location.lng(),
      // }

      // this.setState({ coordinates })
    }).catch((err) => {
      // Handle any errors that occurred when we tried to get geospatial data for a selected
      // suggestion...
    })
    
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleFamily = event =>{
    let family_id = event.target.value
    this.setState({familySelect: family_id, materials:[]})
    this.getFamily(family_id)
  }
  
  handleListing = event =>{
    let listing_id = event.target.value
    this.setState({listingSelect: listing_id, materials:[]})
    this.getListing(listing_id)
  }
  resetLocation = (location) =>{
    
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'reset_location'

    this.setState({location_update_action: location_update_action})
  }
  resetProgram = (program) =>{
    
    let program_update_action = this.state.program_update_action
    program_update_action[program.index] = 'reset_program'

    this.setState({program_update_action: program_update_action})
  }
  checkAllLocationsCompleted = ()=>{
    let completed = true;
    console.log(this.state.locations.length)
    for(let i = 0; i<this.state.locations.length; i++){
      console.log(this.state.locations[i].updated)
    if(!this.state.locations[i].updated)
      { console.log(this.state.locations[i])
        completed = false
      break;}
    }
    if(completed)
    this.setState({all_listings_completed: true})

  }
  backToListings = (updated_all) => {
    let updateState =  {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }}
    if(updated_all){
      this.state.locations[this.state.updateState.listing.current.index].updated = true
      this.checkAllLocationsCompleted()

      
    let location_update_action = this.state.location_update_action
    if(this.state.new_url !== "mixed")
    if(this.state.updateState.listing.all.length > 1)
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all to: ' + this.state.new_url
    else
    location_update_action[this.state.updateState.listing.current.index] = 'Updated to: ' + this.state.new_url
    else{
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all URLs individually'

    }

    this.setState({updateState: updateState, last_updated: {listing: this.state.updateState.listing.current, updated_url: this.state.new_url}, new_url: '', location_update_action: location_update_action})
  }

    else{
    this.setState({updateState: updateState, new_url: ''})}

  }


  async getListings(){
    
    const { history } = this.props;
    
        // const fields = ["email", "password"];
        // const formElements = e.target.elements;
    
        // const formValues = fields
        //   .map(field => ({
            // [field]: formElements.namedItem(field).value
        //   }))
        //   .reduce((current, next) => ({ ...current, ...next }));
    
        if(!apiSetupHeaders()){
            return history.push("/auth/login-page");
        }
    
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/listings`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
   
    for(let i = 0; i<taskRequestData.locations.length; i++){
      taskRequestData.locations[i].type = 'location'
      taskRequestData.locations[i].index = i
    }
      
    for(let i = 0; i<taskRequestData.locations_new.length; i++){
      taskRequestData.locations_new[i].type = 'location'
      taskRequestData.locations_new[i].index = i
    }
    for(let i = 0; i<taskRequestData.programs_new.length; i++){
      taskRequestData.programs_new[i].type = 'program'
      taskRequestData.programs_new[i].index = i
    }
    for(let i = 0; i<taskRequestData.programs.length; i++){
      taskRequestData.programs[i].type = 'program'
      taskRequestData.programs[i].index = i
    }
    this.setState({api_response: true, locations: taskRequestData.locations, locations_new: taskRequestData.locations_new, programs_new: taskRequestData.programs_new, 
      programs: taskRequestData.programs})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  
  
  updateAllIndividually = (value)=>{
    let updateState = this.state.updateState
    updateState.listing.update_all = value;
    this.setState({updateState: updateState})
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  componentDidMount(){
    this.setState({api_response: false})
    document.getElementById('topAppBar').scrollIntoView();
    // this.getProfileData()
    
    let token =this.props.location.search;
    if(token.indexOf('add=true') !== -1)
    this.addListing()
    else
    this.getListings()
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    // if(this.props.last_updated !== prevProps.last_updated && this.props.last_updated.listing.index !== null){
      
    // let location_update_action = this.state.location_update_action
    // location_update_action[this.props.last_updated.listing.index] = 'Updated all to: ' + this.props.last_updated.new_url
    // this.setState({location_update_action: location_update_action})
    // }

  }
  handleClose = () => {

    this.setState({openDialog: !this.state.openDialog})

    // setOpen(false);
    // setSelectedValue(value);
  };
  
  handleChange = (event) => {
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    this.setState({[event.target.name]: event.target.value});
  }

  
 
  viewMaterials=(listing) =>{
    this.setState({
      childComponent: true,
      viewingMaterials:
    <UpdateMaterials
    task={false}
      listing={listing}
      back={this.backFromUpdatingMaterials}
     />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }

  viewChanges = async (listing) => {

    
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/audit?`+listing.type+"="+listing.uuid,
        // {
        //   listing_type: listing.type
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    // console.log(taskRequestData)

    this.setState({listing_name: listing.description, listing_changes: taskRequestData, viewDialog: true})
    
    apiStoreHeaders(taskRequest.headers)


    // this.setState({
    //   childComponent: true,
    //   viewingMaterials:
    // <UpdateMaterials
    // task={false}
    //   listing={listing}
    //   back={this.backFromUpdatingMaterials}
    //  />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
  backFromAddingListing = () =>{
    this.setState({
      childComponent: false, addListing: ''
    })
    
    this.getListings()

  }
  addListing=() =>{
    // this.setState({api_response: false})
    // console.log(listing)
    
  this.setState({
    api_response: false,
    childComponent: true, addListing: <AddListing task={false} back={this.backFromAddingListing}/>})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
  validateListing=(listing) =>{
    console.log(listing)
    this.setState({
      childComponent: true,
      validatingListing:
    <ValidateListing
      listing={listing}
      back={this.backFromValidatingListing}
     />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
  backFromUpdatingMaterials = (payload) =>{
    if(payload.completed){
    console.log(payload)
    document.getElementById('topGo').scrollIntoView();    
    let lua = ''
    let listing_type = payload.listing.type
    if(listing_type == "location")
    lua = this.state.location_update_action
    else
    lua = this.state.program_update_action
    if(payload.completed){
      lua[payload.listing.index] = payload.completed

    }
    if(listing_type == "location")
    this.setState({childComponent: false, viewingMaterials: "", location_update_action: lua})
    else
    this.setState({childComponent: false, viewingMaterials: "", program_update_action: lua})
  
  }else{
    this.setState({childComponent: false, validatingMaterials: ""})
  }
  }
  
  backFromValidatingListing = (payload) =>{ 
    if(this.props.task)   
    if(payload.completed){
    console.log(payload)
    document.getElementById('topGo').scrollIntoView();
    let lua = ''
    let listing_type = payload.listing.type
    if(listing_type == "location")
    lua = this.state.location_update_action
    else
    lua = this.state.program_update_action

      lua[payload.listing.index] = payload.completed

    // }
    if(listing_type == "location")
    this.setState({childComponent: false, validatingListing: "", location_update_action: lua})
    else
    this.setState({childComponent: false, validatingListing: "", program_update_action: lua})
    }else{
      this.setState({childComponent: false, validatingListing: ""})
    }
    else{
      this.getListings()
      this.setState({childComponent: false, validatingListing: ""})
    }
  }
  getProfileData = async e => {
    apiSetupHeaders()
    // e.preventDefault();

    // const { history } = this.props;

    // // const fields = ["email", "password"];
    // // const formElements = e.target.elements;

    // // const formValues = fields
    // //   .map(field => ({
    //     // [field]: formElements.namedItem(field).value
    // //   }))
    // //   .reduce((current, next) => ({ ...current, ...next }));

    // if(!apiSetupHeaders()){
    //     return history.push("/auth/login-page");
    // }

    let profileRequest;
    
    try {
      profileRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/profile`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    if (profileRequest.status == 200) {
        // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        this.setState({profile: profileRequestData.profile})
    }else{
        // return history.push("/auth/login-page");
    }
    this.setState({
    //   errors: loginRequestData.messages && loginRequestData.messages.errors
    });
  };
  render() {
    const { classes, name, email } = this.props;
    const { errors, locationSelect, programSelect, listingSelect,  open, suggestion, formattedAddress, selectedDate } = this.state
    let edit_locations = []
    
    let locations = [[]];
    if(this.state.locations.length > 0){
      let loc_index = 0
        locations = 
            this.state.locations.map((location)=>{
              
              loc_index += 1
              let updated_msg = ""
              if(this.state.location_updates[loc_index-1] == "Removed URL")
              {
                updated_msg = <span style={{"color":"red"}} >Removed the URL</span>
              }
              else{
                if(this.state.location_updates[loc_index-1] == "200")
                updated_msg = "No change committed"
                else
                updated_msg = "Updated to: " + this.state.location_updates[loc_index-1]
              }
            return [ 
              // location.description, location.city, location.status_id, 
           
              //  <span>
               
              //          <a style={{"color":"red"}} target="_blank"
              //                  href={location.url}>
              //          {location.url}
                       
              //          </a>
              //                </span>
              <span>{location.description}
                </span>
              ,
              
              <span>{location.city}
              </span>
            ,
            
            <span>{location.province}
            </span>
          ,
          <span>
          
                             
                          
          <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button simple={true} color="success" aria-label="Edit" className={classes.tableActionButton} onClick={() => this.validateListing(location)}>
                    Edit 
                  </Button>
                  
                </Tooltip>
       
      
                </span>,
                <span>
 <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button  simple={true}  color="warning" aria-label="Edit" className={classes.tableActionButton} onClick={() =>this.viewMaterials(location)}>
                    Materials
                  </Button>
                  
                </Tooltip>
       
                
                </span>,
                
                <span>
                <Tooltip
                                 id="tooltip-top"
                                 title="View Changes"
                                 placement="top"
                                 classes={{tooltip:classes.tooltip}}>
                                
                                 <Button  simple={true} disabled={location.updates? false:true}  color="danger" aria-label="View Changes" className={classes.tableActionButton} onClick={() =>this.viewChanges(location)}>
                                   {location.updates? "View Changes":"  "}
                                 </Button>
                                 
                               </Tooltip>
                      
                               
                               </span>
              // location.effects_count,
                              // <span style={{"color":"green"}} >
                              // {updated_msg}
                              //               </span>,
                                            
                      //           <span>
                      //                         {this.state.location_update_action[loc_index-1] == "Update" && 
                                              
                      //           <span>
                      // <CustomInput
                      //   labelText="New URL"
                      //   id="new_url"
                      //   /* error={errors.name} */
                      //   formControlProps={{
                      //     fullWidth: true
                      //   }}
                      //   inputProps={{
                      //     // required: true,
                      //     // defaultValue: "",
                      //     name: "new_url",
                      //   }}
                      // />
                      
                      // <Button color="success" aria-label="Update" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}> Save
                      //                               </Button>
                                                    
                      //           </span>
                                            
                      //                       }
                                            
                              // <span>
                              
                              //                 {this.state.location_update_action[loc_index-1] == "action_required" ? "" : this.state.location_update_action[loc_index-1]}
                              //               </span>

                              //               </span>
                                            ,
                                            
                                            
                                            // <span>
                                            
                                            // {this.state.location_update_action[loc_index-1] != "action_required" &&            
                                            //  <Tooltip
                                            //         id="tooltip-top"
                                            //         title="Undo"
                                            //         placement="top"
                                            //         classes={{tooltip:classes.tooltip}}>
                                            //         <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetLocation(location)}>
                                            //           <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //         </IconButton>
                                            //       </Tooltip>
                                                  
                                            // }
                                                            
                                            // {this.state.location_update_action[loc_index-1] == 'action_required' &&   
                                            // <Tooltip
                                            //         id="tooltip-top"
                                            //         title="Agree with action we took."
                                            //         placement="top"
                                            //         classes={{tooltip:classes.tooltip}}>
                                            //         <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(location)}>
                                            //           <Check className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //         </IconButton>
                                            //       </Tooltip>
                                            // }
                                                  
                                            //       </span>
                                                  ,
                                            //    <span>
                                               
                                                  
                                            // {this.state.location_update_action[loc_index-1] == "action_required" &&                  
                                                               
                                            //    <Tooltip
                                            //            id="tooltip-top"
                                            //            title="Update"
                                            //            placement="top"
                                            //            classes={{tooltip:classes.tooltip}}>
                                                      
                                            //            <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.updateURL(location)}>
                                            //              <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //            </IconButton>
                                                       
                                            //          </Tooltip>
                                            // }
                                                     
                                            //          </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={location.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    let programs_new = [[]];
    if(this.state.programs_new.length > 0){
      let loc_index = 0
        programs_new = 
            this.state.programs_new.map((program)=>{
              
              loc_index += 1
              let updated_msg = ""
             
            return [ 
              // program.description, program.city, program.status_id, 
           
              //  <span>
               
              //          <a style={{"color":"red"}} target="_blank"
              //                  href={program.url}>
              //          {program.url}
                       
              //          </a>
              //                </span>
              <span>{program.description}
                </span>
              ,
              
              <span>{program.city}
              </span>
            ,
            
            <span>{program.province}
            </span>
          ,
          <span>
          
                             
                          
          <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button simple={true} color="success" aria-label="Edit" className={classes.tableActionButton} onClick={() => this.validateListing(program)}>
                    Edit 
                  </Button>
                  
                </Tooltip>
       
      
                </span>,
                <span>
 <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button  simple={true}  color="warning" aria-label="Edit" className={classes.tableActionButton} onClick={() =>this.viewMaterials(program)}>
                    Materials
                  </Button>
                  
                </Tooltip>
       
                
                </span>,
                
                <span>
                <Tooltip
                                 id="tooltip-top"
                                 title="View Changes"
                                 placement="top"
                                 classes={{tooltip:classes.tooltip}}>
                                
                                 <Button  simple={true} disabled={program.updates? false:true}  color="danger" aria-label="View Changes" className={classes.tableActionButton} onClick={() =>this.viewChanges(program)}>
                                   {program.updates? "View Changes":"  "}
                                 </Button>
                                 
                               </Tooltip>
                      
                               
                               </span>,
                                              //                <span>
                                              //  <Tooltip
                                              //          id="tooltip-top"
                                              //          title="View on Earth911"
                                              //          placement="top"
                                              //          classes={{tooltip:classes.tooltip}}>
                                              //          <a target="_blank"
                                              //                  href={program.earth_link} className={classes.tdNameAnchor}>
                                              //          <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                              //            <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                              //          </IconButton>
                                                       
                                              //          </a>
                                              //        </Tooltip>
                                              //                </span>
              ]
        })
    
    }
    let locations_new = [[]];
    if(this.state.locations_new.length > 0){
      let loc_index = 0
        locations_new = 
            this.state.locations_new.map((location)=>{
              
              loc_index += 1
              let updated_msg = ""
             
            return [ 
              // location.description, location.city, location.status_id, 
           
              //  <span>
               
              //          <a style={{"color":"red"}} target="_blank"
              //                  href={location.url}>
              //          {location.url}
                       
              //          </a>
              //                </span>
              <span>{location.description}
                </span>
              ,
              
              <span>{location.city}
              </span>
            ,
            
            <span>{location.province}
            </span>
          ,
          <span>
          
                             
                          
          <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button simple={true} color="success" aria-label="Edit" className={classes.tableActionButton} onClick={() => this.validateListing(location)}>
                    Edit 
                  </Button>
                  
                </Tooltip>
       
      
                </span>,
                <span>
 <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button  simple={true}  color="warning" aria-label="Edit" className={classes.tableActionButton} onClick={() =>this.viewMaterials(location)}>
                    Materials
                  </Button>
                  
                </Tooltip>
       
                
                </span>,
                
                <span>
                <Tooltip
                                 id="tooltip-top"
                                 title="View Changes"
                                 placement="top"
                                 classes={{tooltip:classes.tooltip}}>
                                
                                 <Button  simple={true} disabled={location.updates? false:true}  color="danger" aria-label="View Changes" className={classes.tableActionButton} onClick={() =>this.viewChanges(location)}>
                                   {location.updates? "View Changes":"  "}
                                 </Button>
                                 
                               </Tooltip>
                      
                               
                               </span>,
                                              //                <span>
                                              //  <Tooltip
                                              //          id="tooltip-top"
                                              //          title="View on Earth911"
                                              //          placement="top"
                                              //          classes={{tooltip:classes.tooltip}}>
                                              //          <a target="_blank"
                                              //                  href={location.earth_link} className={classes.tdNameAnchor}>
                                              //          <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                              //            <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                              //          </IconButton>
                                                       
                                              //          </a>
                                              //        </Tooltip>
                                              //                </span>
              ]
        })
    
    }
    let programs = [[]];
    if(this.state.programs.length > 0){
      let loc_index = 0
        programs = 
            this.state.programs.map((program)=>{
              
              loc_index += 1
              let updated_msg = ""
              if(this.state.program_updates[loc_index-1] == "Removed URL")
              {
                updated_msg = <span style={{"color":"red"}} >Removed the URL</span>
              }
              else{
                if(this.state.program_updates[loc_index-1] == "200")
                updated_msg = "No change committed"
                else
                updated_msg = "Updated to: " + this.state.program_updates[loc_index-1]
              }
            return [ 
              // program.description, program.city, program.status_id, 
           
              //  <span>
               
              //          <a style={{"color":"red"}} target="_blank"
              //                  href={program.url}>
              //          {program.url}
                       
              //          </a>
              //                </span>
              <span>{program.description}
                </span>
              ,
              
              <span>{program.city}
              </span>
            ,
            
            <span>{program.province}
            </span>
          ,
          <span>
                         
                          
          <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button  simple={true}  color="success" aria-label="Edit" className={classes.tableActionButton} onClick={() => this.validateListing(program)}>
                    Edit 
                  </Button>
                  
                </Tooltip>
            </span>
          ,
          <span>
 <Tooltip
                  id="tooltip-top"
                  title="Update"
                  placement="top"
                  classes={{tooltip:classes.tooltip}}>
                 
                  <Button  simple={true} color="warning" aria-label="Edit" className={classes.tableActionButton} onClick={() =>this.viewMaterials(program)}>
                    Materials
                  </Button>
                  
                </Tooltip>
     
                
                </span>,
                
                <span>
                <Tooltip
                                 id="tooltip-top"
                                 title="View Changes"
                                 placement="top"
                                 classes={{tooltip:classes.tooltip}}>
                                
                                 <Button  simple={true} disabled={program.updates? false:true}  color="danger" aria-label="View Changes" className={classes.tableActionButton} onClick={() =>this.viewChanges(program)}>
                                   {program.updates? "View Changes":"  "}
                                 </Button>
                                 
                               </Tooltip>
                      
                               
                               </span>,
              // program.effects_count,
                              // <span style={{"color":"green"}} >
                              // {updated_msg}
                              //               </span>,
                                            
                      //           <span>
                      //                         {this.state.program_update_action[loc_index-1] == "Update" && 
                                              
                      //           <span>
                      // <CustomInput
                      //   labelText="New URL"
                      //   id="new_url"
                      //   /* error={errors.name} */
                      //   formControlProps={{
                      //     fullWidth: true
                      //   }}
                      //   inputProps={{
                      //     // required: true,
                      //     // defaultValue: "",
                      //     name: "new_url",
                      //   }}
                      // />
                      
                      // <Button color="success" aria-label="Update" className={classes.tableActionButton} onClick={() => this.markAsValid(program)}> Save
                      //                               </Button>
                                                    
                      //           </span>
                                            
                      //                       }
                                            
                              // <span>
                              
                              //                 {this.state.program_update_action[loc_index-1] == "action_required" ? "" : this.state.program_update_action[loc_index-1]}
                              //               </span>

                              //               </span>
                                            ,
                                            
                                            
                                            // <span>
                                            
                                            // {this.state.program_update_action[loc_index-1] != "action_required" &&            
                                            //  <Tooltip
                                            //         id="tooltip-top"
                                            //         title="Undo"
                                            //         placement="top"
                                            //         classes={{tooltip:classes.tooltip}}>
                                            //         <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.resetprogram(program)}>
                                            //           <Undo className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //         </IconButton>
                                            //       </Tooltip>
                                                  
                                            // }
                                                            
                                            // {this.state.program_update_action[loc_index-1] == 'action_required' &&   
                                            // <Tooltip
                                            //         id="tooltip-top"
                                            //         title="Agree with action we took."
                                            //         placement="top"
                                            //         classes={{tooltip:classes.tooltip}}>
                                            //         <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.markAsValid(program)}>
                                            //           <Check className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //         </IconButton>
                                            //       </Tooltip>
                                            // }
                                                  
                                            //       </span>
                                                  ,
                                            //    <span>
                                               
                                                  
                                            // {this.state.program_update_action[loc_index-1] == "action_required" &&                  
                                                               
                                            //    <Tooltip
                                            //            id="tooltip-top"
                                            //            title="Update"
                                            //            placement="top"
                                            //            classes={{tooltip:classes.tooltip}}>
                                                      
                                            //            <IconButton aria-label="Edit" className={classes.tableActionButton} onClick={() => this.updateURL(program)}>
                                            //              <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                            //            </IconButton>
                                                       
                                            //          </Tooltip>
                                            // }
                                                     
                                            //          </span>,
                                                             <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={program.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
              ]
        })
    
    }
    return (
      <div id="topGo">
        
        {this.state.viewingMaterials}
        {this.state.validatingListing}
        {this.state.addListing}
        { !this.state.childComponent &&
        <GridContainer> 
          { this.state.viewDialog &&
          <Dialog onClose={()=>this.setState({viewDialog: false})} aria-labelledby="simple-dialog-title" open={this.state.viewDialog}>
      <DialogTitle id="simple-dialog-title">Updates: {this.state.listing_name}</DialogTitle>
      <List>
        {this.state.listing_changes.map( listing_change=> {          

          let when = listing_change.date          
          let from = listing_change.user
          let what = []
          for(let change in listing_change.changes){
            console.log(change)
            console.log(listing_change.changes[change])
            what.push(
          <ListItem key={listing_change.date}> Changed: <span style={{fontWeight: "bold"}}>{change}</span></ListItem>)
          what.push(
          <ListItem key={listing_change.date}> <span style={{fontWeight: "bold"}}>from</span>:{listing_change.changes[change][0]}</ListItem>)
          what.push(
          <ListItem key={listing_change.date}> <span style={{fontWeight: "bold"}}>to</span>:<span style={{fontStyle: "italic"}}>{listing_change.changes[change][1]}</span></ListItem>)
          }
          console.log(listing_change.changes)
          // let what = listing_change.changes.map( change => {
          //   let change.key
          //   return(
          //   <div>
              
          //   </div>
          // )})
          return(
            <div>
          <ListItem key={listing_change.date}>
            {/* <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar> */}
            Date: <ListItemText primary={when} />
            
          </ListItem>
          <ListItem key={listing_change.date}>

            From: <ListItemText primary={from} />
            
          </ListItem>
            {what}
          </div>
        )
      }
      )
      }
      </List>
    </Dialog>

      }
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>All Listings</h4>
                <p className={classes.cardCategoryWhite}>
                  {/* New employees on 15th September, 2016 */}
                </p>
              </CardHeader>
              <CardBody>
        {!this.state.api_response &&
                    <LinearProgress variant="query" />
                    }
                  {this.state.locations.length > 0 &&
                <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    // "Name", "City", "Status", 
                    "Location Name", "City", "State", "", "", "","View on Earth911"]}
                  tableData={ locations } 
                />
                  }
                   {this.state.locations_new.length > 0 &&
                <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    // "Name", "City", "Status", 
                    "New Location Name", "City", "State", "", "", ""]}
                  tableData={ locations_new } 
                />
                  }
                  {this.state.programs.length > 0 &&
                <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    // "Name", "City", "", 
                    "Program Name", "City", "State",  "", "","", "View on Earth911"]}
                  tableData={ programs } 
                />
                  }

{this.state.programs_new.length > 0 &&
                <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    // "Name", "City", "", 
                    "New Program Name", "City", "State",  "", "",""]}
                  tableData={ programs_new } 
                />
                  }
              </CardBody>
              <CardFooter>
                  <Button disabled={this.state.updateState.listing.update_all ? false: true}
                  onClick={(e)=>this.addListing(e)} color="primary">
                    Add New Listings
                  </Button>
                  
                </CardFooter>
            </Card>
          </GridItem>
          
        </GridContainer>
        } 
      </div>
    );
  }
}

AllListings.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(AllListings);
