import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Checkbox from "@material-ui/core/Checkbox";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import IconButton from "@material-ui/core/IconButton";
import Table from "components/Table/Table.jsx";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class EarthUserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_user: false,
      listings: [],
      addUser:{
        next: "Skip",
        add: "Add"
      },
      listingSelect: "",
      errors: {},
      groups: [],
      your_groups: [],
      logo: '',
      new_logo: '',
      fromTask: false,
      access_all: true,
      admin: true,
      profile: {
        first_name: "",
        last_name: "",
        organization: "",
        phone: "",
        email: ""
        // activation_key: "ALREADY_ACTIVATED"
        // affiliations: ""
        // affiliations_other: ""
        // crm_id: ""
        // email: "amandar@ddsd.org"
        // first_name: "Amanda"
        // id: 31
        // last_name: "Roa"
        // organization: "Delta Diablo Sanitation District"
        // phone: "(925) 756-1940"
        // tos_agreed: 5
        // user_id: 45
      }
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.updateProfileInfo = this.updateProfileInfo.bind(this);
  }
  
  onChangeHandler=event=>{
    // console.log("CHANBGE")
        this.setState({new_logo: event.target.files[0]})
    
    }
  componentDidMount(){
    this.getProfileData()
    
    let token =this.props.location.search;
    if(token.indexOf('task=true') !== -1)    
    if(token.indexOf('new=true') !== -1)
    this.setState({fromTask: true, new_user: true})
    else
    this.setState({fromTask: true, new_user: false})

  }
  updateProfile = async(e) => {
    e.preventDefault();
    const { history } = this.props;

    // const fields = ["new_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    
    const data = new FormData() 
    data.append('picture', this.state.new_logo)
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/org_profile/logo`, data,
        // {
        //   profile: formValues
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    
    this.getProfileData()
    // if(registerRequest.status > 205){
    //     console.log(registerRequestData) // do something here
    // }else{
        
    //     apiStoreHeaders(registerRequest.headers)
    //     return history.push("/update/earth911");
        
    // }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  async updateProfileInfo(e) {
    e.preventDefault();
    apiSetupHeaders()
    const { history } = this.props;

    const fields = ["first_name", "last_name", "organization", "phone"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));
    // let payload = formValues
    let profileRequest;
    try {
      profileRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/profile`,
        {
          profile: formValues
        },
        {
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    
    if (profileRequest.status == 200) {
     
      let tasks = JSON.parse(localStorage.getItem('tasks'))
      tasks.contact =  profileRequestData.tasks
    localStorage.setItem('tasks', JSON.stringify(tasks));
    
    if(!profileRequestData.completed){    
    if(this.state.new_user)
      return history.push("/update/tasks/organization-task?new=true");
    else      
      return history.push("/update/tasks/organization-task");
    }
    else
    {
      if(this.state.fromTask)
      this.props.history.push("/update/tasks/listing-task");
      else
      this.props.history.push("/update/earth911");
    }
    
    
    // this.setState({profile: profileRequestData.profile})
    }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  getProfileData = async e => {
    apiSetupHeaders()
    // e.preventDefault();

    // const { history } = this.props;

    // // const fields = ["email", "password"];
    // // const formElements = e.target.elements;

    // // const formValues = fields
    // //   .map(field => ({
    //     // [field]: formElements.namedItem(field).value
    // //   }))
    // //   .reduce((current, next) => ({ ...current, ...next }));

    // if(!apiSetupHeaders()){
    //     return history.push("/auth/login-page");
    // }

    let profileRequest;
    
    try {
      profileRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/profile`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    if (profileRequest.status == 200) {
      // console.log("updated headers", profileRequest.headers)
      let logo = ""
      if(profileRequestData.org !== null){
        logo = REACT_APP_SERVER_URL+profileRequestData.org
      }
        apiStoreHeaders(profileRequest.headers)
        this.setState({admin: profileRequestData.admin,
          uuid: profileRequestData.uuid,
          profile: profileRequestData.profile, logo: logo, groups: profileRequestData.org_groups, your_groups: profileRequestData.your_groups })
    }else{
        // return history.push("/auth/login-page");
    }
    // this.setState({
    // //   errors: loginRequestData.messages && loginRequestData.messages.errors
    // });
  };
  
  resetAddUser = () =>{
    this.getListings()
    const fields = ["first_name", "last_name", "added_email"];
    fields.forEach((id)=>{
      document.getElementById(id).value = ""
    })
    this.setState({access_all: true, admin: true})
  }
  handleChange = (index, transfer) => {
    if(!transfer){
    let access_checked = this.state.accessChecked
    access_checked[index] = !access_checked[index]
    this.setState({accessChecked: access_checked});
    }else{
      let transfer_checked = this.state.transferChecked
      transfer_checked[index] = !transfer_checked[index]
      this.setState({transferChecked: transfer_checked});
    }
  };
  getListings = async () => {
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listings`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    let outbound = []
    let listing_count = 0;
    let accessChecked = []
    let transferChecked = []

    for(let i = 0; i < taskRequestData.listings.programs.length; i++){
      taskRequestData.listings.programs[i].index = listing_count
      listing_count = listing_count + 1
      taskRequestData.listings.programs[i].type = "program"
      outbound.push(taskRequestData.listings.programs[i])
      accessChecked.push(false)
      transferChecked.push(false)
    }
    for(let i = 0; i < taskRequestData.listings.locations.length; i++){
      taskRequestData.listings.locations[i].index = listing_count
      listing_count = listing_count + 1
      taskRequestData.listings.locations[i].type = "location"
      outbound.push(taskRequestData.listings.locations[i])
      accessChecked.push(false)
      transferChecked.push(false)
    }
    this.setState({listings: outbound, accessChecked: accessChecked, transferChecked: transferChecked})
    apiStoreHeaders(taskRequest.headers)

  }
  addUser = async (e) => {
    e.preventDefault();

    let access_all = this.state.access_all
    let access = []
    let transfer = []


    if(!access_all){
      for(let i = 0; i<this.state.listings.length; i++){
        if(this.state.accessChecked[this.state.listings[i].index])
          access.push(this.state.listings[i].uuid)      
        if(this.state.transferChecked[this.state.listings[i].index])
          transfer.push(this.state.listings[i].uuid)          
          // console.log(this.state.listings[i].index)
          // console.log(this.state.actualChecked[this.state.listings[i].index])
      }
    }else{
      access = 'all'
      transfer = 'none'
    }

    // console.log(access)

    const fields = ["first_name", "last_name", "added_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/profile/add_user`,
        {
          profile: formValues, access: access, transfer: transfer, admin: this.state.admin
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;

    if (!registerRequestData.success) {
      this.setState({ errorAlert: true, alertMessage: registerRequestData.messages.errors.email})
      setTimeout(()=>this.setState({ errorAlert: false}), 6000)
      this.setState({
        errors:
          registerRequestData.messages && registerRequestData.messages.errors
          
      });
    }else{
      this.resetAddUser()
      this.setState({ successAlert: true, alertMessage: "Great, we've added that User! Feel free to either add another User or move-on if you are all set."})
      setTimeout(()=>this.setState({ successAlert: false}), 6000)
      
    }
  }
  
  toggleAccessAll = () =>{
    this.setState({access_all: !this.state.access_all})
    if(this.state.access_all)
    this.getListings()

  }
  toggleAdmin = () =>{
    
    if(this.state.admin && this.state.access_all){
      this.toggleAccessAll()
    }
    this.setState({admin: !this.state.admin})
    // if(this.state.access_all)
    // this.getListings()

  }
  handleUpdateGroup = async (group) =>{
    if(group.locations_count <= 0 && group.programs_count <= 0){

    }else{
      apiSetupHeaders()
  
      let profileRequest;
      
      try {
        profileRequest = await axios.put(
          `${REACT_APP_SERVER_URL}/v1/organization/transfer`,
          {
            group: group.uuid,
            user: this.state.listingSelect
          //   ...formValues
          },
          {
              // headers: {'access-token': 'test2'},
            // withCredentials: true
          }
        );
      } catch ({ response }) {
        profileRequest = response;
      }
      const { data: profileRequestData } = profileRequest;

      this.getProfileData()
      
    }
  }
  
  handleListing = event =>{
    let listing_id = event.target.value
    this.setState({listingSelect: listing_id})
    // this.getListing(listing_id)
  }
  render() {
    const { classes, name, email } = this.props;
    const { errors } = this.state;
    console.log(this.state.groups)
    
              
    let users_select = []
    if(this.state.admin.length > 0){
          // console.log(this.state.families)
          let loc_index = 0
            users_select = 
            this.state.admin.map((listing)=>{
              if(listing !== null){
              return [
              <MenuItem
      classes={{
        root: classes.selectMenuItem,
        selected: classes.selectMenuItemSelected
      }}
      value={listing.email}
    >
      {listing.user + " - " + listing.email}
    </MenuItem>]
              }})

              users_select.push(<MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={this.state.profile.email}
              >
                {"You - " +this.state.profile.email}
              </MenuItem>) 
            }


    let add_listings = []
    if(this.state.listings.length > 0){
      // let loc_index = 0
        add_listings = 
        this.state.listings.map((listing)=>{
              
              // loc_index += 1
            return [ 
              listing.description,listing.type, listing.city, listing.province, 
              <Checkbox
        checked={this.state.accessChecked[listing.index]}
        onChange={()=>this.handleChange(listing.index, false)}
        value="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
            ,
            <Checkbox
      checked={this.state.transferChecked[listing.index]}
      onChange={()=>this.handleChange(listing.index, true)}
      value="primary"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
          ,
            <span>
                                               <Tooltip
                                                       id="tooltip-top"
                                                       title="View on Earth911"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       <a target="_blank"
                                                               href={listing.earth_link} className={classes.tdNameAnchor}>
                                                       <IconButton aria-label="Edit" className={classes.tableActionButton}>
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                       </IconButton>
                                                       
                                                       </a>
                                                     </Tooltip>
                                                             </span>
            ]})
          }
          let users = []    
          if(this.state.admin.length > 0){
              users = 
              this.state.admin.map((group)=>{
                // let added_material_residential = "residential_"+location.material_id
                // let added_material_business = "business_"+location.material_id
                // let suggestion = 'Special Notes - for example "'+location.description+' is only accepted if.."'
                    // loc_index += 1
                  return [ 

                    
                <GridContainer>
                  
                <GridItem xs={12} sm={12} md={6}>
                <p>Name: {group.user}</p>
                <p> Email: {group.email} </p>
                    </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <p>Total Locations: {group.locations_count}</p>
                <ol>
                {
                  group.locations.map((l)=>{
                    return(
                      <li>
{l.name}
                        </li>
                    )
                  }

                )
                }
                </ol>
                <p>Total Programs: {group.programs_count} </p>
                
                <ol>
                {
                  group.programs.map((l)=>{
                    return(
                      <li>
{l.name} 
                        </li>
                    )
                  }

                )
                }
                
                </ol>
                    </GridItem>
                    {(group.locations_count > 0 || group.programs_count > 0) &&
                <GridItem xs={12} sm={12} md={6}>
                    <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    //value={this.state.listingSelect}
    onChange={(e)=>this.handleListing(e)}
    fullWidth={true}
    inputProps={{
      
      placeHolder:"Select a User",
      name: "listingSelect",
      id: "simple-select"
    }}
  >
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      User
    </MenuItem>
    {users_select}
    </Select>
            
                    <Button disabled={this.state.listingSelect == "" ? true: false} onClick={()=>{this.handleUpdateGroup(group)}} color="danger">{group.locations_count <= 0 && group.programs_count <= 0 ? "Delete User" : "Transfer All Listings"}</Button>
                </GridItem>
                    }
                    </GridContainer>
      
      
                  ]
                })
                }
          console.log(add_listings)
    let active_users = []    
    if(this.state.groups.length > 0){
        active_users = 
        this.state.groups[0].active.map((group)=>{
          // let added_material_residential = "residential_"+location.material_id
          // let added_material_business = "business_"+location.material_id
          // let suggestion = 'Special Notes - for example "'+location.description+' is only accepted if.."'
              // loc_index += 1
            return [ 
              <p>
              
               Name {group.first_name} {group.last_name}, Email: {group.email} 
              
               </p>,<p><Button color="danger">Remove</Button>
              
              </p>


            ]
          })
          }
          let inactive_users = []    
          if(this.state.groups.length > 0){
              inactive_users = 
              this.state.groups[0].inactive.map((group)=>{
                // let added_material_residential = "residential_"+location.material_id
                // let added_material_business = "business_"+location.material_id
                // let suggestion = 'Special Notes - for example "'+location.description+' is only accepted if.."'
                    // loc_index += 1
                  return [ 
                    <p>
                    
                     Name {group.first_name} {group.last_name}, Email: {group.email} </p>,<p><Button color="success">Invite</Button>
                    
                    </p>
      
      
                  ]
                })
                }

          
    return (
      <div>
        {!this.state.fromTask && this.state.logo !== "" && 
         <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.updateProfile}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Your Organization's Logo </h4>
                  
                </CardHeader>
                <CardBody>
                <GridContainer>
                    {this.state.logo !== "" && 
        !this.state.fromTask &&
                <GridItem xs={12} sm={12} md={6}>
      <img style={{width: "180px"}}src={this.state.logo} alt="logo" className={classes.img} />
                    </GridItem>
                    }
                  </GridContainer>                  
                </CardBody>
                <CardFooter>
                {/* <Button type="submit" color="success">
                Update Logo
                  </Button> */}
                  {/* <Link to={`/update/organization`} activeClassName="active">
                  <Button type="submit" color="warning">
                    Add Logo
                  </Button>
                  </Link> */}
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        }
        
                    
        {!this.state.fromTask &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.addUser}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Add a person</h4>
                  
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="first_name"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                        //   defaultValue: this.state.profile.first_name,
                          // value: this.state.profile.first_name,
                          name: "first_name"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="last_name"
                        /* error={errors.name} */
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                        //   defaultValue: this.state.profile.last_name,
                          // value: this.state.profile.last_name,
                          name: "last_name"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email address"
                        id="added_email"
                        error={errors.email}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                        //   defaultValue: this.state.profile.email,
                          name: "added_email"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
        control={
          <Switch
            checked={this.state.admin}
            onChange={()=>this.toggleAdmin()}
            value="admin"
            color="warning"
          />
        }
        label={this.state.admin ? "Admin - has full control" : "Editor - can only manage Listings that are assigned"}
      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
        control={
          <Switch
            checked={this.state.access_all}
            onChange={()=>this.toggleAccessAll()}
            value="access_all"
            color="warning"
          />
        }
        label={this.state.access_all ? "Access to all listings" : "Selective access to listings"}
      />
                    </GridItem>
                  </GridContainer>
                  {!this.state.access_all &&
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    // "Name", "City", "Status", 
                    "Listing Name", "Listing Type", "City", "State", "Access", "Transfer", "View on Earth911"]}
                  tableData={ add_listings } 
                />
                    </GridItem>
                  </GridContainer>
                  }
                  
                </CardBody>
                <CardFooter>
                  {/* <Link to={`/update/organization`} activeClassName="active"> */}
                  <Button type="submit" color="success"> 
                    {this.state.addUser.add}
                  </Button>
                  {/* </Link> */}
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        }
          {this.state.admin.length > 0 &&
        !this.state.fromTask &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>All Groups</h4>
                </CardHeader>
                <CardBody>
                  
                {this.state.your_groups.length > 0 &&
                <GridContainer>
                  <p>Your Group:</p>
                  <GridItem xs={12} sm={12} md={6}>
                  <p>Total Locations: {this.state.your_groups[0].locations_count}</p>
                  <p>Total Programs: {this.state.your_groups[0].programs_count}</p>
                      </GridItem>
              
                      </GridContainer>
                }
                {this.state.groups.length  == 0 &&
                
                <GridContainer>
                  <p>Your Group:{this.state.groups[0].group.name}</p>
                  <GridItem xs={12} sm={12} md={6}>
                  <p>Total Locations: {this.state.groups[0].group.locations_count}</p>
                  <p>Total Programs: {this.state.groups[0].group.programs_count}</p>
                      </GridItem>
              
                      </GridContainer>
                }
                 {users}
                </CardBody>
                <CardFooter>
                  {/* <Button type="submit" color="primary">
                    Update Profile
                  </Button> */}
                </CardFooter>
              </Card>
          </GridItem>
        </GridContainer>
                    }
                    
                {this.state.groups.length > 0 &&
        !this.state.fromTask &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Groups</h4>
                </CardHeader>
                <CardBody>
                <GridContainer>
                  <p>Organization Group: {this.state.groups[0].group.name}</p>
                  <GridItem xs={12} sm={12} md={6}>
                  <p>Total Locations: {this.state.groups[0].group.locations_count}</p>
                  <p>Total Programs: {this.state.groups[0].group.programs_count}</p>
                      </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <p>Active Users:</p>
                {active_users}
                    </GridItem> <GridItem xs={12} sm={12} md={6}>
                <p>Inactive Users:</p>
                {inactive_users}
                    </GridItem>
                </GridContainer>
                </CardBody>
                <CardFooter>
                  {/* <Button type="submit" color="primary">
                    Update Profile
                  </Button> */}
                </CardFooter>
              </Card>
          </GridItem>
        </GridContainer>
                    }
      { this.state.profile.email != "" &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.updateProfileInfo}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
                  <p className={classes.cardCategoryWhite}>
                    Update the profile
                  </p>
                </CardHeader>
                <CardBody>
                    
                
                <GridContainer>
                  
                
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Organization Name"
                        id="organization"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          disabled: this.state.new_user ? this.state.profile.organization == "" ? false: true:true,
                          defaultValue: this.state.profile.organization,
                          name: "organization"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="first_name"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          defaultValue: this.state.profile.first_name,
                          // value: this.state.profile.first_name,
                          name: "first_name"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="last_name"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          defaultValue: this.state.profile.last_name,
                          // value: this.state.profile.last_name,
                          name: "last_name"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Phone Number"
                        id="phone"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          defaultValue: this.state.profile.phone,
                          name: "phone"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email address"
                        id="email-address"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          disabled: true,
                          defaultValue: this.state.profile.email,
                          name: "username"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="primary">
                    Update Profile
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      }
      </div>
    );
  }
}

EarthUserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(EarthUserProfile);
