import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class VisbilityTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    //   !this.state.tasks.visibilitys[0].completed
      tasks: {visibility: [
        {completed: false},
        {completed: false},
        {completed: false},
      ]},
      profile: {
        first_name: "",
        last_name: "",
        organization: "",
        phone: "",
        email: ""
        // activation_key: "ALREADY_ACTIVATED"
        // affiliations: ""
        // affiliations_other: ""
        // crm_id: ""
        // email: "amandar@ddsd.org"
        // first_name: "Amanda"
        // id: 31
        // last_name: "Roa"
        // organization: "Delta Diablo Sanitation District"
        // phone: "(925) 756-1940"
        // tos_agreed: 5
        // user_id: 45
      }
    };
    this.addUser = this.addUser.bind(this);
    // this.addProfile = this.updateProfile.bind(this);
  }
  
  componentDidMount(){
    // this.getProfileData()
    this.setState({tasks: JSON.parse(localStorage.getItem('tasks'))})

  }
  updateProfile = async(e) => {
    e.preventDefault();
    const { history } = this.props;
    
    if(!apiSetupHeaders()){
      return history.push("/auth/login-page");
  }

    // const fields = ["new_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    
    const data = new FormData() 
    data.append('picture', this.state.logo)
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/org_profile/logo`, data,
        // {
        //   profile: formValues
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    // if(registerRequest.status > 205){
    //     console.log(registerRequestData) // do something here
    // }else{
        apiStoreHeaders(registerRequest.headers)
        
      this.markAsValid(e, 10)
        
    // }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  async addUser(e) {
    e.preventDefault();

    const fields = ["first_name", "last_name", "added_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/profile/add_user`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;

    if (!registerRequestData.success) {
      this.setState({
        errors:
          registerRequestData.messages && registerRequestData.messages.errors
      });
    }
  }
   markAsValid = async (e, task_id) => {
     
    e.preventDefault();
    // e.preventDefault();
    
    // e.preventDefault();
    const { history } = this.props;
    
    if(!apiSetupHeaders()){
      return history.push("/auth/login-page");
  }


    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/task`,
        {
          task: {category: 'visibility', id: task_id, completed: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['visibility'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    return history.push("/update/earth911?completed=true");

    
    // const { data: taskRequestData } = taskRequest;

    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  getProfileData = async e => {
    apiSetupHeaders()
    // e.preventDefault();

    // const { history } = this.props;

    // // const fields = ["email", "password"];
    // // const formElements = e.target.elements;

    // // const formValues = fields
    // //   .map(field => ({
    //     // [field]: formElements.namedItem(field).value
    // //   }))
    // //   .reduce((current, next) => ({ ...current, ...next }));

    // if(!apiSetupHeaders()){
    //     return history.push("/auth/login-page");
    // }

    let profileRequest;
    
    try {
      profileRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/org_profile`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    if (profileRequest.status == 200) {
        // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        this.setState({profile: profileRequestData.profile})
    }else{
        // return history.push("/auth/login-page");
    }
    this.setState({
    //   errors: loginRequestData.messages && loginRequestData.messages.errors
    });
  };
  onChangeHandler=event=>{
    // console.log("CHANBGE")
        this.setState({logo: event.target.files[0]})
    
    }
  render() {
    const { classes, name, email } = this.props;
    const { errors } = this.state;
    // console.log(this.state.tasks.visibilitys[0].completed)
    // console.log(this.state.tasks.visibilitys[1].completed)
    // console.log(this.state.tasks.visibilitys[0].completed)
    return (
      <div>
      { !this.state.tasks.visibility[0].completed && 
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.updateProfile}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Upload your Organization's Logo </h4>
                  
                </CardHeader>
                <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Logo"
                        id="organization-name"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                            type: "file",
                            onChange: this.onChangeHandler,
                        //   disabled: true,
                        //   required: true,
                        //   defaultValue: this.state.profile.organization,
                          name: "organization-name"
                        }}
                      />
                    </GridItem>
                  </GridContainer>                  
                </CardBody>
                <CardFooter>
                <Button type="submit" color="success">
                Add Logo
                  </Button>
                  
                <Button type="submit" color="warning" onClick={(e)=>this.markAsValid(e, 10)}>
                    Skip
                  </Button>
                  {/* <Link to={`/update/organization`} activeClassName="active">
                  <Button type="submit" color="warning">
                    Add Logo
                  </Button>
                  </Link> */}
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      }
      { this.state.profile.email != "" && this.state.tasks.visibility[0].completed &&  !this.state.tasks.visibility[1].completed &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.addUser}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Add a person</h4>
                  
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="first_name"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                        //   defaultValue: this.state.profile.first_name,
                          // value: this.state.profile.first_name,
                          name: "first_name"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="last_name"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                        //   defaultValue: this.state.profile.last_name,
                          // value: this.state.profile.last_name,
                          name: "last_name"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email address"
                        id="added_email"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                        //   defaultValue: this.state.profile.email,
                          name: "added_email"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                <Button type="submit" color="warning" onClick={(e)=>this.markAsValid(e, 2)}>
                    Skip
                  </Button>
                  {/* <Link to={`/update/organization`} activeClassName="active"> */}
                  <Button type="submit" color="success"> 
                    Add
                  </Button>
                  {/* </Link> */}
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      }
      { this.state.profile.email != "" && this.state.tasks.visibility[0].completed &&  this.state.tasks.visibility[1].completed &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.updateEmail}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Change your Email</h4>
                  
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="New email address"
                        id="new_email"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                        //   defaultValue: this.state.profile.email,
                          name: "new_email"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                <Button type="submit" color="warning" onClick={(e)=>this.markAsValid(e, 3)}>
                    Skip
                  </Button>
                  {/* <Link to={`/update/organization`} activeClassName="active"> */}
                  <Button type="submit" color="success"> 
                    Update
                  </Button>
                  {/* </Link> */}
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>        
      }
      { this.state.tasks.visibility[2].completed &&
         <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
          <h3> Great, you are all done!</h3>
           <div>Now you can continue on to <Link to='/update/tasks/listing-task' activeClassName="active">Listings Tasks </Link>
            <p>or..
            Go to the 
                <Link to='/update/earth911' activeClassName="active"> Dashbaord</Link>
                </p>
            </div>
          </GridItem>
        </GridContainer>
        }
      </div>
    );
  }
}

VisbilityTask.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(VisbilityTask);
