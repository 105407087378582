import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import MUIPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import UpdateMaterials from 'components/Materials/UpdateMaterials'
import CardMedia from '@material-ui/core/CardMedia';

import {DebounceInput} from 'react-debounce-input';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import DateFnsUtils from '@date-io/date-fns';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';


import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Send from "@material-ui/icons/Send";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Help from "@material-ui/icons/Help";
import Done from "@material-ui/icons/DoneOutline";
import Undo from "@material-ui/icons/Undo";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Delete from "@material-ui/icons/Delete";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"




// import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import AddAlert from "@material-ui/icons/AddAlert";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class ValidateListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewUpdatesDialog: false,
      suggestion_data:{
        url:"",
        phone: "",
        update_url: false,
        updated_phone: false,
        updated_address: false

      },
      alwaysOpen: false,
      updated_hours: false,
      listing_hours_data: [],
      formattedAddress:{},
     
      requestRemoval: '',
      requestRemovalOpen: false,
        childComponent: false,
      // materialsSelection: "finalize", //yes, options ** DEVELPOPMERNT **
      materialsSelection: "yes", //yes, options
      families: [],      
      viewingMaterials: '',
      listings: [],
      listing: {description:'', earth_link: '', suggestions:[], audits: [], status: ''},
      accepts: [],
      changes: {
        new_location_address:"",
        new_location_city:"",
        new_location_state:"",
        new_location_postal_code: "",

        new_contact_url:"",
        new_contact_hours:"",
        new_contact_phone:"",
        
        new_description:"",
        
        new_location_notes:"",
      },
      family: [],
      suggestedPlace: [],
      formattedAddress:{},
      last_updated: {listing: {}, updated_url: ''},
      updateState: {current: "viewing_all", listing: {
        current: {},
        all: [],
        update_all: true,
        updated_all: false,

      }},
      errors: {},
      locations: [],
      edit_locations: [],
      location_updates: [],
      location_update_action: [],
      current_task: {completed: true},
      all_listings_completed: false,
      adding_location: false,
      listingSelect: "",
      locationSelect: "",
      programSelect: "",
      listing_name: "",
      enterAddressManually: false,
      new_url: '',
      open: false, suggestion: null ,
      selectedDate: new Date('2014-08-18T21:11:54'), 
      nothing_found: false,
      expanded: 'panel1',
      // setSelectedDate,
    //   !this.state.tasks.contacts[0].completed
      tasks: {listings: [
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
      ]},
      openDialog: false,
      completedAddress: false,
      materials: [],
      added_materials: [],
    };
    this.addUser = this.addUser.bind(this);
    this.getListingData = this.getListingData.bind(this);
    this.markAsValid = this.markAsValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.addProfile = this.updateProfile.bind(this);
  }
  backToAllFamilies = () =>{
    this.setState({family: []})
  }
  handleDateChange = date => {
    this.setState({selectedDate: date});
  };
  moveOnToMaterials = (value)=>{
    this.setState({materialsSelection: value})

  }
     
  destroyListing= async () =>{
    
    // const { history } = this.props;
    let listing = {uuid: this.state.listing.uuid, type: this.state.listing.type}
    
    let taskRequest;
    try {
      taskRequest = await axios.delete(
        
        `${REACT_APP_SERVER_URL}/v1/${listing.type}/${listing.uuid}`,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        {
          // listing: payload
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;     

    }
    
    const { data: taskRequestData } = taskRequest;
    // this.markTaskAsValid()
    this.props.back()
    // if(this.props.task){

    // }
    // console.log(listing)
    // this.setState({
    //   childComponent: true,
    //   validatingListing:
    // <ValidateListing
    //   listing={listing}
    //   back={this.props.task ? this.props.back : this.markTaskAsValid}
    //  />})
    
    // return history.push('/update/listings')

  }
  handleCloseRequestRemoval = (event) => {
    this.setState({requestRemovalOpen:false})
    // console.log(event.target)

  }
  handleSubmitRequestRemoval = async (event) => {
    this.setState({requestRemovalOpen:false})
    let reason = document.getElementById('removal-reason').value

    console.log(reason)
    apiSetupHeaders()
    
    let profileRequest;
    
    try {
      profileRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/`+this.props.listing.type+`/request_removal`,
        {
          uuid: this.props.listing.uuid,
          reason: reason
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    // if (profileRequest.status == 200) {
        // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        this.setState({ successAlert: true, alertMessage: "Your request for removal is being proccessed. Thank you, redirecting to listings page now." })
        setTimeout(()=>{this.setState({ successAlert: false}) 

        
        this.props.listing.updated = true
        this.props.back({listing:this.props.listing, completed: 'request_removal'})}
        , 3000)
        // this.setState({profile: profileRequestData.profile})
    // }else{
        // return history.push("/auth/login-page");
    // }
    // this.setState({   });

  }
  undoRequestRemoval = async (event) => {
    let listing = this.state.listing
    listing.request.removed = false;
    this.setState({ listing: listing, successAlert: true, alertMessage: "Your request for removal has been canceled." })
    setTimeout(()=>{this.setState({ successAlert: false}) 
    // this.props.back({completed: false})
  }
    , 3000)
    // this.setState({requestRemovalOpen:false})
    // let reason = document.getElementById('removal-reason').value

    // console.log(reason)
    apiSetupHeaders()
    
    let profileRequest;
    
    try {
      profileRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/`+this.props.listing.type+`/undo_request_removal`,
        {
          uuid: this.props.listing.uuid,
          // reason: reason
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    // if (profileRequest.status == 200) {
        // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        // this.setState({profile: profileRequestData.profile})
    // }else{
        // return history.push("/auth/login-page");
    // }
    // this.setState({   });

  }


  requestRemoval = () =>{
    this.setState({requestRemovalOpen: true})
  }
      
 
//  handleClose = () => {
//     onClose(selectedValue);
//   };

//  handleListItemClick = value => {
//     onClose(value);
//   };
addMaterial=(material)=>{
  console.log(material)

  // material.disabled = true
  let removedMaterial = this.state.materials
  removedMaterial.splice(material.index, 1)
  let materials = this.state.added_materials
  let accepts = this.state.accepts
  
  let changes = this.state.changes
  this.state.changes.added.push(material.material_id)


  materials.push(material)
  accepts.push({residential: 'none', business: 'none', notes: '', material_id: material.material_id})
  material.index = materials.length - 1
  this.setState({added_materials: materials, materials: removedMaterial, accepts: accepts, changes: changes})
}

removeMaterial=(material)=>{
  console.log(material)
  // material.disabled = true
  let removedMaterial = this.state.added_materials
  removedMaterial.splice(material.index, 1)
  let removedAccepts = this.state.accepts
  removedAccepts.splice(material.index, 1)
  for(let i=material.index; i<removedMaterial.length; i++)
  removedMaterial[i].index = removedMaterial[i].index - 1
  // let materials = this.state.materials
  // materials.push(material)
  let changes = this.state.changes
  this.state.changes.removed.push(material.material_id)
  this.setState({added_materials: removedMaterial, accepts: removedAccepts, changes: changes})
}
  onClose=() =>{
    this.setState({ open: false })
  }
  enterAddressManually=() =>{
    // if(!this.state.enterAddressManually)
    this.setState({ enterAddressManually: !this.state.enterAddressManually, suggestedPlace: [], formattedAddress: {}, suggestion: null})
  }
  onSuggestionSelected=(suggestion) =>{
    // Add your business logic here. In this case we just log...
    this.setState({ open: true, suggestion })
    geocodeByPlaceID(suggestion.place_id).then((results) => {

      console.log(results)

      let formattedAddress = {}
      results[0].address_components.forEach(address_component =>{
        formattedAddress[address_component.types[0]] = {s: address_component.short_name, l:address_component.long_name}

      })
      this.setState({suggestedPlace: results, formattedAddress: formattedAddress})
      // Add your business logic here. In this case we simply set our state with the coordinates of
      // the selected suggestion...

      // Just use the first result in the list to get the geometry coordinates
      // const { geometry } = results[0]

      // const coordinates = {
      //   lat: geometry.location.lat(),
      //   lng: geometry.location.lng(),
      // }

      // this.setState({ coordinates })
    }).catch((err) => {
      // Handle any errors that occurred when we tried to get geospatial data for a selected
      // suggestion...
    })
    
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleFamily = event =>{
    let family_id = event.target.value
    this.setState({familySelect: family_id, materials:[]})
    this.getFamily(family_id)
  }
  resetSuggestion = ()=>{
    this.resetHours(false)
    this.setState({listingSelect: "",
    suggestion_data:{
      url:"",
      phone: "",

    }})
  }
  handleListing = event =>{
    let listing_id = event.target.value
    this.setState({listingSelect: listing_id})
    this.getSuggestion(listing_id)
  }
  resetLocation = (location) =>{
    
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'action_required'

    this.setState({location_update_action: location_update_action})
  }
  checkAllLocationsCompleted = ()=>{
    let completed = true;
    console.log(this.state.locations.length)
    for(let i = 0; i<this.state.locations.length; i++){
      console.log(this.state.locations[i].updated)
    if(!this.state.locations[i].updated)
      { console.log(this.state.locations[i])
        completed = false
      break;}
    }
    if(completed)
    this.setState({all_listings_completed: true})

  }
  backToListings = (updated_all) => {
    let updateState =  {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }}
    if(updated_all){
      this.state.locations[this.state.updateState.listing.current.index].updated = true
      this.checkAllLocationsCompleted()

      
    let location_update_action = this.state.location_update_action
    if(this.state.new_url !== "mixed")
    if(this.state.updateState.listing.all.length > 1)
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all to: ' + this.state.new_url
    else
    location_update_action[this.state.updateState.listing.current.index] = 'Updated to: ' + this.state.new_url
    else{
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all URLs individually'

    }

    this.setState({updateState: updateState, last_updated: {listing: this.state.updateState.listing.current, updated_url: this.state.new_url}, new_url: '', location_update_action: location_update_action})
  }

    else{
    this.setState({updateState: updateState, new_url: ''})}

  }


  async getListingData(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/`+this.props.listing.type+`/`+this.props.listing.uuid+`/all_data`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    taskRequestData.listing.type = this.props.listing.type
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.materials.length; i++){
    //   // if(taskRequestData.locations[i].updated)
    //   // location_update_action[i] = 'This listing(s) has been completed.'      
    //   // else
    //   // location_update_action[i] = 'action_required'
    //   taskRequestData.materials[i].index = i
    // }
    // this.setState({added_materials: taskRequestData.materials, accepts: taskRequestData.accepts})
    // console.log(taskRequestData.listing.contact.hours_payload)
    let hours_payload = taskRequestData.listing.contact.hours_payload
    if(hours_payload.length > 0){
      // console.log(taskRequestData.contact.hours_payload)
     let listing_hours_data = hours_payload
      
    this.setState({listing_hours_data: listing_hours_data})
    }
    this.setState({listing: taskRequestData.listing})
    
   
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getListings(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listings`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({listings: taskRequestData.listings, materials: []})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getListing(listing_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listing/`+listing_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({materials: taskRequestData.listing})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getSuggestion(listing_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/listings/suggestion/`+listing_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({suggestion: taskRequestData.suggestion})
    let place = taskRequestData.suggestion
    let listing_hours_data = this.state.listing_hours_data
    let updated_hours = false;
    if(place.opening_hours !== undefined){
      updated_hours = true;
    // hours = place.opening_hours.weekday_text.join(', ')
     let periods = place.opening_hours.periods
     for(let i=0; i< periods.length; i++){
       let current_period = periods[i]
      //  console.log(current_period)
       listing_hours_data[current_period.open.day].index = current_period.open.day
       listing_hours_data[current_period.open.day].open = true
       listing_hours_data[current_period.open.day].start_hour = current_period.open.time.substring(0,2)//current_period.open.hours < 10 ? "0" + current_period.open.hours : current_period.open.hours
       listing_hours_data[current_period.open.day].start_minute = current_period.open.time.substring(2)//current_period.open.minutes < 10 ? "0" + current_period.open.minutes : current_period.open.minutes
       listing_hours_data[current_period.close.day].close_hour = current_period.close.time.substring(0,2)//current_period.close.hours < 10 ? "0" + current_period.close.hours : current_period.close.hours
       listing_hours_data[current_period.close.day].close_minute = current_period.close.time.substring(2)//current_period.close.minutes < 10 ? "0" + current_period.close.minutes : current_period.close.minutes
      //  console.log(listing_hours_data[current_period.open.day])
      //  console.log(listing_hours_data[current_period.close.day])
     }
    }
    console.log(listing_hours_data)
    let formattedAddress = {}
    place.address_components.forEach(address_component =>{
      formattedAddress[address_component.types[0]] = {s: address_component.short_name, l:address_component.long_name}

    })
    // this.setState({suggestedPlace: results,})
    let changes = this.state.changes
    changes['new_contact_hours'] = listing_hours_data
    this.setState({suggestion_data: {phone: place.formatted_phone_number, url: place.website}, listing_hours_data: listing_hours_data,  formattedAddress: formattedAddress, changes: changes, updated_hours: updated_hours})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getFamilies(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/families/`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({families: taskRequestData.families, materials: []})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getFamily(family_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/family/`+family_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({materials: taskRequestData.family})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  saveUpdates = async ()=>{
    
    // const fields = ["new_url"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));
    let changes =  this.state.changes
    changes.updated_hours = this.state.updated_hours
    changes.always_open = this.state.alwaysOpen
    

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/`+this.state.listing.type+`/`,
        {
            uuid:this.state.listing.uuid,
            updates:  changes
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    // console.log(taskRequest.data)
    // this.backToListings(true)

    // let updateState = this.state.updateState
    // location = updateState.listing
    // location.updated_all = true
    // // location.url = formValues.new_url
    
    // this.setState({updateState: updateState})

    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    // this.props.back()
    this.viewMaterials(this.props.listing)
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  updateAllIndividually = (value)=>{
    let updateState = this.state.updateState
    updateState.listing.update_all = value;
    this.setState({updateState: updateState})
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  resetHours = (open) => {
    this.setState({
      new_contact_hours: "",
    listing_hours_data: 
    [
      {
        index: 0,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Sunday"
      },
      {
        index: 1,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Monday"
      },
      {
        index: 2,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Tuesday"
      },
      {
        index: 3,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Wednesday"
      },
      {
        index: 4,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Thursday"
      },
      {
        index: 5,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Friday"
      },
      {
        index: 6,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Saturday"
      }
  ]})

  }
  
  isOpen = (hours)=>{
    console.log(hours)
    let lhd = this.state.listing_hours_data
    hours.open = !hours.open
    lhd[hours.index] = hours
    let changes = this.state.changes
    changes['new_contact_hours'] = lhd
    this.setState({listing_hours_data: lhd, changes: changes})
    console.log(lhd)

  }
  
  updateTime = (event) =>{
    // console.log(event.target)
    // console.log(event.target.name)
    // console.log(event.target.value)

    let name = event.target.name
    let day_index = name.split('_')[0]
    let day_type = name.split('_')[1] //start or end
  
    
    let value = event.target.value
    let hour = value.split(':')[0]
    let minute = value.split(':')[1]

    let lhd = this.state.listing_hours_data

    if(day_type == "open")
    {          
      lhd[day_index].start_hour = hour
      lhd[day_index].start_minute = minute
    }
    else
    {
      lhd[day_index].close_hour = hour
      lhd[day_index].close_minute = minute
    }
    let changes = this.state.changes
    changes['new_contact_hours'] = lhd
    this.setState({listing_hours_data: lhd, changes: changes, updated_hours: true})


  }
  componentDidMount(){
    
    let listings_count = JSON.parse(localStorage.getItem('listings_count'));
  this.setState({listings_count: listings_count})    
    this.resetHours()
    document.getElementById('topAppBar').scrollIntoView();
    // this.getCurrentMaterials()
    
    this.getListingData()
    // this.getProfileData()
    // let tasks = JSON.parse(localStorage.getItem('tasks'))
    // this.setState({tasks: tasks})
    
    // let listing_task = tasks['listings']
    // console.log(listing_task)

    // for(let i=0; i<listing_task.length; i++){
    //  if(!listing_task[i].completed){
    //     this.setState({current_task: listing_task[i]})
    //     // console.log("set current to", listing_task[i])
    //     // console.log(this.state)
    //     if(listing_task[i].task.id == 9){
    //       this.setState({adding_location: true})
    //     }else{
    //     this.getCurrentTaskData(listing_task[i]);
    //     }
    //     break;
    //  }
    // }

  }
  componentDidUpdate(prevProps, prevState, snapshot){
    // if(this.props.last_updated !== prevProps.last_updated && this.props.last_updated.listing.index !== null){
      
    // let location_update_action = this.state.location_update_action
    // location_update_action[this.props.last_updated.listing.index] = 'Updated all to: ' + this.props.last_updated.new_url
    // this.setState({location_update_action: location_update_action})
    // }

  }
  handleClose = () => {

    this.setState({openDialog: !this.state.openDialog})

    // setOpen(false);
    // setSelectedValue(value);
  };
  handlePanelChange = (panel) => {
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    this.setState({expanded: panel});
  }
  
  handleChange = (event) => {
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    let changes = this.state.changes
    changes[event.target.name] = event.target.value
    this.setState({changes: changes});
  }

  searchMaterials = async (event) => {
    this.setState({materials: []})
    let taskRequest;
    try {
      taskRequest = await axios.get(
        
        `${REACT_APP_SERVER_URL}/v1/materials?q=`+event.target.value,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        // {
          // url: location.url
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    console.log(taskRequestData)
    let nothing_found = false
    if(taskRequestData.materials.length == 0)
    nothing_found = true
    this.setState({materials: taskRequestData.materials, nothing_found: nothing_found})
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    // this.setState({[event.target.name]: 
    
      
      // https://search.earth911.com/suggest/materials/?q=%231
  }
  updateEmail = async(e) => {
    e.preventDefault();
    const { history } = this.props;

    const fields = ["new_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/profile/change_email`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    if(registerRequest.status > 205){
        console.log(registerRequestData) // do something here
    }else{
        
        apiStoreHeaders(registerRequest.headers)
        return history.push("/update/earth911");
        
    }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  async addUser(e) {
    e.preventDefault();

    const fields = ["first_name", "last_name", "added_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/profile/add_user`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;

    if (!registerRequestData.success) {
      this.setState({
        errors:
          registerRequestData.messages && registerRequestData.messages.errors
      });
    }
  }
   markTaskAsValid = async () => {
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/task`,
        {
          task: {category: 'listing', id: this.state.current_task.task.id, completed: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

    // console.log(taskRequest)
    
    // const { data: taskRequestData } = taskRequest;
    this.state.tasks.listings[this.state.current_task.task.id-4].completed = true
    
    let all_tasks_completed = true
    for(let i=this.state.current_task.task.id-4; i<this.state.tasks.listings.length; i++){
      if(!this.state.tasks.listings[i].completed){
        this.resetAllData()
        all_tasks_completed = false
        this.setState({current_task: this.state.tasks.listings[i]})
        this.getCurrentTaskData(this.state.tasks.listings[i])   
        break;
      }
    }
    
    const { history } = this.props;
    if(all_tasks_completed)    
    history.push("/update/earth911");
    // console.log(taskRequestData)


    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  resetAllData = () => {
    this.setState({
    last_updated: {listing: {}, updated_url: ''},
    updateState: {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }},
    locations: [],
    edit_locations: [],
    location_updates: [],
    location_update_action: [],
    all_listings_completed: false,    
    new_url: ''})
  }
  useSuggested = (what) =>{
    let suggestions = this.state.suggestion_data
    let listing = this.state.listing
    let changes = this.state.changes
    if(what == "phone"){
      let phone = suggestions.phone
      changes['new_contact_phone'] =  phone
      listing.contact.phone = suggestions.phone
      suggestions.phone = ""
      suggestions.updated_phone = true
      this.setState({suggestion_data: suggestions, listing: listing, changes: changes})      
    }else{
      if(what == "url"){
        let url = suggestions.url
        changes['new_contact_url'] =  url
        listing.contact.url = suggestions.url
        suggestions.url = ""
        suggestions.updated_url = true
        this.setState({suggestion_data: suggestions, listing: listing, changes: changes})    

      }
      else{
        // let url = suggestions.url
        changes['address_payload'] =  this.state.formattedAddress
        // listing.contact.url = suggestions.url
        // suggestions.url = ""
        suggestions.updated_address = true
        this.setState({suggestion_data: suggestions, changes: changes})    

        // formattedAddress
        //what == address

      }
    }
  }
  
  removeSuggested = (what) =>{
    let suggestions = this.state.suggestion_data
    // let listing = this.state.listing
    // let changes = this.state.changes
    if(what == "phone"){
      // let phone = suggestions.phone
      // changes['new_contact_phone'] =  phone
      // listing.contact.phone = suggestions.phone
      suggestions.phone = ""
      // suggestions.updated_phone = true
      this.setState({suggestion_data: suggestions})      
    }else{
      if(what == "url"){
        // let url = suggestions.url
        // changes['new_contact_url'] =  url
        // listing.contact.url = suggestions.url
        suggestions.url = ""
        // suggestions.updated_url = true
        this.setState({suggestion_data: suggestions})    

      }
      else{
        
        this.setState({formattedAddress:{}})
        //what == address

      }
    }
  }
  updateLocation = async (e, location) => {
    
    const fields = ["new_url"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));
    e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.uuid,
        {
          location:  formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)

    let updateState = this.state.updateState
    location = updateState.listing.all[location.index]
    location.updated = true
    location.url = formValues.new_url
    
    this.setState({updateState: updateState})

    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    let completed = true
    for(let i=0; i<updateState.listing.all.length; i++)
    {
      if(updateState.listing.all[i].updated == false)
        completed = false
    }
    if(completed){
      if(updateState.listing.all.length == 1)
      this.setState(formValues)
      else
      this.setState({new_url: "mixed"})
      
      this.backToListings(true)

    }
    // const { data: taskRequestData } = taskRequest;

    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  markAsValid = async (location) => {
    // console.log("marked as valid", location, index)
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'Marked as Valid (no further updates to listings will be made)'
    let locations = this.state.locations
    locations[location.index].updated = true
    this.setState({location_update_action: location_update_action, locations: locations})
    
    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.uuid,
        {
          location:  {markAsValid: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)
    apiStoreHeaders(taskRequest.headers)
    this.checkAllLocationsCompleted()

  }
  updateURL = async (location) => {
    // console.log("marked as valid", location, index)
    // let location_update_action = this.state.location_update_action
    // location_update_action[location.index] = 'Update'
    // this.setState({location_update_action: location_update_action})
    let updateState = this.state.updateState
    updateState.current = 'editing'
    updateState.listing.current = location
    console.log(location)
    if(location.effects_count == "1")
    {
      
      updateState.listing.update_all = false
      console.log(updateState)
      
      // last_updated: {listing: {}, updated_url: ''},
      // updateState: {current: "viewing_all", listing: {
      //   current: {},
      //   all: [],
      //   update_all: true,
      //   updated_all: false,

      // }},


    }
    

    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.uuid,
        // {
          // url: location.url
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    console.log(taskRequestData)
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

    // const { data: taskRequestData } = taskRequest;
    // let location_update_action = []
    for(let i = 0; i<taskRequestData.locations.length; i++){
      // location_update_action[i] = 'action_required'
      taskRequestData.locations[i].index = i
      taskRequestData.locations[i].updated = false
    }

    updateState.listing.all = taskRequestData.locations
    this.setState({updateState: updateState})


  }
  
  backFromUpdatingMaterials = (payload) =>{
    // this.setState({childComponent: false, viewingMaterials: ""})
    this.props.back(payload)
  }
  viewMaterials=(location) =>{
    this.setState({
      childComponent: true,
      viewingMaterials:
    <UpdateMaterials
    task={true}
      listing={location}
      back={this.backFromUpdatingMaterials}
     />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
  
  setToPreviousDay = (hours) =>{
    let current_hours = this.state.listing_hours_data;
    let current_index = hours.index
    let prev_index = 6
    if(current_index !== 0)
    prev_index = current_index - 1


    current_hours[hours.index].start_hour = current_hours[prev_index].start_hour 
    current_hours[hours.index].close_hour = current_hours[prev_index].close_hour 
    current_hours[hours.index].start_minute = current_hours[prev_index].start_minute 
    current_hours[hours.index].close_minute = current_hours[prev_index].close_minute 
    current_hours[hours.index].open = false
    this.setState({listing_hours_data: current_hours})
    setTimeout(()=>{
    let current_hours = this.state.listing_hours_data;
    current_hours[hours.index].open = true
    this.setState({listing_hours_data: current_hours})
    },1000)


  }
  getProfileData = async e => {
    apiSetupHeaders()
    // e.preventDefault();

    // const { history } = this.props;

    // // const fields = ["email", "password"];
    // // const formElements = e.target.elements;

    // // const formValues = fields
    // //   .map(field => ({
    //     // [field]: formElements.namedItem(field).value
    // //   }))
    // //   .reduce((current, next) => ({ ...current, ...next }));

    // if(!apiSetupHeaders()){
    //     return history.push("/auth/login-page");
    // }

    let profileRequest;
    
    try {
      profileRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/profile`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    if (profileRequest.status == 200) {
        // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        this.setState({profile: profileRequestData.profile})
    }else{
        // return history.push("/auth/login-page");
    }
    this.setState({
    //   errors: loginRequestData.messages && loginRequestData.messages.errors
    });
  };
  render() {
    const { classes, name, email } = this.props;
    const { errors, locationSelect, programSelect, listingSelect,  open, suggestion, formattedAddress, selectedDate, listing } = this.state
   let listing_update_message = ""
   if(listing.contact != undefined)
   if(listing.contact.update_status == 1){
    listing_update_message = "This URL hasn't changed since "+ listing.updated.split('-')[0] +"." 
    // console.log(listing_update_message, listing)

   }
    else  {
      if(listing.contact.update_status == 2 || listing.contact.update_status == 4)
      listing_update_message =  <p> <span style={{color:"red"}}>PLEASE UPDATE</span><p>The URL: <a href={listing.contact.url} target="_blank">{listing.contact.url}</a> has been removed since it is no longer works. </p></p>
    else
    listing_update_message =  <span>Updated from the original URL: <a href={listing.contact.url} target="_blank">{listing.contact.url}</a></span>
    // console.log(listing_update_message, listing)

    }
    // console.log(listing_update_message, listing)
    let hours = ''
    if(this.state.listing_hours_data.length > 0){
    hours = this.state.listing_hours_data.map((hours)=>{
        // console.log(hours)
        let day_start = hours.start_hour + ":" +hours.start_minute
        let day_end = hours.close_hour + ":" +hours.close_minute

        let has_previous_day_ability = false
        let current_index = hours.index
        let prev_index = 6
        if(current_index !== 0)
        prev_index = current_index - 1
        let previous_day = this.state.listing_hours_data[prev_index]
        if(previous_day.start_hour !== "" || previous_day.close_hour !== ""){
          has_previous_day_ability = true
        }
      return [   
        <GridContainer>  
          <GridItem xs={12} sm={12} md={3}> 
    <FormControlLabel
        control={
          <Switch
            checked={hours.open}
            onChange={()=>this.isOpen(hours)}
            value={hours.open} 
            color="warning"
          />
        }
        label={hours.open ? "Open" : "Closed "+hours.day}
      />
    </GridItem>   
     
    <GridItem xs={12} sm={12} md={3}> 
          <Button
          simple = {true}
          disabled ={!has_previous_day_ability}
            onClick={()=>this.setToPreviousDay(hours)}
            color={has_previous_day_ability ? "success": "warning"}
          >
          Same as {previous_day.day}
          </Button>
      </GridItem> 
        {hours.open &&
        <GridItem xs={12} sm={12} md={3}> 
      <TextField
      id="time"
      label={hours.day +" Opening"}
      type="time"
      fullWidth={true}
      defaultValue={day_start}
      onChange={(e)=>this.updateTime(e)}  
      /* value={monday_start} */
      className={classes.textField}
      InputLabelProps={{
        shrink: true,        
      }}
      inputProps={{
        step: 300, // 5 min
        name: hours.index+"_open",
        // defaultValue: day_start,
        // onChange: (e)=>this.updateTime(e)
      }}
    />
    </GridItem>
        }
        {hours.open &&
    <GridItem xs={12} sm={12} md={3}> 
      <TextField
      id="time"
      fullWidth={true}
      label={hours.day +" Closing"}
      type="time"
      defaultValue={day_end}
      onChange={(e)=>this.updateTime(e)}  
      /* value={monday_start} */
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
        name: hours.index+"_close",
      }}
    />
    </GridItem>
      }
    
    </GridContainer>
    ]
    })
    // console.log(hours)
    }
    let listings_select = []
    if(this.state.listing.suggestions.length > 0){
          // console.log(this.state.families)
          let loc_index = 0
            listings_select = 
            this.state.listing.suggestions.map((listing)=>{
              if(listing !== null){
              return [
              <MenuItem
      classes={{
        root: classes.selectMenuItem,
        selected: classes.selectMenuItemSelected
      }}
      value={listing.uuid}
    >
      {listing.name}
    </MenuItem>]
              }})

            }
    return (
      <div>
        
        <Snackbar
                    place="tr"
                    color="success"
                    icon={AddAlert}
                    message={this.state.alertMessage}
                    open={this.state.successAlert}
                    closeNotification={() => this.setState({ successAlert: false })}
                    close
                  />
                  <Dialog onClose={()=>this.setState({viewUpdatesDialog: false})} aria-labelledby="simple-dialog-title" open={this.state.viewUpdatesDialog}>
      <DialogTitle id="simple-dialog-title">Updates: {listing.description}</DialogTitle>
      
      <List>
        {this.state.listing.audits.map( listing_change=> {          

          let when = listing_change.date          
          let from = listing_change.user
          let what = []
          for(let change in listing_change.changes){
  //          console.log(change)
    //        console.log(listing_change.changes[change])
            what.push(
          <ListItem key={listing_change.date}> Changed: <span style={{fontWeight: "bold"}}>{change}</span></ListItem>)
          what.push(
          <ListItem key={listing_change.date}> <span style={{fontWeight: "bold"}}>from</span>:{listing_change.changes[change][0]}</ListItem>)
          what.push(
          <ListItem key={listing_change.date}> <span style={{fontWeight: "bold"}}>to</span>:<span style={{fontStyle: "italic"}}>{listing_change.changes[change][1]}</span></ListItem>)
          }
//          console.log(listing_change.changes)
          // let what = listing_change.changes.map( change => {
          //   let change.key
          //   return(
          //   <div>
              
          //   </div>
          // )})
          return(
            <div>
          <ListItem key={listing_change.date}>
            {/* <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar> */}
            Date: <ListItemText primary={when} />
            
          </ListItem>
          <ListItem key={listing_change.date}>

            From: <ListItemText primary={from} />
            
          </ListItem>
            {what}
          </div>
        )
      }
      )
      }
      </List>
    </Dialog>
                  
        <Dialog open={this.state.requestRemovalOpen} onClose={()=>this.handleCloseRequestRemoval} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Request Removal</DialogTitle>
    <DialogContent>
      <DialogContentText>
        (Optional) Feel free to provide us a with the reason for removal here.
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="removal-reason"
        label="Reason"
        type="text"
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={()=>this.handleCloseRequestRemoval()} color="warning">
        Cancel
      </Button>
      <Button onClick={()=>this.handleSubmitRequestRemoval()} color="danger">
        Submit
      </Button>
    </DialogActions>
  </Dialog>
      {this.state.viewingMaterials}
        
{listing.description !== "" && !this.state.childComponent &&

      <div>
        {listing.request.removed &&
                      <p style={{fontWeight: 'bold'}}>This listing has been requested to be removed
                      <Tooltip
                                                       id="tooltip-top"
                                                       title="Undo the removal"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                       
                                                            
<Button color="danger" simple={true} onClick={()=>{this.undoRequestRemoval()}}>

<Undo className={classes.tableActionButtonIcon + " " + classes.edit}/> {'  '}Made a mistake, rescind the request.
    </Button>
                                                     
                                                       
                                                     </Tooltip>
                                                     </p>
                      }
        
        <p style={{fontStyle: 'italic'}}>
        
        {listing.audits.length > 0 &&
  
  <Button color="warning" onClick={()=>{this.setState({viewUpdatesDialog: true})}}>
  View Changes
  </Button>
  
}
          <Tooltip
                                                       id="tooltip-top"
                                                       title={this.state.listing.status == "Published" ? "View on Earth911": "Pending Authorization to be Published"}
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                               
<Button target="_blank"
                                                               href={this.state.listing.status == "Published" ? listing.earth_link : "#"}
                                                                color="primary" disabled={this.state.listing.status == "Published" ? false: true}>
{this.state.listing.status == "Published" ? "View On Earth911" :"Pending Authorization"}
{this.state.listing.status == "Published" &&
                                                         <OpenInNew className={classes.tableActionButtonIcon + " " + classes.edit}/>
}
    </Button>
                                                     
                                                       
                                                     </Tooltip>
                                                     {listing.status == "New" &&
                                                     
          <Tooltip
                                                       id="tooltip-top"
                                                       title={"Remove this listing"}
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
   <Button color="danger" onClick={(e)=>this.destroyListing()}>
   Delete Listing
 </Button>
 
 </Tooltip>
                                                     }

                                                     <p>
                                                     This {this.props.listing.type} listing was created in {listing.created.split('-')[0]}.
                                                       </p>

                                                       </p>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={listingSelect == "2" ?  "Program Name": "Location Name"}
                        id="listing-name"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          defaultValue: listing.description,
                          name: "new_description"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                <GridContainer>
                      <div style={{padding: "20px"}}><h3>
          <Typography className={classes.heading}>Contact Information</Typography>
          </h3>
          </div>
          {this.state.listing.suggestions.length > 0
          // &&
      //     <GridItem xs={12} sm={12} md={12}>
      //               <FormControlLabel
      //               labelPlacement="start"
      //   control={
      //      <Button simple={true}  color="warning" onClick={(e)=>this.setState({currentlySelecting: "listings"})}>
      //               Select Suggestion
      //             </Button>
      //   }
      //   label="View suggestions from Google:  "
      // />
                    
      //               </GridItem>
          }
                    {listings_select.length > 0 &&
                    
                    <GridItem xs={12} sm={12} md={12}>
                     <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select a Suggestion from Google
                          </InputLabel>
                    <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    value={this.state.listingSelect}
    onChange={this.handleListing}
    fullWidth={true}
    inputProps={{
      name: "listingSelect",
      id: "simple-select"
    }}
  >
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      Suggestion
    </MenuItem>
    {listings_select}
    </Select>
    
    </FormControl>
                
    </GridItem>
                    }    
                     {this.state.listingSelect !== ""  &&
                      <GridItem xs={12} sm={12} md={4}>
                      Remove Suggestion
                                                     <IconButton style={{color:"red"}} aria-label="Edit" className={classes.tableActionButton} onClick={() => {this.resetSuggestion()}}>
                                                       <Delete  fontSize="large" className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton>
                      </GridItem>
                      }
                      
                {/* </GridContainer> */}
                      
                {this.state.suggestion_data.phone !== ""  &&
                      <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Suggested Phone *"
                        id="listing-phone"
                        fullWidth={true}
                        error={errors.username}
                        formControlProps={{
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          defaultValue: this.state.suggestion_data.phone,
                          name: "new_contact_phone"
                        }}
                      />
                      </GridItem>
                      }
                      {this.state.suggestion_data.phone !== ""  &&
                      <GridItem xs={12} sm={12} md={2}>
                      Use Phone
                                                     <IconButton style={{color:"green"}} aria-label="Edit" className={classes.tableActionButton} onClick={() => {this.useSuggested("phone")}}>
                                                       <Done  fontSize="large" className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton>
                      </GridItem>
                      }
                      {this.state.suggestion_data.phone !== ""  &&
                      <GridItem xs={12} sm={12} md={2}>
                      Reject Phone
                                                     <IconButton style={{color:"red"}} aria-label="Edit" className={classes.tableActionButton} onClick={() => {this.removeSuggested("phone")}}>
                                                       <Delete  fontSize="large" className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton>
                      </GridItem>
                      }
                      {!this.state.suggestion_data.updated_phone &&
            <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Current Phone *"
                        id="listing-phone"
                        fullWidth={true}
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          defaultValue: listing.contact.phone,
                          name: "new_contact_phone"
                        }}
                      />
                      </GridItem>
                      }
                      {this.state.suggestion_data.updated_phone &&
            <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Updated Phone *"
                        id="listing-phone"
                        fullWidth={true}
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          defaultValue: listing.contact.phone,
                          name: "new_contact_phone"
                        }}
                      />
                      </GridItem>
                      }
        <GridItem xs={12} sm={12} md={12}>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={selectedDate}
          onChange={this.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider> */}
                      <CustomInput
                      labelText="Hours"
                        id="listing-hours"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          disabled: true,
                          required: true,
                          defaultValue: listing.contact.hours,
                          name: "new_contact_listing_hours"
                        }}
                      />
                      <span style={{color:"red"}}>{listing.contact.hours_payload.length > 0 ?" ": "Please update"} <FormControlLabel
        control={
          <Switch
            checked={this.state.alwaysOpen}
            onChange={()=>this.setState({alwaysOpen: !this.state.alwaysOpen})}
            value={hours.open} 
            color="warning"
          />
        }
        label={this.state.alwaysOpen ? "Always Open" : "Not Open 24/7"}
      /></span>
                      
      {!this.state.alwaysOpen &&
        hours}
                      </GridItem>
                      {this.state.suggestion_data.url !== ""  &&
                      <GridItem xs={12} sm={12} md={8}>
                      <CustomInput
                        labelText="Suggested URL"
                        id="listing-URL"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          defaultValue:  this.state.suggestion_data.url,
                          name: "new_contact_url"
                        }}
                      />
                      <p>
                        
                      <a href={this.state.suggestion_data.url} target="_blank"><Button color="warning">Check it out</Button></a>
                    </p>
                      </GridItem>
                      }
                      {this.state.suggestion_data.url !== ""  &&
                      <GridItem xs={12} sm={12} md={2}>
                      Use URL
                                                     <IconButton style={{color:"green"}} aria-label="Edit" className={classes.tableActionButton} onClick={() => {this.useSuggested("url")}}>
                                                       <Done  fontSize="large" className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton>
                      </GridItem>
                      }
                      {this.state.suggestion_data.url !== ""  &&
                      <GridItem xs={12} sm={12} md={2}>
                      Reject URL
                                                     <IconButton style={{color:"red"}} aria-label="Edit" className={classes.tableActionButton} onClick={() => {this.removeSuggested("url")}}>
                                                       <Delete  fontSize="large" className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton>
                      </GridItem>
                      }
                     
        <GridItem xs={12} sm={12} md={12}>
                      <p style={{fontWeight:"bold"}}>
                          {listing_update_message}
                      </p>
                      {!this.state.suggestion_data.updated_url &&
                      <CustomInput
                        labelText="URL"
                        id="listing-URL"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          defaultValue:  listing.contact.update_status == 1 ? listing.contact.url: listing.contact.updated,
                          name: "new_contact_url"
                        }}
                      />
                      }
                      {this.state.suggestion_data.updated_url &&
                      <CustomInput
                        labelText="Updated URL"
                        id="listing-URL"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          defaultValue:  this.state.changes.new_contact_url,
                          name: "new_contact_url"
                        }}
                      />
                      }
                      {listing.contact.update_status !== 2 && listing.contact.update_status !== 4 &&
                      <p>
                        
                      <a href={listing.contact.update_status == 1 ? listing.contact.url: listing.contact.updated} target="_blank"><Button color="warning">Check it out</Button></a>
                    </p>
                      }
                      </GridItem>
                     
                      {!listing.request.removed &&
                                listing.status !== "New"                       &&
                      <p style={{fontStyle: 'italic'}}>No longer in business?   
                      <Tooltip
                                                       id="tooltip-top"
                                                       title="Request this Listing be removed"
                                                       placement="top"
                                                       classes={{tooltip:classes.tooltip}}>
                                                            
<Button color="danger" simple={true} onClick={()=>{this.requestRemoval()}}>
Request Listing removal
                                                         <Delete className={classes.tableActionButtonIcon + " " + classes.edit}/>
    </Button>
                                                     
                                                       
                                                     </Tooltip>
                                                     </p>
                      }
                  </GridContainer>
                  {formattedAddress.locality && 
                  
                      <div style={{padding: "20px"}}>
          <h3>
                <Typography className={classes.heading}>
                  {this.state.suggestion_data.updated_address ? "Updated Address" : "Suggested Address"}</Typography>
          </h3>
          
                </div>
                  }
                       
                
                  <GridContainer>
                  {formattedAddress.street_number !== undefined && 
                    <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="Street Number"
                        id="address_street_number"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                         disabled: true,
                          value: formattedAddress.street_number.l,
                          // value: ""_name,
                          name: "address_street_number"
                        }}
                      />
                    </GridItem>               
                  }
                  
                  {formattedAddress.route !== undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="Street"
                        id="address_route"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          disabled: true,
                          value: formattedAddress.route.l,
                          // value: ""_name,
                          name: "address_route"
                        }}
                      />
                    </GridItem>        
                  }  
                  {formattedAddress.street_number !== undefined && formattedAddress.route !== undefined && 
                  
                  <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="Suite/Unit"
                        id="address_suite"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          disabled: true,
                          // value: formattedAddress.route.l,
                          // value: ""_name,
                          name: "address_suite"
                        }}
                      />
                    </GridItem>   
                  }        
                  </GridContainer>
                  <GridContainer>
                  
                  {formattedAddress.locality !== undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="City"
                        id="address_locality"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          disabled: true,
                          value: formattedAddress.locality.l,
                          // value: ""_name,
                          name: "address_locality"
                        }}
                      />
                    </GridItem>       
                  }
                  
                  {formattedAddress.administrative_area_level_1 !== undefined && 
                    <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="State"
                        id="address_administrative_area_level_1"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          disabled: true,
                          value: formattedAddress.administrative_area_level_1.l,
                          // value: ""_name,
                          name: "address_administrative_area_level_1"
                        }}
                      />
                    </GridItem>      
                  }

                  {formattedAddress.postal_code !== undefined && 
                    <GridItem xs={12} sm={12} md={4}>
                    
                      <CustomInput
                        labelText="Postal Code"
                        id="address_postal_code"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          disabled: true,
                          value: formattedAddress.postal_code_suffix !== undefined ? formattedAddress.postal_code.l+"-"+formattedAddress.postal_code_suffix.l : formattedAddress.postal_code.l,
                          name: "address_postal_code"
                        }}
                      />
                    </GridItem>       
                  }
                  
                  </GridContainer>
                  
                  <GridContainer>
                  {formattedAddress.administrative_area_level_2 !== undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="County"
                        id="address_administrative_area_level_2"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          disabled: true,
                          value: formattedAddress.administrative_area_level_2.l,
                          // value: ""_name,
                          name: "address_administrative_area_level_2"
                        }}
                      />
                    </GridItem>          
                  } 
                  {formattedAddress.country !== undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="Country"
                        id="address_country"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          disabled: true,
                          value: formattedAddress.country.s,
                          // value: ""_name,
                          name: "address_country"
                        }}
                      />
                    </GridItem>      
                  }
                            
                  {formattedAddress.route && !this.state.suggestion_data.updated_address &&
                  <div>
                  <GridItem xs={12} sm={12} md={12}>
                      Use Suggested Address
                                                     <IconButton style={{color:"green"}} aria-label="Edit" className={classes.tableActionButton} onClick={() => {this.useSuggested("address")}}>
                                                       <Done  fontSize="large" className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton>
                      Reject Suggested Address
                                                     <IconButton style={{color:"red"}} aria-label="Edit" className={classes.tableActionButton} onClick={() => {this.removeSuggested("address")}}>
                                                       <Delete  fontSize="large" className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton>
                      </GridItem>
                  </div>
                  }   
                  </GridContainer>
                  {!this.state.suggestion_data.updated_address &&
                  <div style={{padding: "20px"}}>
          <h3>
                <Typography className={classes.heading}>Current Address</Typography>
          </h3>
                </div>
                  }
                  
                  {!this.state.suggestion_data.updated_address &&
                  <div>
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="Street"
                        id="address_route"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          // required: true,
                          
                          //value: formattedAddress.route.l,
                          defaultValue: listing.location.address,
                          name: "new_location_address"
                        }}
                      />
                    </GridItem>        
{/*                   
                  <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="Suite/Unit"
                        id="address_suite"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          // disabled: tr,
                          //// value: formattedAddress.route.l,
                          //// value: ""_name,
                          name: "address_suite"
                        }}
                      />
                    </GridItem>    */}
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="City"
                        id="address_locality"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          onChange: this.handleChange,
                          
                          //value: formattedAddress.locality.l,
                          //// value: ""_name,
                          defaultValue: listing.location.city,
                          name: "new_location_city"
                        }}
                      />
                    </GridItem>       
                    <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="State"
                        id="address_administrative_area_level_1"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          onChange: this.handleChange,
                          
                          //value: formattedAddress.administrative_area_level_1.l,
                          //// value: ""_name,
                          defaultValue: listing.location.state,
                          name: "new_location_state"
                        }}
                      />
                    </GridItem>      
                    <GridItem xs={12} sm={12} md={4}>
                    
                      <CustomInput
                        labelText="Postal Code"
                        id="address_postal_code"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // required: true,
                          
                          onChange: this.handleChange,
                          defaultValue: listing.location.postal_code,
                          //value: formattedAddress.postal_code_suffix == undefined ? formattedAddress.postal_code.l+"-"+formattedAddress.postal_code_suffix.l : formattedAddress.postal_code.l,
                          name: "new_location_postal_code"
                        }}
                      />
                    </GridItem>      
                    </div>
                    }
                  <GridContainer> 
                  
                  </GridContainer>
                  
                                 
<GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                  <TextField
          id="filled-multiline-static"
          label="Notes * - Visible to the Public"
          multiline
          rows="4"
          name="new_location_notes"
          placeholder="Public Notes are used to provide information to the public, such as directions to the site or certification information."
          defaultValue={listing.location.notes}
          variant="filled"
          fullWidth="true"
            onChange= {this.handleChange}
        />
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={2}>
                    <Button  onClick={()=>this.moveOnToMaterials("yes")} color="danger">
                    Move on to Materials selection

                      </Button>

                    </GridItem> */}
                  </GridContainer>


              
                  

                
                                                     
                  <Button onClick={()=>this.saveUpdates()} color="success">Save, and move on to materials</Button>

                  <Button onClick={()=>this.props.back({completed: false})} color="danger" simple={true}>Cancel and just Go Back</Button>
      </div>
                    }
                    </div>
    );
  }
}

ValidateListing.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(ValidateListing);
