import axios from "axios";


export function apiSetupHeaders() {
    

        let headers = JSON.parse(localStorage.getItem('current_login'))
        if(headers == null)
        return false
        // access-token: "8qbyffHBonvlCMcZIJIs5g"
        // cache-control: "max-age=0, private, must-revalidate"
        // client: "hq4uL_BOlnf330Cpa7Sgig"
        // content-type: "application/json; charset=utf-8"
        // expiry: "1570464910"
        // token-type: "Bearer"
        // uid: "valdezmarka@gmail.com"
        // headers.authorization
        axios.defaults.headers.common['access-token'] = headers["access-token"]; 
        axios.defaults.headers.common['uid'] = headers["uid"]; 
        axios.defaults.headers.common['client'] = headers["client"]; 
        return true
        
        // axios.defaults.headers.common['access-token'] = headers["access-token"]; 
        // return {}
}

export function apiStoreHeaders(headers){
    console.log(headers['access-token'])
    if(headers['access-token'] !== undefined){
        console.log("updated token", headers)
    localStorage.setItem('current_login', JSON.stringify(headers));
    }else{
        console.log("ignored", headers)
    }
}
