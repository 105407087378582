import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

import ReactCrop from 'react-image-crop';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import ShoppingCart from '@material-ui/icons/ShoppingCart';
import AddAlert from '@material-ui/icons/AddAlert';
import PaypalButton from 'components/PayPal/PaypalButton';

import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormLabel from '@material-ui/core/FormLabel';
import Check from "@material-ui/icons/Check";

import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"
import 'react-image-crop/dist/ReactCrop.css';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import logo_listing from "assets/img/logo_listing.png";
import ad_banner_listing from "assets/img/ad_banner_listing.png";
import sticky_listing from "assets/img/sticky_listing.png";
import Chip from '@material-ui/core/Chip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Table from "components/Table/Table.jsx";
import IconButton from '@material-ui/core/IconButton';
const CLIENT = {
    sandbox: 'AVPmKfbQw3-k6g_YhL5Z8IIdL85zxwC7c8S0x_87eRDXtKeqXaApfBkcc86AoR83cq-aYNdygyp9nT0m',
    production: 'AfhyJk8uWN-pUzYVn_5hQ5QiRt8zHvQHtSn6xiNkl_Fnud44QSlZfe4_osar4Sz6b1ebtNosvmZ8kC9l',
  };
  
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class Visbility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromTask: false,
      postal_codes: [],
      isComplete: false,
      ad_postal_codes: [],
      cart_index: -1,
      postal_index: -1,
      materials_index: -1,
      ad_materials: [],
      cart_postal: 0,
      viewDialog: {open: false},
      viewDialog2: {open: false},
      viewDialog3: {open: false},
      errors: {},
      logo: {url: "", added: false},
      ad: '',
      ads: [],
      logo_out: '',
      checked: [],
      checkout: false,
      cart:[],
      crop: {
        unit: 'px', // default, can be 'px' or '%'
        x: 0,
        y: 0,
        width: 180,
        height: 180
      },
      crop2: {
        unit: 'px', // default, can be 'px' or '%'
        x: 0,
        y: 0,
        width: 300,
        height: 250
      },
      width: 0, height: 0,
      ad_size: "300",
    };
    // this.addUser = this.addUser.bind(this);
    // this.addProfile = this.updateProfile.bind(this);
  }
  checkOut = () =>{
    let cart =  this.state.cart 
    if(this.state.logo.added)
    cart.push({type: "logo", added:true,  completed: true, image: this.state.logo.url, total: 79})
    
    let postal_codes = this.state.postal_codes
    for(let i=0; i<postal_codes.length; i++)
    if(postal_codes[i].postal_code  !== -1){
      cart.push({type: "postal_code", completed: true,  added:true, postal_code: postal_codes[i].postal_code, target_type: "", target_id: "", total: 29})
    }
    


    this.checkCompleted()

    this.setState({checkout:true})

  }
  checkCompleted = () =>{
    let completed = true
    let cart = this.state.cart
    for(let i =0; i< cart.length; i++)
    if(cart[i].added)
    if(!cart[i].completed)
    completed = false

    this.setState({isComplete: completed})
    
  }
  addToCart = (product_type, id) => {

    if(product_type == "logo"){
      let l = this.state.logo
      l.added = true
      this.setState({logo: l})
    }else{
      let ads = this.state.ads
      let cart = this.state.cart
      for(let i = 0; i< ads.length; i++){
        if(ads[i].id == id)
        {
          ads[i].completed = false
          ads[i].added = true
          ads[i].type = "ad"
          ads[i].link_url = ""
          ads[i].number_of_months_materials = []
          ads[i].number_of_impressions = []
          ads[i].number_of_months = []
          // ads[i].number_of_impressions[0] = 10
          // ads[i].number_of_months[0] = 1
          ads[i].total = 0
          cart.push(ads[i])
        }

      }
      this.setState({ads: ads, cart: cart})

    }
    
    
  }
  componentDidMount = () =>{
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.getAssets()
    let token =this.props.location.search;
    if(token.indexOf('from_listing=true') !== -1)    
    this.setState({fromTask: true})
    // else
    // this.setState({fromTask: true, new_user: false})
  }
  
  componentWillUnmount =() =>{
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleToggle = value => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    console.log(newChecked)

    let cart = this.state.cart
    let cart_index = this.state.cart_index
    if(this.state.cart_index == -1)    
    {
      console.log(cart.length)
    cart_index = cart.length
    this.setState({
      cart_index: cart.length
    })
  }
    cart[cart_index] = {type:"recurring", payload: newChecked} 
    // console.log(cart)
    this.setState({
      cart_index: cart.length-1,
      checked: newChecked,
      cart: cart
    });
    console.log(this.state.cart)
  };
  viewExample = (type) =>{
    console.log(type)
    let image = ""
    if(type == "sticky listing")
    image = sticky_listing
    else
    if(type == "logo listing")
    image = logo_listing
    else
    image = ad_banner_listing
    
    let viewDialog = {open:true, title: "Example of " + type, titleDetails: "", fullScreen: false, content: <img style={{width: "100%"}}src={image}/>}
    
    console.log(viewDialog)
    this.setState({viewDialog: viewDialog})

  }
  onChangeHandler=event=>{
    // console.log("CHANBGE")
        // this.setState({logo_out: event.target.files[0]})
        
        var image = new Image();
        // image.setAttribute('crossOrigin', 'anonymous');
        // image.src = '/static/media/logo_white.fb5dadfe.png';
        let w = ""
        let h = ""
        let objectUrl = URL.createObjectURL(event.target.files[0])
        image.onload = () => {
          // console.log(image)
          // console.log(image.width,this.state.width)
          // console.log(image.height, this.state.height)
          if((image.width > this.state.width) || (image.height > this.state.height)){
          this.setAlert("danger", `Please use an image that is smaller than your screen size so we can crop it down to 180x180 px. Current image is ${image.width} x ${image.height} and screen size is ${this.state.width}x${this.state.height}`)
          
          URL.revokeObjectURL(objectUrl);
          }
          else{
            
        let viewDialog = {open:true, title: "Crop", titleDetails: "180 px X 180 px (same size as facebook organization profile image)"}
        this.setState({file: objectUrl, viewDialog2: viewDialog})
          }

          // console.log(this.width)
          // console.log(this.height)
        };
        image.src = objectUrl

        // console.log(image)
        // console.log(image.naturalHeight)
        // console.log(image.naturalWidth)
        // console.log(w)
        // console.log(h)
        // if image.naturalHeight > this.state.height-50 || image
        // console.log(event.target.files[0])
        // console.log(URL.createObjectURL(event.target.files[0]))
    
    }
    onChangeHandler2=event=>{
      // console.log("CHANBGE")
          // this.setState({logo_out_2: event.target.files[0]})
          
          var image = new Image();
          // image.setAttribute('crossOrigin', 'anonymous');
          // image.src = '/static/media/logo_white.fb5dadfe.png';
          let w = ""
          let h = ""
          let objectUrl = URL.createObjectURL(event.target.files[0])
          image.onload = () => {
            console.log(image)
            console.log(image.width,this.state.width)
            console.log(image.height, this.state.height)
            if((image.width > this.state.width) || (image.height > this.state.height)){
            this.setAlert("danger", `Please use an image that is smaller than your screen size so we can crop it down to 180x180 px. Current image is ${image.width} x ${image.height} and screen size is ${this.state.width}x${this.state.height}`)
            
            URL.revokeObjectURL(objectUrl);
            }
            else{
              
          let viewDialog = {open:true, title: "Crop", titleDetails: this.state.ad_size == "300" ? "300 px X 250":"728 x 90"}
          this.setState({file2: objectUrl, viewDialog3: viewDialog})
            }
  
            // console.log(this.width)
            // console.log(this.height)
          };
          image.src = objectUrl
  
          // console.log(image)
          // console.log(image.naturalHeight)
          // console.log(image.naturalWidth)
          // console.log(w)
          // console.log(h)
          // if image.naturalHeight > this.state.height-50 || image
          // console.log(event.target.files[0])
          // console.log(URL.createObjectURL(event.target.files[0]))
      
      }
    setAlert = (color, message) => {      
      this.setState({successAlertColor: color, alertMessage: message, successAlert: true})
      setTimeout(()=>this.setState({successAlert: false}),4000)

    }
    updateLogo = async() =>{
        const canvas = document.getElementById('outbound');
        // console.log(canvas)
        const base64Image = canvas.toDataURL('image/jpeg');
        console.log(base64Image)
        canvas.toBlob( async (blob) =>{
            
            console.log(blob)
            
          const formData = new FormData();
          formData.append('picture', blob);
        
          // Post via axios or other transport method
          const { history } = this.props;
          
          if(!apiSetupHeaders()){
            return history.push("/auth/login-page");
        }
      
          // const fields = ["new_email"];
          // const formElements = e.target.elements;
          // const formValues = fields
          //   .map(field => ({
          //     [field]: formElements.namedItem(field).value
          //   }))
          //   .reduce((current, next) => ({ ...current, ...next }));
      
          let registerRequest;
          
          try {
            registerRequest = await axios.post(
              `${REACT_APP_SERVER_URL}/v1/org_profile/logo`, formData,
              // {
              //   profile: formValues
              // },
              {
              //   withCredentials: true
              }
            );
          } catch ({ response }) {
            registerRequest = response;
          }
          const { data: registerRequestData } = registerRequest;
          let l = this.state.logo
          l.url = registerRequestData.logo
          this.setState({ logo: l })
          // if(registerRequest.status > 205){
          //     console.log(registerRequestData) // do something here
          // }else{
              apiStoreHeaders(registerRequest.headers)
        }, 'image/png', 1);
        // document.getElementById('outbound').destroy
        document.getElementById('outbound').remove()
    }
    updateLogo2 = async() =>{
      const canvas = document.getElementById('outbound2');
      // console.log(canvas)
      const base64Image = canvas.toDataURL('image/jpeg');
      console.log(base64Image)
     
      canvas.toBlob( async (blob) =>{
          
          console.log(blob)
          
        const formData = new FormData();
        formData.append('picture', blob);
      
        // Post via axios or other transport method
        const { history } = this.props;
        
        if(!apiSetupHeaders()){
          return history.push("/auth/login-page");
      }
    
        // const fields = ["new_email"];
        // const formElements = e.target.elements;
        // const formValues = fields
        //   .map(field => ({
        //     [field]: formElements.namedItem(field).value
        //   }))
        //   .reduce((current, next) => ({ ...current, ...next }));
    
        let registerRequest;
        
        try {
          registerRequest = await axios.post(
            `${REACT_APP_SERVER_URL}/v1/org_profile/ad`, formData,
            // {
            //   profile: formValues
            // },
            {
            //   withCredentials: true
            }
          );
        } catch ({ response }) {
          registerRequest = response;
        }
        const { data: registerRequestData } = registerRequest;
        let ads = this.state.ads
        ads.push(registerRequestData.ad)
        this.setState({ads: ads})
        // if(registerRequest.status > 205){
        //     console.log(registerRequestData) // do something here
        // }else{
            apiStoreHeaders(registerRequest.headers)
      }, 'image/png', 1);
      document.getElementById('outbound2').remove()
  }
    updateCrop = (crop) =>{
      //   console.log(crop)
    this.setState({ crop: crop })
  
    }
    updateCrop2 = (crop) =>{
      //   console.log(crop)
    this.setState({ crop2: crop })
  
    }
  saveCropped = async() =>{
    // (image, pixelCrop, fileName) {
        // let image = this.state.logo
        var image = new Image();
        // image.setAttribute('crossOrigin', 'anonymous');
        // image.src = '/static/media/logo_white.fb5dadfe.png';
        
        image.src = this.state.file;
        
        let pixelCrop = this.state.crop
        let fileName = "test.png"
    const canvas = document.createElement('canvas');
    canvas.id = "outbound"
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
   
    let scaleY = 1
    let scaleX = 1


    if(image.naturalHeight > this.state.height)
    scaleY = image.naturalHeight / this.state.height;
    
    if(image.naturalWidth > this.state.width)
    scaleX = image.naturalWidth / this.state.width;


    console.log(image.naturalWidth)
    console.log(image.naturalHeight)
    
console.log(image.width)
console.log(image.height)
    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );
    this.setState({viewDialog2: false})
    setTimeout(()=>{
      
    document.getElementById("canvas").appendChild(canvas)
    this.updateLogo()

    },1000)

  }
  saveCropped2 = async() =>{
    // (image, pixelCrop, fileName) {
        // let image = this.state.logo
        var image = new Image();
        // image.setAttribute('crossOrigin', 'anonymous');
        // image.src = '/static/media/logo_white.fb5dadfe.png';
        
        image.src = this.state.file2;
        
        let pixelCrop = this.state.crop2
        // let fileName = "test.png"
    const canvas = document.createElement('canvas');
    canvas.id = "outbound2"
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
   
    let scaleY = 1
    let scaleX = 1


    if(image.naturalHeight > this.state.height)
    scaleY = image.naturalHeight / this.state.height;
    
    if(image.naturalWidth > this.state.width)
    scaleX = image.naturalWidth / this.state.width;


    // console.log(image.naturalWidth)
    // console.log(image.naturalHeight)
    
// console.log(image.width)
// console.log(image.height)
    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    this.setState({viewDialog3: false})
    setTimeout(()=>{
    document.getElementById("canvas2").appendChild(canvas)
    this.updateLogo2()
  },1000)

  }
  handleChange = (event) => {
    // console.log(event.target)
    let crop2 = this.state.crop2
    if(event.target.value == 300){
    crop2.width = 300
    crop2.height = 250}  
    else{

      crop2.width = 728
      crop2.height = 90
    }
    this.setState({ad_size: event.target.value, crop2: crop2})
    
    
        // let name = event.target.name
        // console.log(name)
        // console.log(event.target.value)
        // let accepts = this.state.accepts
        // let target = event.target.name.split('_')
  } 
  deletePostal = (pc) =>{
    // let add =document.getElementById('postal_codes').value
    let postal_codes = this.state.postal_codes
    postal_codes[pc.index].postal_code = -1

    let cart_postal = this.state.cart_postal
    cart_postal = cart_postal - 1
    // for(let i = 0; i<postal_codes
    // postal_codes.push({postal_code: add, index: postal_codes.length})
    this.setState({postal_codes: postal_codes, cart_postal: cart_postal})


    // document.getElementById('postal_codes').value = ""
    // console.log(e.target.value)
  }
  
  deleteAdMaterial = (pc) =>{
    // let add =document.getElementById('postal_codes').value
    let postal_codes = this.state.ad_materials
    postal_codes[pc.index].material = -1
    this.setState({ad_materials: postal_codes})
    
    let target = pc.item
    target.total = this.getCurrentPostalCount(target) + this.getCurrentMaterialsCount(target)
   



    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == target.id){

        target.number_of_months_materials[pc.index] = -1

        // cart[i].number_of_impressions.push(10)
        // cart[i].number_of_months.push(1)
        cart[i] = target
      }
    }
    
    
    let materials_index = this.state.materials_index
    if(materials_index == -1)    
    {
      // console.log(cart.length)
    materials_index = cart.length
    this.setState({
      // cart_index: cart.length
      materials_index: materials_index
    })
  }
    cart[materials_index] = {type:"materials_target", payload: postal_codes} 
    this.setState({cart: cart})


    // document.getElementById('postal_codes').value = ""
    // consol
  }
  
  deleteAdPostal = (pc) =>{
    // let add =document.getElementById('postal_codes').value
    let postal_codes = this.state.ad_postal_codes
    postal_codes[pc.index].postal_code = -1
    this.setState({ad_postal_codes: postal_codes})
    
    let target = pc.item
    target.total = this.getCurrentMaterialsCount(target) + this.getCurrentPostalCount(target)


    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == target.id){
        
        target.number_of_months_materials[pc.index] = -1

        cart[i].number_of_impressions[pc.index] = -1
        cart[i].number_of_months[pc.index] = -1
        cart[i] = target
      }
    }
    
    let postal_index = this.state.postal_index
    if(postal_index == -1)    
    {
      // console.log(cart.length)
    postal_index = cart.length
    this.setState({
      // cart_index: cart.length
      postal_index: postal_index
    })
  }
    cart[postal_index] = {type:"postal_target", payload: postal_codes} 

    this.setState({cart: cart})


    // document.getElementById('postal_codes').value = ""
    // consol
  }
  // canCheckOut = () =>{
    // this.state.cart.length > 0 || this.cart_postal > 0

  // }
  removeFromCart = (item, type) =>{
    console.log("remove from", item)
    // item.added = false


    // if(type == "logo")
    let cart = this.state.cart
    for(let i=0; i< cart.length; i++){
      if(cart[i].type == type && type == 'logo')
      cart[i].added = false
      else      
      if(cart[i].type == type && type == 'ad'){
      if(cart[i].id == item.id)
      cart[i].added = false
      }else{
        if(cart[i].type == type && type == 'postal_code')        
        if(cart[i].postal_code == item.postal_code)
        cart[i].added = false       
      }
    }
    
    console.log("new cart", cart)
    this.setState({cart: cart})
    
    this.checkCompleted()
    
  }
  addPostalCode = () =>{
    let add =document.getElementById('postal_codes').value
    let postal_codes = this.state.postal_codes
    // this.addToCart("postal", postal_codes.length)
    postal_codes.push({postal_code: add, index: postal_codes.length})
    let cart_postal = this.state.cart_postal
    cart_postal = cart_postal + 1
    // for(let i = 0; i<postal_codes
    // postal_codes.push({postal_code: add, index: postal_codes.length})
    this.setState({postal_codes: postal_codes, cart_postal: cart_postal})
    document.getElementById('postal_codes').value = ""
    // console.log(e.target.value)
  } 
  addAdPostalCode = (item) =>{
    let add =document.getElementById('ad_postal_codes').value
    let postal_codes = this.state.ad_postal_codes
    postal_codes.push({postal_code: add, index: postal_codes.length, item: item})
    this.setState({ad_postal_codes: postal_codes})

    
    document.getElementById('ad_postal_codes').value = ""

    
    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == item.id){

        cart[i].number_of_impressions.push(10)
        cart[i].number_of_months.push(1)
        // if(this.state.ad_postal_codes.length !== 1)
        cart[i].total = cart[i].total + 100
      }
    }
    
    let postal_index = this.state.postal_index
    if(postal_index == -1)    
    {
      // console.log(cart.length)
    postal_index = cart.length
    this.setState({
      // cart_index: cart.length
      postal_index: postal_index
    })
  }
    cart[postal_index] = {type:"postal_target", payload: postal_codes} 
    this.setState({cart: cart})
    // console.log(e.target.value)
  } 
  
  addAdMaterial = (item) =>{
    let add =document.getElementById('ad_material').value
    let postal_codes = this.state.ad_materials
    postal_codes.push({postal_code: add, index: postal_codes.length, item: item})
    this.setState({ad_materials: postal_codes})

    
    document.getElementById('ad_material').value = ""

    
    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == item.id){

        // cart[i].number_of_impressions.push(1)
        cart[i].number_of_months_materials.push(1)
        // if(this.state.ad_postal_codes.length !== 1)
        cart[i].total = cart[i].total + 250
      }
    }

    
    let materials_index = this.state.materials_index
    if(materials_index == -1)    
    {
      // console.log(cart.length)
    materials_index = cart.length
    this.setState({
      // cart_index: cart.length
      materials_index: materials_index
    })
  }
    cart[materials_index] = {type:"materials_target", payload: postal_codes} 


    this.setState({cart: cart})
    // console.log(e.target.value)
  } 
  removeLogo = async() => {
    const { history } = this.props;
    
    if(!apiSetupHeaders()){
      return history.push("/auth/login-page");
  }

    // const fields = ["new_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    
    try {
      registerRequest = await axios.delete(
        `${REACT_APP_SERVER_URL}/v1/org_profile/logo`,
        // {
        //   profile: formValues
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    this.getAssets()

  }
  removeAd = async(id) => {
    const { history } = this.props;
    
    if(!apiSetupHeaders()){
      return history.push("/auth/login-page");
  }

    // const fields = ["new_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    
    try {
      registerRequest = await axios.delete(
        `${REACT_APP_SERVER_URL}/v1/org_profile/ad/${id}`,
        // {
        //   profile: formValues
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    this.getAssets()

  }
  getAssets = async() => {
    const { history } = this.props;
    
    if(!apiSetupHeaders()){
      return history.push("/auth/login-page");
  }

  

    // const fields = ["new_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    
    try {
      registerRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/org_profile/assets`,
        // {
        //   profile: formValues
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    
    let l = this.state.logo
    l.url = registerRequestData.logo
    this.setState({logo: l, ads: registerRequestData.ads})

  }
  updateTotalImpressions = (e, index, target) =>{
    // console.log(e)
    // console.log(target)
    if(e.target.value >= 10 && e.target.value <= 100){
    target.number_of_impressions[index] = e.target.value
    target.total = this.getCurrentMaterialsCount(target)
    for(let i = 0; i<target.number_of_impressions.length; i++)    
    if(this.state.ad_postal_codes[i].postal_code !== -1)
    target.total = target.total + target.number_of_impressions[i] * 10 * target.number_of_months[i]
    }
    

    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == target.id)
      cart[i]  = target
    }
    this.setState({cart: cart})

    
  }
  getCurrentMaterialsCount = (target) => {
    let total = 0
    for(let i = 0; i<target.number_of_months_materials.length; i++){    
      console.log(this.state.ad_postal_codes[i])
    if(this.state.ad_materials[i].material !== -1)
    total = total + (250 * target.number_of_months_materials[i])}
    
    
    return total

    
  }
  getCurrentPostalCount = (target) => {
    let total = 0
    for(let i = 0; i<target.number_of_impressions.length; i++)    
    if(this.state.ad_postal_codes[i].postal_code !== -1)
    total = total + target.number_of_impressions[i] * 10 * target.number_of_months[i]
    
    
    return total

    
  }
  updateTotalMonths = (e, index, target) =>{
    // console.log(e)
    // console.log(target)
    if(e.target.value >= 1 && e.target.value <= 12){
    target.number_of_months[index] = e.target.value

    target.total = this.getCurrentMaterialsCount(target)
    for(let i = 0; i<target.number_of_impressions.length; i++){    
      console.log(this.state.ad_postal_codes[i])
    if(this.state.ad_postal_codes[i].postal_code !== -1)
    target.total = target.total + target.number_of_impressions[i] * 10 * target.number_of_months[i]}
    }
  
    

    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == target.id)
      cart[i]  = target
    }
    this.setState({cart: cart})

    
  }
  updateTotalMonthsMaterial = (e, index, target) =>{
    // console.log(e)
    // console.log(target)
    if(e.target.value >= 1 && e.target.value <= 12){
    target.number_of_months_materials[index] = e.target.value

  
    target.total =  this.getCurrentPostalCount(target)
    for(let i = 0; i<target.number_of_months_materials.length; i++){    
      console.log(this.state.ad_postal_codes[i])
    if(this.state.ad_materials[i].material !== -1)
    target.total = target.total + (250 * (target.number_of_months_materials[i] == 12 ? 10 : target.number_of_months_materials[i]))}
    }
  
    

    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == target.id)
      cart[i]  = target
    }
    this.setState({cart: cart})

    
  }
  updateOrder = async(payload)=>{
    // console.log("updateOrder")
    // console.log(payload)
    this.setAlert("success", `${payload.msg}`)
    
    const { history } = this.props;
    
    if(payload.msg == "Successful payment! Redirecting you to the dashboard..."){
      setTimeout(
        ()=>{return history.push("/update/earth911")},5000)
    }
    

  }
  updateMaterialAll = (e, index, target) =>{
    // console.log(e.target)
    // console.log(target)
    // if(e.target.value >= 1 && e.target.value <= 12){
    if(e.target.checked)
    target.number_of_months_materials[index] = 12
    else
    target.number_of_months_materials[index] = 1

  
    target.total =  this.getCurrentPostalCount(target)
    for(let i = 0; i<target.number_of_months_materials.length; i++){    
      console.log(this.state.ad_postal_codes[i])
    if(this.state.ad_materials[i].material !== -1)
    target.total = target.total + (250 * (target.number_of_months_materials[i] == 12 ? 10 : target.number_of_months_materials[i]))
  // }
    }
  
    

    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == target.id)
      cart[i]  = target
    }
    this.setState({cart: cart})

    
  }
  completeAd = (target) =>{



    let cart = this.state.cart
    for(let i = 0; i<cart.length; i++){
      if(cart[i].type == "ad")
      if(cart[i].id == target.id)
      cart[i].completed = true
    }
    this.setState({cart: cart, ad_materials: [], ad_postal_codes: []})
    
    this.checkCompleted()


  }
  render() {
    const { classes} = this.props;
    const { errors, crop, viewDialog , viewDialog2, viewDialog3, logo, ad, checkout } = this.state;
    const onSuccess = (payment) =>
    {console.log('Successful payment!', payment); 
    this.updateOrder({msg: 'Successful payment! Redirecting you to the dashboard...', payload: payment}
  
  )}
  const onError = (error) =>
    {console.log('Erroneous payment OR failed to load script!', error); this.updateOrder({msg: 'Erroneous payment OR failed to load script!', payload: error})}
  const onCancel = (data) =>
    {console.log('Cancelled payment!', data); this.updateOrder({msg: 'Cancelled payment!', payload: data})}
    let postal_out = this.state.postal_codes.map((pc)=>{
      if(pc.postal_code !== -1)
      return (
        
      <Chip label={pc.postal_code} color={"primary"} 
      onDelete={()=>this.deletePostal(pc)}/>
      )
    })
    let ads_out = this.state.ads.map((ad)=>{
      // console.log(ad.added)
      return (
        <div>      
  <img src={REACT_APP_SERVER_URL+ad.image} />
  <p>
  <Button disabled={ad.added == undefined ? false : true} onClick={()=>{this.addToCart('ad', ad.id)}} color="success">{ad.added == undefined ? "Add to Cart":"Added"}</Button>
  <Button onClick={()=>{this.removeAd(ad.id)}} color="danger">Delete</Button>
  </p>
  </div>
      )
    })

    let ad_postal_out_options = this.state.ad_postal_codes.map((pc)=>{
      if(pc.postal_code !== -1)
      return (
        
        <span>
          
      <Chip label={pc.postal_code} color={"primary"} 
      onDelete={()=>this.deleteAdPostal(pc)}/>
                     <CustomInput
                           labelText="# of Months"
                          id="new_url"
                          /* error={errors.name} */
                          formControlProps={{
                            fullWidth: true
                           }}
                           
                           /* placeHolder= {item.number_of_months} */
                           inputProps={{
                             
                           onChange: (e)=>this.updateTotalMonths(e, pc.index, pc.item),
                             type: "Number",
                            value: pc.item.number_of_months[pc.index],
                            // required: true,
                             // defaultValue: "",
                             name: "new_url",
                           }}
                         />
                     <CustomInput
                           labelText="# of impressions (in 1000s)"
                          id="new_url"
                          /* error={errors.name} */
                          formControlProps={{
                            fullWidth: true
                           }}
                           inputProps={{
                             
                           onChange: (e)=>this.updateTotalImpressions(e, pc.index, pc.item),
                             type: "Number",
                             value: pc.item.number_of_impressions[pc.index],
                            // required: true,
                             // defaultValue: "",
                             name: "new_url",
                           }}
                         />
                     </span>
      )
    })
    
    let ad_material_options = this.state.ad_materials.map((pc)=>{
      if(pc.material !== -1)
      return (
        
        <span>
          
      <Chip label={pc.postal_code} color={"primary"} 
      onDelete={()=>this.deleteAdMaterial(pc)}/>
                     <CustomInput
                           labelText="# of Months"
                          id="new_url"
                          /* error={errors.name} */
                          formControlProps={{
                            fullWidth: true
                           }}
                           
                           /* placeHolder= {item.number_of_months} */
                           inputProps={{
                             
                           onChange: (e)=>this.updateTotalMonthsMaterial(e, pc.index, pc.item),
                             type: "Number",
                            value: pc.item.number_of_months_materials[pc.index],
                            // required: true,
                             // defaultValue: "",
                             name: "new_url",
                           }}
                         />    
                         <FormControlLabel
        classes={{
          root:
            classes.checkboxLabelControl +
            " " +
            classes.checkboxLabelControlClassName,
          label: classes.checkboxLabel
        }}
        control={
          <Checkbox
            tabIndex={-1}
            onClick={(e)=>this.updateMaterialAll(e, pc.index, pc.item)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            required
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        }
        label={
          <span>
            Save $500 and purchase a year!
          </span>
        }
      />                
                     </span>
      )
    })
    let ad_data = []

    let added_one = false    
        ad_data = this.state.cart.map((item)=>{


          
      if(item.added && item.type == "ad" && !item.completed && !added_one){
        added_one = true
        return(
       
        
          [
            
      <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Ad #{item.id} Options</h4>                 
      </CardHeader>
      <CardBody>
      <GridContainer>

          <GridItem xs={12} sm={12} md={6}>
                               <CustomInput
                        labelText="Postal Codes"
                        id="ad_postal_codes"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                         
                        inputProps={{
                          onKeyPress: (ev) => {
                            if (ev.key === 'Enter') {
                              this.addAdPostalCode(item)
                            }
                          },
  
                          //   type: "file",
                          // onChange: ,
                          // disabled: true,
                        //   required: true,
                        //   defaultValue: this.state.profile.organization,
                          name: "ad_postal_codes"
                        }}
                      />
                      <Button color="warning" onClick={()=>this.addAdPostalCode(item)}> Target Postal Code</Button>
                      <div>
                      {ad_postal_out_options}
                      </div>
                      </GridItem>
                      

          <GridItem xs={12} sm={12} md={6}>
                               <CustomInput
                        labelText="Materials"
                        id="ad_material"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                         
                        inputProps={{
                          onKeyPress: (ev) => {
                            if (ev.key === 'Enter') {
                              this.addAdMaterial(item)
                            }
                          },
  
                          //   type: "file",
                          // onChange: ,
                          // disabled: true,
                        //   required: true,
                        //   defaultValue: this.state.profile.organization,
                          name: "ad_material"
                        }}
                      />
                      <Button color="warning" onClick={()=>this.addAdMaterial(item)}> Target Material</Button>
                      <div>
                      {ad_material_options}
                      </div>
                      </GridItem>
                      </GridContainer>
                               </CardBody>
                               <CardFooter>
                                 
                      <Button color="success" onClick={()=>this.completeAd(item)}> Finish Options</Button>
                                 </CardFooter>
                               </Card>
                   
                    ]
          
         
        )
      }
        })

    let cart_data = []

    cart_data = this.state.cart.map((item)=>{
      if(!item.added)
      return []
      console.log(item.added)

      if(item.added && item.type == "logo")
      return(
        [
          <span> Logo
            </span>,
  <span><img src={REACT_APP_SERVER_URL+item.image} />
  </span>,
                 
        <FormControlLabel
        classes={{
          root:
            classes.checkboxLabelControl +
            " " +
            classes.checkboxLabelControlClassName,
          label: classes.checkboxLabel
        }}
        control={
          <Checkbox
            tabIndex={-1}
            onClick={() => this.handleToggle("logo-annual")}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            required
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        }
        label={
          <span>
            Recurring Anually
          </span>
        }
      />,
      
      <span>
      </span>,
              <span>
              <Button onClick={()=>{this.removeFromCart(item, 'logo')}} color="danger">Remove</Button>
              </span>
,
<span>
${item.total}
</span>
        ]

      )
      
      if(item.added && item.type == "postal_code")
      return(
        [

          <span> Sticky Listings Postal Code
          </span>,
                  
                  <span>{item.postal_code}</span>,
        <FormControlLabel
        classes={{
          root:
            classes.checkboxLabelControl +
            " " +
            classes.checkboxLabelControlClassName,
          label: classes.checkboxLabel
        }}
        control={
          <Checkbox
            tabIndex={-1}
            onClick={() => this.handleToggle("postal."+item.postal_code)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            required
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        }
        label={
          <span>
            Recurring Monthly
          </span>
        }
      />,
      
      <span>
      </span>,
              <span>
              <Button onClick={()=>{this.removeFromCart(item, 'postal_code')}} color="danger">Remove</Button>
              </span>
,
<span>
${item.total}
</span>

        ]

      )
      
      if(item.added && item.type == "ad"){
      return(
        [

          <span> Ad #{item.id}
          </span>,
  <span><img src={REACT_APP_SERVER_URL+item.image} />
  </span>,
  
  <span> 
  </span>,
          <span> 
          </span>,
  
  <span>
  <Button onClick={()=>{this.removeFromCart(item, 'ad')}} color="danger">Remove</Button>
  </span>,
        
<span>
${item.total}
</span>
        ]
      

      )
    }

    })
    

    if(cart_data.length > 0){
      console.log(cart_data)
      // let this.state.cart
      let total = 0
      let cart = this.state.cart
      for(let i = 0; i<cart.length; i++){
        if(cart[i].added)
        total = total + cart[i].total
      }
      console.log(total)
      cart_data.push(["", "", "", "", "Grand Total: ", "$"+total])
    }

    

    // console.log(this.state.tasks.visibilitys[0].completed)
    // console.log(this.state.tasks.visibilitys[1].completed)
    // console.log(this.state.tasks.visibilitys[0].completed)
    return (
      <div>
          <Snackbar
        place="tr"
        color={this.state.successAlertColor}
        icon={AddAlert}
        message={this.state.alertMessage}
        open={this.state.successAlert}
        closeNotification={() => this.setState({ successAlert: false })}
        close
      />
        {viewDialog2.open &&
      <div>
        
        <h4>
        {viewDialog2.title} - 
        <small>
                    {viewDialog2.titleDetails}
                        </small>
        </h4>
                        <ReactCrop locked={true} src={this.state.file} crop={this.state.crop} onChange={(crop) => {this.updateCrop(crop)} }/>
                  <GridItem xs={12} sm={12} md={12}>
              <Button color="success" onClick={()=>{this.saveCropped()}}>
                            Crop and Save
                          </Button>
                          </GridItem>
                          </div>
      }
    {/* <Dialog fullScreen onClose={()=>this.setState({viewDialog3: {open:false}})} aria-labelledby="simple-dialog-title" open={viewDialog3.open} style={{maxWidth: "100%"}}>
      <DialogTitle id="simple-dialog-title"> {viewDialog.title}
                    <small>
                    {viewDialog3.titleDetails}
                        </small></DialogTitle> */}
      {viewDialog3.open &&
      <div>
        <h4>
        {viewDialog3.title} - 
        <small>
                    {viewDialog3.titleDetails}
                        </small>
        </h4>
                        <ReactCrop locked={true} src={this.state.file2} crop={this.state.crop2} onChange={(crop) => {this.updateCrop2(crop)} }/>
                  <GridItem xs={12} sm={12} md={12}>
              <Button color="success" onClick={()=>{this.saveCropped2()}}>
                            Crop and Save
                          </Button>
                          </GridItem>
                          </div>
      }
    {/* </Dialog> */}
    {checkout &&
    <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
      
      <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Checkout</h4>                 
                </CardHeader>
                <CardBody>
  


  <Table
                  tableHeaderColor="warning"
                  tableHead={[
                    // "Name", "City", "Status", 
                    "Product", " ", " ", "","","Total"]}
                  tableData={ cart_data } 
                />
  
               

        

                  </CardBody>
                  <CardFooter>     
                  {this.state.isComplete &&
 <PaypalButton
          cart={this.state.cart}
          client={CLIENT}
           env={"production"} 
          /* env={"sandbox"} */
          commit={true}
          currency={'USD'}
          total={100}
          onSuccess={onSuccess}
          onError={onError}
          onCancel={onCancel}
        />
                  }

                    </CardFooter>
                  </Card>
                  {ad_data}
                  </GridItem>
                  </GridContainer>
                   
      }
    {!viewDialog2.open &&  !viewDialog3.open && !checkout &&
    <GridContainer>
        <Dialog fullScreen={viewDialog.fullScreen} onClose={()=>this.setState({viewDialog: {open:false}})} aria-labelledby="simple-dialog-title" open={viewDialog.open} style={{maxWidth: "100%"}}>
      <DialogTitle id="simple-dialog-title"> {viewDialog.title}
                    <small>
                    {viewDialog.titleDetails}
                        </small></DialogTitle>
      
                        {viewDialog.content}
    </Dialog>

          <GridItem xs={12} sm={12} md={8}>
          {
            this.state.fromTask &&
            <Card>
            <CardBody style={{fontWeight: "bold"}}>
              Thank you for verifying your listing on Earth911's Recycling Database. Your listings will now have a verified badge and will display above unverified listings per zip code. Will update you on the Dashboard when those changes have taken effect. Sponsored articles, recycling database category and how-to guide sponsorships, and custom advertising campaigns are also available. Please contact us at <a href="mailto:advertising@earth911.com">advertising@earth911.com</a> for more information.
              </CardBody>
              </Card>
          }
          {(this.state.cart.length > 0 || this.state.cart_postal > 0 || this.state.logo.added) &&
          // <Button size={"large"} onClick={()=>this.checkOut()} color={"success"}> Check-out {"  "} <ShoppingCart />
          // </Button>
          <Tooltip
                                                     id="tooltip-top"
                                                     title="Checkout"
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton aria-label="Help" className={classes.tableActionButton} onClick={()=>this.checkOut()}>
          <ShoppingCart className={classes.tableActionButtonIcon + " " + classes.edit} />
          </IconButton>
                                                  </Tooltip>
          
          }

          

          <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Add a Logo</h4>
                  <p>Add a logo to your listing. Listings with logos are more eye catching to our readers and reinforce your brand.</p>
                  
                </CardHeader>
                <CardBody>
                <GridContainer>
                   
                    <GridItem xs={12} sm={12} md={6}>
                 
    
                    <CustomInput
                      labelText="Logo"
                      id="organization-name"
                      error={errors.username}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                          type: "file",
                          onChange: this.onChangeHandler,
                          // labelText: "Choose Logo",
                        // disabled: true,
                      //   required: true,
                      //   defaultValue: this.state.profile.organization,
                        name: "organization-name"
                      }}
                    />
                        
                    <small>
                        
                    180 px X 180 px (same size as facebook organization profile image)
                        </small></GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <p>
                        $79 / a year (annual subscription available)
                        </p><Button  color="warning"  onClick={()=>{this.viewExample("logo listing")}}>
                    View an Example
                  </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                     
    </GridItem>
    
<div id="canvas">
  {this.state.logo.url !== "" &&
  <div>
  <img src={REACT_APP_SERVER_URL+this.state.logo.url} />
  <p>
  <Button disabled={this.state.logo.added ? true: false}onClick={()=>{this.addToCart('logo')}} color="success">{this.state.logo.added ? "Added" : "Add to Cart"}</Button>
  <Button onClick={()=>{this.removeLogo()}} color="danger">Delete</Button>
  </p>
  </div>
    }
    </div>
                  </GridContainer>
                  </CardBody>
                  <CardFooter>
        </CardFooter>
                  </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>

          


          <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Sticky Listing</h4>
                  <p>Have your listing display first on search results by zip codes.</p>
                  
                </CardHeader>
                <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                   
                    <CustomInput
                      labelText="Postal Codes"
                      id="postal_codes"
                      error={errors.username}
                      formControlProps={{
                        fullWidth: true
                      }}
                       
                      inputProps={{
                        onKeyPress: (ev) => {
    if (ev.key === 'Enter') {
      this.addPostalCode()
    }
  },

                        //   type: "file",
                        // onChange: ,
                        // disabled: true,
                      //   required: true,
                      //   defaultValue: this.state.profile.organization,
                        name: "postal_codes"
                      }}
                    />
                    <Button color="success" onClick={()=>this.addPostalCode()}> Add Postal Code</Button>
                    <div>
                    {postal_out}
                    </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
<p>                    $29 / month per zip code. This will only be available to one company per zip code on a first-come, first-serve basis. You may purchase this service for multiple zip codes.</p> <Button color="warning" onClick={()=>{this.viewExample("sticky listing")}}>
                    View an Example
                  </Button>

                    </GridItem>
                  </GridContainer>
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                  </Card>
          </GridItem>
          
          <GridItem xs={12} sm={12} md={8}>
          {(this.state.cart.length > 0 || this.state.cart_postal > 0 || this.state.logo.added) &&
          // <Button size={"large"} onClick={()=>this.checkOut()} color={"success"}> Check-out {"  "} <ShoppingCart />
          // </Button>
          <Tooltip
                                                     id="tooltip-top"
                                                     title="Checkout"
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton style={{
    float: "right",
    marginBottom: "20px"}}aria-label="Help" className={classes.tableActionButton} onClick={()=>this.checkOut()}>
          <ShoppingCart color="green" className={classes.tableActionButtonIcon + " " + classes.edit} />
          </IconButton>
                                                  </Tooltip>
          }

          


          <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Banner Ad</h4>
                  <p>Purchase above-the-fold banner advertising on either Earth911.com or on our Recycling Database.</p>
                  
                </CardHeader>
                <CardBody>
                <GridContainer>
               
                    <GridItem xs={12} sm={12} md={6}>
     
                    <CustomInput
                      labelText="Logo"
                      id="organization-name"
                      error={errors.username}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                          type: "file",
                          onChange: this.onChangeHandler2,
                        // disabled: true,
                      //   required: true,
                      //   defaultValue: this.state.profile.organization,
                        name: "organization-name"
                      }}
                    />
                    <small>                        
                    300 px X 250 px or 728 x 90
                        </small>     </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <FormControl component="fieldset">
  <FormLabel component="legend">Size of Banner</FormLabel>
  <RadioGroup aria-label="size" name="size" value={this.state.ad_size} onChange={this.handleChange}>
    <FormControlLabel value="300" control={<Radio />} label="300 x 250" />
    <FormControlLabel value="728" control={<Radio />} label="728 x 90" />
  </RadioGroup>
</FormControl>
                        <p>$10 per 1,000 impressions per month. Minimum purchase $100 for 10k impressions for one month. Max purchase $1,000 per month for 100k impressions. Users can contact us for larger campaigns at <a href="mailto:advertising@earth911.com">advertising@earth911.com</a>. Users may select 1 - 12 month campaigns. Users can select campaigns to be run either on Earth911.com only, Recycling Database Only, or both. Users will need to upload an image to the exact banner sizes as well as the URL of the advertising destination. Campaigns will start the month immediately after purchase, if your purchase is made by the 20th day of the month.</p>   <Button  color="warning" onClick={()=>{this.viewExample("ad banner")}}>
                    View an Example
                  </Button>       
                    </GridItem>
                       
                <GridItem xs={12} sm={12} md={12}>
                     
<div id="canvas2">
  {ads_out.length > 0 &&
  ads_out
                    }
    </div>
    </GridItem>
                  </GridContainer>
                  </CardBody>
                  <CardFooter>
                 </CardFooter>
                  </Card>
                  {(this.state.cart.length > 0 || this.state.cart_postal > 0 || this.state.logo.added) &&
          // <Button size={"large"} onClick={()=>this.checkOut()} color={"success"}> Check-out {"  "} <ShoppingCart />
          // </Button>
          
          <Tooltip
                                                     id="tooltip-top"
                                                     title="Checkout"
                                                    placement="top"
                                                    classes={{tooltip:classes.tooltip}}>
                                                    <IconButton aria-label="Help" className={classes.tableActionButton} onClick={()=>this.checkOut()}>
          <ShoppingCart className={classes.tableActionButtonIcon + " " + classes.edit} />
          </IconButton>
                                                  </Tooltip>
          }
          </GridItem>
        </GridContainer>
    }
      </div>
    );
  }
}

Visbility.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(Visbility);
