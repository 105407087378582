import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import scriptLoader from 'react-async-script-loader';

import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"

const { REACT_APP_SERVER_URL } = process.env;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
  }
  componentDidMount() {
    const {
      isScriptLoaded,
      isScriptLoadSucceed
    } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  
  componentWillReceiveProps(nextProps) {
    const {
      isScriptLoaded,
      isScriptLoadSucceed,
    } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton &&
      !this.props.isScriptLoaded &&
      isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }
  render() {
    const {
      total,
      currency,
      env,
      commit,
      client,
      onSuccess,
      onError,
      onCancel,
    } = this.props;
    const {
      showButton,
    } = this.state;
    const paypal = window.paypal
    const payment = async (data, actions) =>{

      const { history } = this.props;
      
      if(!apiSetupHeaders()){
        return history.push("/auth/login-page");
    }
      
      let taskRequest;
      try {
        taskRequest = await axios.post(
          `${REACT_APP_SERVER_URL}/v1/payment`,
          {
            cart: this.props.cart
          },
          {
          //   withCredentials: true
          }
        );
      } catch ({ response }) {
        taskRequest = response;
      }
  
      const { data: taskRequestData } = taskRequest;
      console.log(taskRequestData.id)
      return taskRequestData.id
    }
    // actions.request.post(`${REACT_APP_SERVER_URL}/v1/payment`)
    // .then(function(res) {
    //   // 3. Return res.id from the response
    //   return res.id;
    // });
    // paypal.rest.payment.create(env, client, {
    //   transactions: [
    //     {
    //       amount: {
    //         total,
    //         currency,
    //       }
    //     },
    //   ],
    // });
  const onAuthorize = async (data, actions) =>{
    
          const { history } = this.props;
          
          if(!apiSetupHeaders()){
            return history.push("/auth/login-page");
        }
          
          let taskRequest;
          try {
            taskRequest = await axios.post(
              `${REACT_APP_SERVER_URL}/v1/payment/proccess`,
              {
                
          pr_id: data.payerID,
          pm_id: data.paymentID,
              },
              {
              //   withCredentials: true
              }
            );
          } catch ({ response }) {
            taskRequest = response;
          }
      
          const { data: taskRequestData } = taskRequest;
          console.log(taskRequestData)
          this.props.onSuccess(taskRequestData)
          // return taskRequestData.id
        }
  
  // (data, actions) =>
  //   actions.payment.execute()
  //     .then(() => {
  //       const payment = {
  //         paid: true,
  //         cancelled: false,
  //         payerID: data.payerID,
  //         paymentID: data.paymentID,
  //         paymentToken: data.paymentToken,
  //         returnUrl: data.returnUrl,
  //       };
  //       onSuccess(payment);
  //     });

    return (
      <div>
        {showButton && <paypal.Button.react
          env={env}
          client={client}
          commit={commit}
          payment={payment}
          onAuthorize={onAuthorize}
          onCancel={onCancel}
          onError={onError}
        />}
      </div>
    );
  }
}
export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);