import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import MUIPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import UpdateMaterials from 'components/Materials/UpdateMaterials'
import ValidateListing from 'components/Listings/ValidateListing'
import LinearProgress from '@material-ui/core/LinearProgress';


import {DebounceInput} from 'react-debounce-input';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// import DateFnsUtils from '@date-io/date-fns';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';


import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Send from "@material-ui/icons/Send";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Help from "@material-ui/icons/Help";
import Undo from "@material-ui/icons/Undo";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import OpenInNew from "@material-ui/icons/OpenInNew";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"




// import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import AddAlert from '@material-ui/icons/AddAlert';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat:  props.defaultCenter.lat, lng:  props.defaultCenter.lng }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: props.marker.lat, lng:  props.marker.lng }} />
    </GoogleMap>
  ))
);
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class AddListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:{contact:false, materials_search: false},
      address_payload: {},
      addingAnother: false,
      requestListingAddition: true,
      accepts: [],
      search:'',
      listing_hours_data: [],
     
        currentlySelecting: "",
        listing_phone: "",
        listing_hours: "",
        listing_url: "",
      childComponent: false,
      viewingMaterials: '',
      validatingListing: '',
      // materialsSelection: "yes", //yes, options ** DEVELPOPMERNT **
      materialsSelection: "no", //yes, options
      families: [],
      family: [],
      listings: [],
      listing: [],
      contact_listings:[],
      // listingSelect: ''
      suggestedPlace: [],
      formattedAddress:{},
      last_updated: {listing: {}, updated_url: ''},
      updateState: {current: "viewing_all", listing: {
        current: {},
        all: [],
        update_all: true,
        updated_all: false,

      }},
      listings_count: {new_total:0},
      errors: {},
      locations: [],
      edit_locations: [],
      location_updates: [],
      location_update_action: [],
      programs: [],
      edit_programs: [],
      program_updates: [],
      program_update_action: [],
      current_task: {completed: true},
      all_listings_completed: false,
    //   adding_location: false,
      contactListingSelect: "",
      listingSelect: "",
      locationSelect: "",
      programSelect: "",
      listing_name: "",
      enterAddressManually: false,
      new_url: '',
      open: false, suggestion: null ,
      selectedDate: new Date('2014-08-18T21:11:54'), 
      nothing_found: false,
      // setSelectedDate,
    //   !this.state.tasks.contacts[0].completed
      tasks: {listings: [
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
        {completed: false},
      ]},
      openDialog: false,
      completedAddress: false,
      materials: [],
      added_materials: [],
    };
    this.addUser = this.addUser.bind(this);
    this.getCurrentTaskData = this.getCurrentTaskData.bind(this);
    this.markAsValid = this.markAsValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.myRef = React.createRef()   // Create a ref object 
    // this.addProfile = this.updateProfile.bind(this);
  }
  backToAllFamilies = () =>{
    this.setState({family: []})
  }
  handleDateChange = date => {
    this.setState({selectedDate: date});
  };
  moveOnToMaterials = async (value)=>{

    if(value == "yes"){
    let address_payload = {}
    if(this.state.formattedAddress.route !== undefined){
      address_payload = this.state.formattedAddress
      address_payload.from_search = true
    }
    else{
      address_payload = this.state.address_payload
      address_payload.from_search = false
      
    }
    let payload = {address_payload: address_payload, new_hours_data: this.state.listing_hours_data, name: this.state.listing_name, phone: this.state.listing_phone, url: this.state.listing_url, notes: this.state.address_notes}
    let taskRequest;
    let listing_type = "location"
    if(this.state.listingSelect == "2"){
    listing_type = "program"
    payload.program_type = this.state.programSelect
    }else{
      
    payload.location_type = this.state.locationSelect

    }


this.setState({listing_type: listing_type})

    try {
      taskRequest = await axios.post(
        
        `${REACT_APP_SERVER_URL}/v1/${listing_type}`,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        {
          listing: payload
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;     

    }
    
    const { data: taskRequestData } = taskRequest;
    this.setState({new_listing_uuid: taskRequestData.uuid})


  //   this.setState({ successAlert: true, alertMessage: "The new Listing Type has been requested. Thank you for your contribution!" })
  //   setTimeout(()=>{this.setState({ successAlert: false}) 
  //   // this.props.back({completed: false})
  // }
  //   , 5000)
  }else{
    if(value == "options"){
      console.log(this.state.added_materials)
      let accepts = []
      for(let i=0; i<this.state.added_materials.length; i++){
        accepts.push({residential: 'none', business: 'none', material_id: this.state.added_materials[i].material_id, notes: ''})

      }
      console.log(accepts)
    this.setState({accepts: accepts})
    }else{
      //value == "finalize"
      if(this.state.accepts.length > 0){
        
    let listing_type = "location"
    if(this.state.listingSelect == "2")
    listing_type = "program"
      let taskRequest;
      try {
        taskRequest = await axios.post(
          `${REACT_APP_SERVER_URL}/v1/materials/${listing_type}/`+ this.state.new_listing_uuid,
          {
            // new_listing: true,
            updates:  this.state.accepts
          },
          {
          //   withCredentials: true
          }
        );
      } catch ({ response }) {
        taskRequest = response;
      }
      // let tasks = this.state.tasks
      // console.log(taskRequest.data)
      // this.backToListings(true)
  
      // let updateState = this.state.updateState
      // location = updateState.listing
      // location.updated_all = true
      // // location.url = formValues.new_url
      
      // this.setState({updateState: updateState})
  
      // tasks['contact'][task_id-1].completed = true;
      // console.log(tasks)
      // // console.log(this.state.tasks)
      // this.setState({tasks: tasks})
      
      apiStoreHeaders(taskRequest.headers)      
      }
    }
  }
    document.getElementById('topGo').scrollIntoView();    
    this.setState({materialsSelection: value, addingAnother: true})

  }
  resetHours = (open) => {
    this.setState({
    listing_hours_data: 
    [
      {
        index: 0,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Sunday"
      },
      {
        index: 1,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Monday"
      },
      {
        index: 2,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Tuesday"
      },
      {
        index: 3,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Wednesday"
      },
      {
        index: 4,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Thursday"
      },
      {
        index: 5,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Friday"
      },
      {
        index: 6,
        start_hour:'',
        start_minute:'',
        close_hour:'',
        close_minute:'',
        open: open,
        day: "Saturday"
      }
  ]})

  }
      
  destroyListing= async () =>{
    let listing = {uuid: this.state.new_listing_uuid, type: this.state.listing_type}
    
    let taskRequest;
    try {
      taskRequest = await axios.delete(
        
        `${REACT_APP_SERVER_URL}/v1/${listing.type}/${listing.uuid}`,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        {
          // listing: payload
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;     

    }
    
    const { data: taskRequestData } = taskRequest;
    this.markTaskAsValid()
    // if(this.props.task){

    // }
    // console.log(listing)
    // this.setState({
    //   childComponent: true,
    //   validatingListing:
    // <ValidateListing
    //   listing={listing}
    //   back={this.props.task ? this.props.back : this.markTaskAsValid}
    //  />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
      
  validateListing=() =>{
    let listing = {uuid: this.state.new_listing_uuid, type: this.state.listing_type}
    // console.log(listing)
    this.setState({
      childComponent: true,
      validatingListing:
    <ValidateListing
      listing={listing}
      back={this.props.task ? this.markTaskAsValid : this.props.back}
     />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
 
//  handleClose = () => {
//     onClose(selectedValue);
//   };

//  handleListItemClick = value => {
//     onClose(value);
//   };
addMaterial=(material)=>{
  console.log(material)

  // material.disabled = true
  let removedMaterial = this.state.materials
  removedMaterial.splice(material.index, 1)
  let materials = this.state.added_materials
  materials.push(material)
  material.index = materials.length - 1
  this.setState({added_materials: materials, materials: removedMaterial})
}


addAll=()=>{
  // console.log(material)

  // material.disabled = true
  let removedMaterial = this.state.materials
  // removedMaterial.splice(material.index, 1)
  let materials = this.state.added_materials
  for(let i=0; i<removedMaterial.length; i++)
  {
    materials.push(removedMaterial[i])
    removedMaterial.index = materials.length - 1
  }
  this.setState({added_materials: materials, materials: [], listingContactSelect: 0})
}

removeMaterial=(material)=>{
  console.log(material)
  // material.disabled = true
  let removedMaterial = this.state.added_materials
  removedMaterial.splice(material.index, 1)
  for(let i=material.index; i<removedMaterial.length; i++)
  removedMaterial[i].index = removedMaterial[i].index - 1
  let materials = this.state.materials
  materials.push(material)
  this.setState({added_materials: removedMaterial, materials: materials})
}
  onClose=() =>{
    this.setState({ open: false })
  }
  enterAddressManually=() =>{
    // let search = document.getElementById('mui-places-autocomplete-input')
    // search.value = ""
    // console.log(document.getElementById('mui-places-autocomplete-input'), "reset")
   this.resetHours(true)
    // if(!this.state.enterAddressManually)
    let search = ""
    if(this.state.enterAddressManually)
    search = <MUIPlacesAutocomplete
    textFieldProps={{ placeholder: "Search for the place via Google..", fullWidth: true }}    
    placeholder="Search for the place via Google.."
onSuggestionSelected={this.onSuggestionSelected}
renderTarget={() => (<div />)}
/>
    this.setState({ search: search, enterAddressManually: !this.state.enterAddressManually, suggestedPlace: [], formattedAddress: {}, suggestion: null, listing_url: '', listing_phone: ''})
  }
  onSuggestionSelected=(suggestion) =>{
    // Add your business logic here. In this case we just log...
    this.setState({ open: true, suggestion })
    setTimeout(()=>this.setState({ open: false }), 5000)
    let request = {
      placeId: suggestion.place_id,
      fields: ['name', 'rating', 'formatted_phone_number', 'geometry', 'opening_hours', 'icon', 'url', 'website']
    };
    
    let service = new window.google.maps.places.PlacesService(document.getElementById("map"));
    service.getDetails(request, (place, status) => {
      this.resetHours(false)
      console.log(place)
      // let hours = ""
      let listing_hours_data = this.state.listing_hours_data
      if(place.opening_hours !== undefined){
      // hours = place.opening_hours.weekday_text.join(', ')
       let periods = place.opening_hours.periods
       for(let i=0; i< periods.length; i++){
         let current_period = periods[i]
        //  console.log(current_period)
         listing_hours_data[current_period.open.day].index = current_period.open.day
         listing_hours_data[current_period.open.day].open = true
         listing_hours_data[current_period.open.day].start_hour = current_period.open.hours < 10 ? "0" + current_period.open.hours : current_period.open.hours
         listing_hours_data[current_period.open.day].start_minute = current_period.open.minutes < 10 ? "0" + current_period.open.minutes : current_period.open.minutes
         listing_hours_data[current_period.close.day].close_hour = current_period.close.hours < 10 ? "0" + current_period.close.hours : current_period.close.hours
         listing_hours_data[current_period.close.day].close_minute = current_period.close.minutes < 10 ? "0" + current_period.close.minutes : current_period.close.minutes
        //  console.log(listing_hours_data[current_period.open.day])
        //  console.log(listing_hours_data[current_period.close.day])
       }
      }
      
      this.setState({listing_phone: place.formatted_phone_number, listing_url: place.website, listing_hours_data: listing_hours_data})

    })
    geocodeByPlaceID(suggestion.place_id).then((results) => {

      console.log(results)

      let formattedAddress = {}
      results[0].address_components.forEach(address_component =>{
        formattedAddress[address_component.types[0]] = {s: address_component.short_name, l:address_component.long_name}

      })
      formattedAddress.coordinates = results[0].geometry.location
      this.setState({suggestedPlace: results, formattedAddress: formattedAddress})
      // Add your business logic here. In this case we simply set our state with the coordinates of
      // the selected suggestion...

      // Just use the first result in the list to get the geometry coordinates
      // const { geometry } = results[0]

      // const coordinates = {
      //   lat: geometry.location.lat(),
      //   lng: geometry.location.lng(),
      // }

      // this.setState({ coordinates })
    }).catch((err) => {
      // Handle any errors that occurred when we tried to get geospatial data for a selected
      // suggestion...
    })
    
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSimpleResidential = event => {
    // let changes = this.state.changes
    let am = this.state.accepts
    am.forEach((m)=>{
      m.residential = event.target.value      
    })
    this.setState({ [event.target.name]: event.target.value, accepts: am });
  };
  handleSimpleBusiness = event => {
    let am = this.state.accepts
    am.forEach((m)=>{
      m.business = event.target.value      
    })
    this.setState({ [event.target.name]: event.target.value, accepts: am });
  };
  handleFamily = event =>{
    let family_id = event.target.value
    this.setState({familySelect: family_id, materials:[]})
    this.getFamily(family_id)
  }
  
  handleListing = event =>{
    let listing_id = event.target.value
    this.setState({listingContactSelect: listing_id, materials:[]})
    this.getListing(listing_id)
  }
  loading = (what, value) => {
    
    let loading = this.state.loading
    console.log(loading)
    loading[what] = value;
    this.setState({loading:loading})

    console.log(this.state.loading)
  }
  handleContactListing = event =>{
    // this.loading('contact', true)
    this.resetHours(false)
    let listing_id = event.target.value
    let contact_hours = ""
    let contact_phone = ""
    let contact_url = ""
    let target = ''
    // console.log(listing_id)
    // console.log(this.state.contact_listings)
    for(let i = 0; i<this.state.contact_listings.length; i++){
        // console.log(this.state.contact_listings[i].name == listing_id)
        // console.log(this.state.contact_listings[i].name)
        // console.log(listing_id)
        if(this.state.contact_listings[i].name == listing_id){
          // this.resetHours(false)
    // this.setState({listing_hours_data: []})
    // this.setState({listing_hours_data: []})
          target = this.state.contact_listings[i]
            contact_hours = target.contact_info.hours
            contact_phone = target.contact_info.phone
            if(target.contact_info.update_status > 1)
            contact_url = target.contact_info.updated
            else
            contact_url = target.contact_info.url    
            break;        
        }
    }
    let payload = {contactListingSelect: listing_id, listing_hours: contact_hours, listing_url: contact_url, listing_phone: contact_phone}
    // console.log(payload)
    this.setState(payload)
    
    if(target.contact_info.hours_payload.length > 0){
      setTimeout(()=>{this.setState({listing_hours_data: target.contact_info.hours_payload})
      this.loading('contact', false)}, 1000)
    }else{
      if(target.contact_info.hours_payload.length == 0)
        setTimeout(()=>{  this.loading('contact', false)}, 1000)
    }
    // this.getListing(listing_id)
  }
  resetLocation = (location) =>{
    
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'reset_location'

    this.setState({location_update_action: location_update_action})
  }
  resetProgram = (program) =>{
    
    let program_update_action = this.state.program_update_action
    program_update_action[program.index] = 'reset_program'

    this.setState({program_update_action: program_update_action})
  }
  checkAllLocationsCompleted = ()=>{
    let completed = true;
    console.log(this.state.locations.length)
    for(let i = 0; i<this.state.locations.length; i++){
      console.log(this.state.locations[i].updated)
    if(!this.state.locations[i].updated)
      { console.log(this.state.locations[i])
        completed = false
      break;}
    }
    if(completed)
    this.setState({all_listings_completed: true})

  }
  backToListings = (updated_all) => {
    let updateState =  {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }}
    if(updated_all){
      this.state.locations[this.state.updateState.listing.current.index].updated = true
      this.checkAllLocationsCompleted()

      
    let location_update_action = this.state.location_update_action
    if(this.state.new_url !== "mixed")
    if(this.state.updateState.listing.all.length > 1)
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all to: ' + this.state.new_url
    else
    location_update_action[this.state.updateState.listing.current.index] = 'Updated to: ' + this.state.new_url
    else{
    location_update_action[this.state.updateState.listing.current.index] = 'Updated all URLs individually'

    }

    this.setState({updateState: updateState, last_updated: {listing: this.state.updateState.listing.current, updated_url: this.state.new_url}, new_url: '', location_update_action: location_update_action})
  }

    else{
    this.setState({updateState: updateState, new_url: ''})}

  }


  async getCurrentTaskData(task){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/task/`+task.task.id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    let location_update_action = []
    for(let i = 0; i<taskRequestData.locations.length; i++){
      taskRequestData.locations[i].type = 'location'
      if(taskRequestData.locations[i].updated == 'completed')
      location_update_action[i] = 'completed'      
      else
      if(taskRequestData.locations[i].updated == 'updated_listing')
      location_update_action[i] = 'needs_materials'
      else
      location_update_action[i] = 'action_required'
      taskRequestData.locations[i].index = i
    }
    
    let program_update_action = []
    for(let i = 0; i<taskRequestData.programs.length; i++){
      taskRequestData.programs[i].type = 'program'
      if(taskRequestData.programs[i].updated == 'completed')
      program_update_action[i] = 'completed'      
      else
      if(taskRequestData.programs[i].updated == 'updated_listing')
      program_update_action[i] = 'needs_materials'
      else
      program_update_action[i] = 'action_required'
      taskRequestData.programs[i].index = i
    }
    this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action,
      programs: taskRequestData.programs, program_updates: taskRequestData.program_updates, program_update_action: program_update_action})
    
    this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  
  async getFamilies(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/families/`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({currentlySelecting: "families", families: taskRequestData.families, materials: []})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getListings(){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listings`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    let outbound = []
    for(let i = 0; i < taskRequestData.listings.programs.length; i++){
      taskRequestData.listings.programs[i].type = "program"
      outbound.push(taskRequestData.listings.programs[i])
    }
    for(let i = 0; i < taskRequestData.listings.locations.length; i++){
      taskRequestData.listings.locations[i].type = "location"
      outbound.push(taskRequestData.listings.locations[i])
    }
    this.setState({currentlySelecting: "listings", listings: outbound, materials: []})
    apiStoreHeaders(taskRequest.headers)

  }
  async getContactListings(){
    const { history } = this.props;
    if(!apiSetupHeaders()){
        return history.push("/auth/login-page");
    }
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/organization/contact_listings`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({contact_listings: taskRequestData.listings})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getFamily(family_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/family/`+family_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({materials: taskRequestData.family})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  async getListing(listing_id){
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listing/`+listing_id,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    this.setState({materials: taskRequestData.listing})
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   if(taskRequestData.locations[i].updated)
    //   location_update_action[i] = 'This listing(s) has been completed.'      
    //   else
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    
    // this.checkAllLocationsCompleted()
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

  }
  setAllListingsToURL = async ()=>{
    
    // const fields = ["new_url"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+this.state.updateState.listing.current.UUID,
        {
          location:  {new_url: this.state.new_url, all: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)
    this.backToListings(true)

    // let updateState = this.state.updateState
    // location = updateState.listing
    // location.updated_all = true
    // // location.url = formValues.new_url
    
    // this.setState({updateState: updateState})

    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  updateAllIndividually = (value)=>{
    let updateState = this.state.updateState
    updateState.listing.update_all = value;
    this.setState({updateState: updateState})
    
    // const formElements = e.target.elements;
    // console.log(formElements.namedItem("new_url").value)
  }
  componentDidMount(){
    
   
    let listings_count = JSON.parse(localStorage.getItem('listings_count'));


    this.resetHours(false)
    document.getElementById('topAppBar').scrollIntoView();
    // this.getProfileData()
    let tasks = JSON.parse(localStorage.getItem('tasks'))
    this.setState({listings_count: listings_count, tasks: tasks, search: <MUIPlacesAutocomplete
      textFieldProps={{ placeholder: "Search for the place via Google..", fullWidth: true }}
      
      placeholder="Search for the place via Google.."
onSuggestionSelected={this.onSuggestionSelected}
renderTarget={() => (<div />)}
/>})
    
    let listing_task = tasks['listings']
    console.log(listing_task)

    for(let i=0; i<listing_task.length; i++){
     if(!listing_task[i].completed){
        this.setState({current_task: listing_task[i]})
        // console.log("set current to", listing_task[i])
        // console.log(this.state)
        if(listing_task[i].task.id == 9){
          this.setState({adding_location: true})
        }else{
        this.getCurrentTaskData(listing_task[i]);
        }
        break;
     }
    }

  }
  componentDidUpdate(prevProps, prevState, snapshot){
    // if(this.props.last_updated !== prevProps.last_updated && this.props.last_updated.listing.index !== null){
      
    // let location_update_action = this.state.location_update_action
    // location_update_action[this.props.last_updated.listing.index] = 'Updated all to: ' + this.props.last_updated.new_url
    // this.setState({location_update_action: location_update_action})
    // }

  }
  handleClose = () => {

    this.setState({openDialog: !this.state.openDialog})

    // setOpen(false);
    // setSelectedValue(value);
  };
  handleMaterialChange = (event) => {
    
    
        // let name = event.target.name
        // console.log(name)
        // console.log(event.target.value)
        let accepts = this.state.accepts
        let target = event.target.name.split('_')
        
        let changes = this.state.changes
        if(target[0] == "residential"){
          for(let i=0; i<accepts.length; i++){
          if(accepts[i].material_id == target[1]){
          accepts[i].residential = event.target.value
          // changes.existing.push({material_id: accepts[i].material_id, residential: event.target.value})
        }}
        }else{
    
          if(target[0] == "business"){
          for(let i =0; i<accepts.length; i++){
          if(accepts[i].material_id == target[1]){
          accepts[i].business = event.target.value
          
          // changes.existing.push({material_id: accepts[i].material_id, business: event.target.value})
        }}
          }else{
            for(let i=0; i<accepts.length; i++){
            if(accepts[i].material_id == target[1]){
            accepts[i].notes = event.target.value
            // changes.existing.push({material_id: accepts[i].material_id, notes: event.target.value})
          }}
          }
        }
        this.setState({ accepts: accepts});
      }
    
  handleChange = (event) => {
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    this.setState({[event.target.name]: event.target.value});
  }

  handleChangeAddress = (event) => {
    let ap = this.state.address_payload
    let name = event.target.name
    ap[name] = event.target.value
    // console.log(name)
    // console.log(event.target.value)
    this.setState({address_payload: ap});
  }

  searchMaterials = async (event) => {
    // console.log(event)
    // event.preventDefault();
    
    const { history } = this.props;
    if(!apiSetupHeaders()){
        return history.push("/auth/login-page");
    }
    this.loading("materials_search", true)
    this.setState({ currentlySelecting: "searching", materials: [], families: []})
    let taskRequest;
    try {
      taskRequest = await axios.get(
        
        `${REACT_APP_SERVER_URL}/v1/materials?q=`+event.target.value,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        // {
          // url: location.url
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    console.log(taskRequestData)
    let nothing_found = false
    if(taskRequestData.materials.length == 0)
    nothing_found = true
    this.setState({materials: taskRequestData.materials, nothing_found: nothing_found})
    this.loading("materials_search", false)
    // let name = event.target.name
    // console.log(name)
    // console.log(event.target.value)
    // this.setState({[event.target.name]: 
    
      
      // https://search.earth911.com/suggest/materials/?q=%231
  }
  updateEmail = async(e) => {
    e.preventDefault();
    const { history } = this.props;

    const fields = ["new_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/profile/change_email`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    if(registerRequest.status > 205){
        console.log(registerRequestData) // do something here
    }else{
        
        apiStoreHeaders(registerRequest.headers)
        return history.push("/update/earth911");
        
    }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  async addUser(e) {
    e.preventDefault();

    const fields = ["first_name", "last_name", "added_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/profile/add_user`,
        {
          profile: formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;

    if (!registerRequestData.success) {
      this.setState({
        errors:
          registerRequestData.messages && registerRequestData.messages.errors
      });
    }
  }
  addAnotherListing = async () => {
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));
    if(this.props.task){
    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/task`,
        {
          task: {category: 'listing', id: 9, completed: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
  }

    this.resetAddListing()


  }
  resetAddListing = () =>{
    this.resetHours(false)
    document.getElementById('topAppBar').scrollIntoView();
    // this.props.task = false
    this.setState({requestListingAddition: true,
      addingAnother: true,
      accepts: [],
      listing_hours_data: [],
     
        currentlySelecting: "",
        listing_phone: "",
        listing_hours: "",
        listing_url: "",
      childComponent: false,
      viewingMaterials: '',
      validatingListing: '',
      // materialsSelection: "finalize", //yes, options ** DEVELPOPMERNT **
      materialsSelection: "no", //yes, options
      families: [],
      family: [],
      listings: [],
      listing: [],
      contact_listings:[],
      // listingSelect: ''
      suggestedPlace: [],
      formattedAddress:{},
      last_updated: {listing: {}, updated_url: ''},
      updateState: {current: "viewing_all", listing: {
        current: {},
        all: [],
        update_all: true,
        updated_all: false,

      }},
      errors: {},
      locations: [],
      edit_locations: [],
      location_updates: [],
      location_update_action: [],
      programs: [],
      edit_programs: [],
      program_updates: [],
      program_update_action: [],
      // current_task: {completed: true},
      all_listings_completed: false,
    //   adding_location: false,
      contactListingSelect: "",
      listingContactSelect: "",
      locationSelect: "",
      programSelect: "",
      listing_name: "",
      enterAddressManually: false,
      new_url: '',
      open: false, suggestion: null ,
      selectedDate: new Date('2014-08-18T21:11:54'), 
      nothing_found: false,
      // setSelectedDate,
    //   !this.state.tasks.contacts[0].completed
      // tasks: {listings: [
      //   {completed: false},
      //   {completed: false},
      //   {completed: false},
      //   {completed: false},
      //   {completed: false},
      //   {completed: false},
      // ]},
      openDialog: false,
      completedAddress: false,
      materials: [],
      added_materials: [],
      search: <MUIPlacesAutocomplete
      textFieldProps={{ placeholder: "Search for the place via Google..", fullWidth: true }}
      
      placeholder="Search for the place via Google.."
onSuggestionSelected={this.onSuggestionSelected}
renderTarget={() => (<div />)}
/>})
  }
   markTaskAsValid = async () => {
    // e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));
    if(this.props.task){
    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/task`,
        {
          task: {category: 'listing', id: 9, completed: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    }

    // console.log(taskRequest)
    
    // const { data: taskRequestData } = taskRequest;

    // const { history } = this.props;  
    // history.push("/update/earth911");
    this.props.back()
    // console.log(taskRequestData)


    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  resetAllData = () => {
    this.setState({
    last_updated: {listing: {}, updated_url: ''},
    updateState: {current: "viewing_all", listing: {
      current: {},
      all: [],
      update_all: true,
      updated_all: false,

    }},
    locations: [],
    edit_locations: [],
    location_updates: [],
    location_update_action: [],
    all_listings_completed: false,    
    new_url: ''})
  }
  updateLocation = async (e, location) => {
    
    const fields = ["new_url"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));
    e.preventDefault();

    // const fields = ["first_name", "last_name", "added_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.UUID,
        {
          location:  formValues
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)

    let updateState = this.state.updateState
    location = updateState.listing.all[location.index]
    location.updated = true
    location.url = formValues.new_url
    
    this.setState({updateState: updateState})

    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)
    let completed = true
    for(let i=0; i<updateState.listing.all.length; i++)
    {
      if(updateState.listing.all[i].updated == false)
        completed = false
    }
    if(completed){
      if(updateState.listing.all.length == 1)
      this.setState(formValues)
      else
      this.setState({new_url: "mixed"})
      
      this.backToListings(true)

    }
    // const { data: taskRequestData } = taskRequest;

    // if (!taskRequestData.success) {
    //   this.setState({
    //     errors:
    //       taskRequestData.messages && taskRequestData.messages.errors
    //   });
    // }
  }
  markAsValid = async (location) => {
    // console.log("marked as valid", location, index)
    let location_update_action = this.state.location_update_action
    location_update_action[location.index] = 'Marked as Valid (no further updates to listings will be made)'
    let locations = this.state.locations
    locations[location.index].updated = true
    this.setState({location_update_action: location_update_action, locations: locations})
    
    let taskRequest;
    try {
      taskRequest = await axios.put(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.UUID,
        {
          location:  {markAsValid: true}
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    // let tasks = this.state.tasks
    console.log(taskRequest.data)
    apiStoreHeaders(taskRequest.headers)
    this.checkAllLocationsCompleted()

  }
  updateURL = async (location) => {
    // console.log("marked as valid", location, index)
    // let location_update_action = this.state.location_update_action
    // location_update_action[location.index] = 'Update'
    // this.setState({location_update_action: location_update_action})
    let updateState = this.state.updateState
    updateState.current = 'editing'
    updateState.listing.current = location
    console.log(location)
    if(location.effects_count == "1")
    {
      
      updateState.listing.update_all = false
      console.log(updateState)
      
      // last_updated: {listing: {}, updated_url: ''},
      // updateState: {current: "viewing_all", listing: {
      //   current: {},
      //   all: [],
      //   update_all: true,
      //   updated_all: false,

      // }},


    }
    

    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/locations/`+location.UUID,
        // {
          // url: location.url
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    console.log(taskRequestData)
    // let location_update_action = []
    // for(let i = 0; i<taskRequestData.locations.length; i++){
    //   location_update_action[i] = 'action_required'
    //   taskRequestData.locations[i].index = i
    // }
    // this.setState({locations: taskRequestData.locations, location_updates: taskRequestData.location_updates, location_update_action: location_update_action})
    // let tasks = this.state.tasks
    // console.log(tasks)
    // tasks['contact'][task_id-1].completed = true;
    // console.log(tasks)
    // console.log(this.state.tasks)
    // this.setState({tasks: tasks})
    
    apiStoreHeaders(taskRequest.headers)

    // const { data: taskRequestData } = taskRequest;
    // let location_update_action = []
    for(let i = 0; i<taskRequestData.locations.length; i++){
      // location_update_action[i] = 'action_required'
      taskRequestData.locations[i].index = i
      taskRequestData.locations[i].updated = false
    }

    updateState.listing.all = taskRequestData.locations
    this.setState({updateState: updateState})


  }
  viewMaterials=(listing) =>{
    this.setState({
      childComponent: true,
      viewingMaterials:
    <UpdateMaterials
      task={false}
      listing={listing}
      back={this.backFromUpdatingMaterials}
     />})
    
    // const { history } = this.props;
    // history.push('/materials/'+location.location_id)

  }
  backFromUpdatingMaterials = (payload) =>{
    if(payload.completed){
    console.log(payload)
    document.getElementById('topGo').scrollIntoView();    
    let lua = ''
    let listing_type = payload.listing.type
    if(listing_type == "location")
    lua = this.state.location_update_action
    else
    lua = this.state.program_update_action
    if(payload.completed){
      lua[payload.listing.index] = payload.completed

    }
    if(listing_type == "location")
    this.setState({childComponent: false, viewingMaterials: "", location_update_action: lua})
    else
    this.setState({childComponent: false, viewingMaterials: "", program_update_action: lua})
  
  }else{
    this.setState({childComponent: false, validatingMaterials: ""})
  }
  }
  
  getProfileData = async e => {
    apiSetupHeaders()
    // e.preventDefault();

    // const { history } = this.props;

    // // const fields = ["email", "password"];
    // // const formElements = e.target.elements;

    // // const formValues = fields
    // //   .map(field => ({
    //     // [field]: formElements.namedItem(field).value
    // //   }))
    // //   .reduce((current, next) => ({ ...current, ...next }));

    // if(!apiSetupHeaders()){
    //     return history.push("/auth/login-page");
    // }

    let profileRequest;
    
    try {
      profileRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/profile`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    if (profileRequest.status == 200) {
        // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        this.setState({profile: profileRequestData.profile})
    }else{
        // return history.push("/auth/login-page");
    }
    this.setState({
    //   errors: loginRequestData.messages && loginRequestData.messages.errors
    });
  };
  isOpen = (hours)=>{
    console.log(hours)
    let lhd = this.state.listing_hours_data
    hours.open = !hours.open
    lhd[hours.index] = hours
    this.setState({listing_hours_data: lhd})
    console.log(lhd)

  }
  updateTime = (event) =>{
    // console.log(event.target)
    // console.log(event.target.name)
    // console.log(event.target.value)

    let name = event.target.name
    let day_index = name.split('_')[0]
    let day_type = name.split('_')[1] //start or end
  
    
    let value = event.target.value
    let hour = value.split(':')[0]
    let minute = value.split(':')[1]

    let lhd = this.state.listing_hours_data

    if(day_type == "open")
    {          
      lhd[day_index].start_hour = hour
      lhd[day_index].start_minute = minute
    }
    else
    {
      lhd[day_index].close_hour = hour
      lhd[day_index].close_minute = minute
    }
    this.setState({listing_hours_data: lhd})


  }
  
  requestMaterial = async () =>{
    let mn = document.getElementById('material-name').value
    let md = document.getElementById('material-description').value
    
    this.setState({requestMaterialAddition: false})
    let payload = {material:{description: md, name:mn}}
    let taskRequest;
    try {
      taskRequest = await axios.post(
        
        `${REACT_APP_SERVER_URL}/v1/material`,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        {
          payload
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    this.setState({ successAlert: true, alertMessage: "The new material request is being processed. Thank you." })
    setTimeout(()=>{this.setState({ successAlert: false}) 
    // this.props.back({completed: false})
  }
    , 3000)

    // const { data: taskRequestData } = taskRequest;
    // console.log(taskRequestData)
    // let nothing_found = false
    // if(taskRequestData.materials.length == 0)
    // nothing_found = true
    // this.setState({materials: taskRequestData.materials, nothing_found: nothing_found})
  }
  requestListing = async (program) =>{
    let mn = ''
    let md = ''
    if(program){
    mn = document.getElementById('listing-name-program').value
    md = document.getElementById('listing-description-program').value
    }else{      
    mn = document.getElementById('listing-name-location').value
    md = document.getElementById('listing-description-location').value
    }
    
    this.setState({requestListingAddition: false})
    let payload = {listing:{description: md, name:mn, program: program}}
    let taskRequest;
    try {
      taskRequest = await axios.post(
        
        `${REACT_APP_SERVER_URL}/v1/listing`,
        // `https://search.earth911.com/suggest/materials/?q=`+event.target.value,
        {
          payload
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }
    this.setState({ successAlert: true, alertMessage: "The new Listing Type has been requested. Thank you for your contribution!" })
    setTimeout(()=>{this.setState({ successAlert: false}) 
    // this.props.back({completed: false})
  }
    , 5000)

    // const { data: taskRequestData } = taskRequest;
    // console.log(taskRequestData)
    // let nothing_found = false
    // if(taskRequestData.materials.length == 0)
    // nothing_found = true
    // this.setState({materials: taskRequestData.materials, nothing_found: nothing_found})
  }
  setToPreviousDay = (hours) =>{
    let current_hours = this.state.listing_hours_data;
    let current_index = hours.index
    let prev_index = 6
    if(current_index !== 0)
    prev_index = current_index - 1


    current_hours[hours.index].start_hour = current_hours[prev_index].start_hour 
    current_hours[hours.index].close_hour = current_hours[prev_index].close_hour 
    current_hours[hours.index].start_minute = current_hours[prev_index].start_minute 
    current_hours[hours.index].close_minute = current_hours[prev_index].close_minute 
    current_hours[hours.index].open = false
    this.setState({listing_hours_data: current_hours})
    setTimeout(()=>{
    let current_hours = this.state.listing_hours_data;
    current_hours[hours.index].open = true
    this.setState({listing_hours_data: current_hours})
    },1000)


  }
  render() {
    const { classes, name, email } = this.props;
    const { errors, locationSelect, programSelect, listingSelect,  open, suggestion, formattedAddress, selectedDate } = this.state
    let hours = ''
    if(this.state.listing_hours_data.length > 0)
    hours = this.state.listing_hours_data.map((hours)=>{
        console.log(hours)
        let day_start = hours.start_hour + ":" +hours.start_minute
        let day_end = hours.close_hour + ":" +hours.close_minute

        let has_previous_day_ability = false
        let current_index = hours.index
        let prev_index = 6
        if(current_index !== 0)
        prev_index = current_index - 1
        let previous_day = this.state.listing_hours_data[prev_index]
        if(previous_day.start_hour !== "" || previous_day.close_hour !== ""){
          has_previous_day_ability = true
        }

        

      return [   
        <GridContainer>  
          <GridItem xs={12} sm={12} md={3}> 
    <FormControlLabel
        control={
          <Switch
            checked={hours.open}
            disabled={this.state.materialsSelection == "finalize" ? true: false}
            onChange={()=>this.isOpen(hours)}
            value={hours.open} 
            color="warning"
          />
        }
        label={hours.open ? "Open" : "Closed "+hours.day}
      />
    </GridItem>   
    
    <GridItem xs={12} sm={12} md={3}> 
          <Button
          simple = {true}
          disabled ={!has_previous_day_ability}
            onClick={()=>this.setToPreviousDay(hours)}
            color={has_previous_day_ability ? "success": "warning"}
          >
          Same as {previous_day.day}
          </Button>
      </GridItem> 
        {hours.open &&
        <GridItem xs={12} sm={12} md={3}> 
      <TextField
      id="time"
      label={hours.day +" Opening"}
      type="time"
      fullWidth={true}
      defaultValue={day_start}
      onChange={(e)=>this.updateTime(e)}  
      /* value={monday_start} */
      className={classes.textField}
      InputLabelProps={{
        shrink: true,        
      }}
      inputProps={{
        
        disabled: this.state.materialsSelection == "finalize" ? true: false,
        step: 300, // 5 min
        name: hours.index+"_open",
        // defaultValue: day_start,
        // onChange: (e)=>this.updateTime(e)
      }}
    />
    </GridItem>
        }
        {hours.open &&
    <GridItem xs={12} sm={12} md={3}> 
      <TextField
      id="time"
      fullWidth={true}
      label={hours.day +" Closing"}
      type="time"
      defaultValue={day_end}
      onChange={(e)=>this.updateTime(e)}  
      /* value={monday_start} */
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        disabled: this.state.materialsSelection == "finalize" ? true: false,
        step: 300, // 5 min
        name: hours.index+"_close",
      }}
    />
    </GridItem>
      }
    
    </GridContainer>
    ]
    })
    
    // let monday_start = "06:00"
    // console.log(monday_start)
    let listing_type = ""
      if(listingSelect == "1"){
        listing_type = "A location is a recycling opportunity with a permanent address that allows drop-off service at least once per month. Examples include transfer stations, retailers and 24-hour recycling bins in high-traffic areas."
      }else{
        // if(simpleSelect == "1")
      if(listingSelect == "2")
          listing_type = "A program is a recycling opportunity that does not meet all of the requirements of a location. Examples include curbside programs, mail-in programs, mobile collection opportunities and seasonal collection events."
          // else          
          // listing_type = "National recycling opportunity via mail."
      

      }
      let location_types = [
        "Commercial: Operated by a for-profit business that accepts from public.",
        "Government: Operated with city, county or regional government funding.",
        "Household Hazardous Waste: Site open at least once per month that accepts materials such as paint or batteries.",
        "Non-Profit: Operated by non-profit or community-based organization, such as a church or school.",
        "National Retail: Operated by a retailer that exists in 20 or more U.S. states.",
        "Regional Retail: Operated by a retailer that exists in less than 20 U.S. states.",
        "Unstaffed Bin: Hauler-operated bin placed in high-traffic parking lot for drop-offs of material like paper and clothing.",
        "Christmas Tree Events: Seasonal drop-off event for Christmas trees.",
        "ABOP: Short for Antifreeze, Batteries, Oil, Paint, these drop-off sites provide disposal for the recyclable forms of HHW.",
        "Location Type Not Listed: Please use the form below to suggest a new Location type.",
      ]
    let location_type = ""
    if(locationSelect !== "") 
    location_type = location_types[locationSelect]
    let program_types = [
      "Resource Contact Information: Reserved for state DEQ listings and other solid waste contact listings.",
      "Other: Reserved for programs that don’t fit another category, such as a company with drop-off bins where we’re unable to obtain the location of bins, or events until we have the event system active.	",
      "Government Packaging Curbside: A program operated with city, county or regional government funding that accepts packaging materials, such as glass, metal, paper or plastic (defaults as first listing in search results).",
      "Household Hazardous Waste: A program operated with city, county or regional government funding that collects household hazardous waste, either through a series of events or mobile collection.",
      "Mail-in: National recycling opportunity via mail (shows up for all material searches regardless of location).",
      "Commercial Curbside: Operated by a for-profit business that collects from public.",
      "Government Organics Curbside: A program operated with city, county or regional government funding that accepts organic materials, such as yard waste or food waste (defaults as first listing in search results).",
      "Government Christmas Tree Curbside: A program operated with city, county or regional government funding that accepts Christmas trees at the curb (defaults as first listing in search results).",
      "Government Bulky Waste Curbside: A program operated with city, county or regional government funding that accepts bulky waste at the curb, such as furniture, appliances or electronics (defaults as first listing in search results).",
      "Program Type Not Listed: Please use the form below to suggest a new Program type"
    ]
  let program_type = ""
  if(programSelect !== "") 
  program_type = program_types[programSelect]

    // console.log(this.state.tasks.contacts[0].completed)
    // console.log(this.state.tasks.contacts[1].completed)
    // console.log(this.state.tasks.contacts[0].completed)
    let edit_locations = [[]]
    let families = []

 

let families_select = []
    if(this.state.families.length > 0){
          console.log(this.state.families)
          let loc_index = 0
            families_select = 
            this.state.families.map((material)=>{
              if(material !== null){
              return [
              <MenuItem
      classes={{
        root: classes.selectMenuItem,
        selected: classes.selectMenuItemSelected
      }}
      value={material.family_id}
    >
      {material.description}
    </MenuItem>]
              }})}
              // let listings = []
              
               
              
              let contact_listings_select = []
              if(this.state.contact_listings.length > 0){
                    // console.log(this.state.families)
                    let loc_index = 0
                      contact_listings_select = 
                      this.state.contact_listings.map((contact_listing)=>{
                        if(contact_listing !== null){
                        return [
                        <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={contact_listing.name}
              >
                {contact_listing.name}
              </MenuItem>]
                        }})}





              let listings_select = []
                  if(this.state.listings.length > 0){
                        // console.log(this.state.families)
                        let loc_index = 0
                          listings_select = 
                          this.state.listings.map((listing)=>{
                            if(listing !== null){
                            return [
                            <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={listing.uuid}
                  >
                    {listing.description + " - " + listing.city}
                  </MenuItem>]
                            }})}

                            
    
        if(this.state.families.length > 0){
          console.log(this.state.families)
          let loc_index = 0
            families = 
            this.state.families.map((material)=>{
              // let image = ""
                  loc_index += 1
              if(material !== null){
                
              material.index = loc_index-1
               let image = "http://manage.earth911.com/media/"+material.image
                return [
                  // families.description,
                  // families.long_description
                  <Card className={classes.card} key={material}>
                  <CardActionArea>
                    <CardMedia
                      style={{width:"200px",height:"200px"}}
                      className={classes.media}
                      image={image}
                      title={material.description}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                      {material.description}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      {material.long_description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button color="warning" onClick={()=>this.getFamily(material.family_id)}>
                      View Materials
                    </Button>
                    {/* <Button size="small" color="primary">
                      Learn More
                    </Button> */}
                  </CardActions>
                </Card>
                ]
              }
                
              })
              console.log(families)
            }
            let family = []
            
                if(this.state.family.length > 0){
                  console.log(this.state.family)
                  let loc_index = 0
                    family = 
                    this.state.family.map((material)=>{
                      // let image = ""
                          loc_index += 1
                      if(material !== null){
                        
                      material.index = loc_index-1
                       let image = "http://manage.earth911.com/media/"+material.image
                        return [
                          // family.description,
                          // family.long_description
                          <Card className={classes.card} key={material}>
                          <CardActionArea>
                            <CardMedia
                              style={{width:"200px",height:"200px"}}
                              className={classes.media}
                              image={image}
                              title={material.description}
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h5" component="h2">
                              {material.description}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" component="p">
                              {material.long_description}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                            <Button color="warning" onClick={()=>this.addMaterial(material)}>
                              Add Material
                            </Button>
                            {/* <Button size="small" color="primary">
                              Learn More
                            </Button> */}
                          </CardActions>
                        </Card>
                        ]
                      }
                        
                      })
                      console.log(family)
                    }

    let materials = []

    if(this.state.materials.length > 0){
      console.log(this.state.materials)
      let loc_index = 0
        materials = 
        this.state.materials.map((material)=>{
          // let image = ""
              loc_index += 1
          if(material !== null){
            
          material.index = loc_index-1
           let image = "http://manage.earth911.com/media/"+material.image
            return [
              // materials.description,
              // materials.long_description
              <Card className={classes.card} key={material}>
              <CardActionArea>
                <CardMedia
                  style={{width:"200px",height:"200px"}}
                  className={classes.media}
                  image={image}
                  title={material.description}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                  {material.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                  {material.long_description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button color="warning" onClick={()=>this.addMaterial(material)}>
                  Add Material
                </Button>
                {/* <Button size="small" color="primary">
                  Learn More
                </Button> */}
              </CardActions>
            </Card>
            ]
          }
            
          })
          console.log(materials)
        }
        let added_materials = []
        
            if(this.state.added_materials.length > 0){
              // console.log(this.state.materials)
              let loc_index = 0
                added_materials = 
                this.state.added_materials.map((material)=>{
                  // let image = ""
                  if(material !== null){
                   let image = "http://manage.earth911.com/media/"+material.image
                      loc_index += 1
                    return [
                      // materials.description,
                      // materials.long_description
                      <Card className={classes.card} key={material}>
                      <CardActionArea>
                        <CardMedia
                          style={{width:"200px",height:"200px"}}
                          className={classes.media}
                          image={image}
                          title={material.description}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                          {material.description}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                          {material.long_description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button color="danger" onClick={()=>this.removeMaterial(material)}>
                         Remove
                        </Button>
                        {/* <Button size="small" color="primary">
                          Learn More
                        </Button> */}
                      </CardActions>
                    </Card>
                    ]
                  }
                    
                  })
                  console.log(materials)
                }


             let added_materials_table = []    
    if(this.state.added_materials.length > 0 && this.state.accepts.length > 0){
      let loc_index = 0
        added_materials_table = 
        this.state.added_materials.map((location)=>{
          let added_material_residential = "residential_"+location.material_id
          let added_material_business = "business_"+location.material_id
          let added_material_notes= "notes_"+location.material_id
          let suggestion = 'Special Notes - for example "'+location.description+' is only accepted if.."'
          let placeHolder = location.description+" is only accepted if.."
              loc_index += 1
            return [ 
              <span>
              
              {location.description}
              
              </span>,
                                              
                                <span>
                            <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup aria-label="residential option" name={added_material_residential} value={this.state.accepts[loc_index-1].residential} 
        
        disabled = {this.state.materialsSelection == "finalize" ? true: false}
        onChange={this.handleMaterialChange}>
        <FormControlLabel value="none" control={<Radio disabled = {this.state.materialsSelection == "finalize" ? true: false} />} label="None" />
          <FormControlLabel value="pickup" control={<Radio disabled = {this.state.materialsSelection == "finalize" ? true: false} />} label="Pickup" />
          <FormControlLabel value="dropoff" control={<Radio disabled = {this.state.materialsSelection == "finalize" ? true: false} />} label="Drop-off" />
          <FormControlLabel value="both" control={<Radio disabled = {this.state.materialsSelection == "finalize" ? true: false} />} label="Both" />
        </RadioGroup>
      </FormControl>
                                         
                                  </span>
                                ,
                                
                                <span>
                                <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup aria-label="business option" 
            
          disabled = {this.state.materialsSelection == "finalize" ? true: false}
        name={added_material_business} value={this.state.accepts[loc_index-1].business} onChange={this.handleMaterialChange}>
        <FormControlLabel value="none" control={<Radio disabled = {this.state.materialsSelection == "finalize" ? true: false} />} label="None" />
              <FormControlLabel value="pickup" control={<Radio disabled = {this.state.materialsSelection == "finalize" ? true: false} />} label="Pickup" />
              <FormControlLabel value="dropoff" control={<Radio disabled = {this.state.materialsSelection == "finalize" ? true: false} />} label="Drop-off" />
              <FormControlLabel value="both" control={<Radio disabled = {this.state.materialsSelection == "finalize" ? true: false} />} label="Both" />
            </RadioGroup>
          </FormControl>
                                                    
                                  </span>
                                    ,
                                    <GridItem xs={12} sm={12} md={8} lg={6}>
                                   <DebounceInput
                                   style={{width:"300px", margin: "30px"}}
                                   minLength={2}
                                   debounceTimeout={1500}
                                   element={TextField}
                                   id="outlined-multiline-static"
                                  label={suggestion}
                                  multiline
                                  rows="4"
                                  
          disabled = {this.state.materialsSelection == "finalize" ? true: false}
                                  name={added_material_notes}
                                  fullWidth={true}
                                  placeholder={placeHolder}
                                  onChange={this.handleMaterialChange}
                                  value={this.state.accepts[loc_index-1].notes}
                                  variant="outlined"
                                   /* element={CustomInput} */
                                   />
                                  </GridItem>
            ]
          })
        }    
    
    return (
      <div id="topGo">
        {this.state.validatingListing}
         <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message={this.state.alertMessage}
        open={this.state.successAlert}
        closeNotification={() => this.setState({ successAlert: false })}
        close
      />
        { !this.state.childComponent &&
        <GridContainer>     
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Adding New Listing</h4>
                <p className={classes.cardCategoryWhite}>
                  {/* New employees on 15th September, 2016 */}
                </p>
              </CardHeader>
              <CardBody>
  <GridContainer>
    
    
    <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.openDialog}>
      <DialogTitle id="simple-dialog-title">{this.state.listingSelect == "2" ? "Program" : "Location" } Types</DialogTitle>
      <List>
      {this.state.listingSelect == "1" && 
        location_types.map( location=> (
          <ListItem key={location}>
            {/* <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar> */}
            <ListItemText primary={location} />
          </ListItem>
        ))
      }
      {this.state.listingSelect == "2" && 
        program_types.map( program=> (
          <ListItem key={program}>
            {/* <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar> */}
            <ListItemText primary={program} />
          </ListItem>
        ))
      }

        {/* <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="add account" />
        </ListItem> */}
      </List>
    </Dialog>
    
          <GridItem xs={12} sm={12} md={12}>
                       {this.state.added_materials.length == 0 && this.state.materialsSelection == "yes" &&
                      
                    <Button  onClick={()=>this.moveOnToMaterials("finalize")} color="danger">
                    There are no Materials that this listing accepts.

                      </Button>
                       }
                        {this.state.added_materials.length > 0 && this.state.materialsSelection == "yes" &&
                    <Button  onClick={() => this.moveOnToMaterials("options")} color="success">
                    Move on to Material Options

                      </Button>
                     }
          {this.props.task && !this.state.addingAnother &&
          <Button simple={true} color="danger"
                  onClick={(e)=>this.markTaskAsValid()}>
                  <Check className={classes.tableActionButtonIcon + " " + classes.edit}/> I have no listings to add at this time
                  </Button>
          }
            <form onSubmit={this.updateProfile}>
            
{(this.state.materialsSelection == "no" || this.state.materialsSelection == "finalize") &&
              <div>
            <GridContainer>
                    
    
              
                    <GridItem xs={12} sm={12} md={4}>
                    {/* <legend>{this.state.simpleSelect == "" ? "Select type of Listing": listing_type} </legend> */}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Type of Listing
                          </InputLabel>
            <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.listingSelect}
                            onChange={this.handleSimple}
                            inputProps={{
                              defaultValue: this.state.listingSelect,
                              disabled: this.state.materialsSelection == "finalize" ? true: false,
                              name: "listingSelect",
                              id: "simple-select"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              Type of Listing
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="1"
                            >
                              Drop-off Location
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="2"
                            >
                              Pick-up Service / curbside collection
                            </MenuItem>
                            {/* <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="3"
                            >
                              Mail-in Program
                            </MenuItem> */}
                          </Select>
                          </FormControl>
                        </GridItem>
                        
            
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                    <legend>{this.state.listingSelect == "" ? <p>Step 1: Select type of Listing<br/><small>When choosing whether or not to add a listing as a <b>Location</b> or a <b>Program</b>, simply ask “Can I drop-off materials at this recycling opportunity at least once a month?” If the answer is yes, it is a location. If any parts of that question trigger a no (e.g. it’s pick-up only), list it as a program. Most recycling opportunities on Earth911 are locations.</small></p>: listing_type} </legend>
                    
                    </GridItem>
                    
         
                  </GridContainer>
              {this.state.listingSelect == "1" &&
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                    {/* <legend>{this.state.simpleSelect == "" ? "Select type of Listing": listing_type} </legend> */}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Type of Location
                          </InputLabel>
            <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.locationSelect}
                            onChange={this.handleSimple}
                            inputProps={{
                              
                              defaultValue: this.state.locationSelect,
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                              name: "locationSelect",
                              id: "simple-select"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                            Type of Location
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="0"
                            >Commercial
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="1"
                            >
                            
                            Government
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="2"
                            >Household Hazardous Waste
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="3"
                            >
                            Non-Profit
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="4"
                            >National Retail
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="5"
                            >Regional Retail
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="6"
                            >Unstaffed Bin
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="7"
                            >Christmas Tree Events
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="8"
                            >ABOP
                            </MenuItem>
                            
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="9"
                            >Not found - Suggest a Location type
                            </MenuItem>
                          </Select>
                          </FormControl>
                        </GridItem>
                        
            
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                            <legend>
                            {this.state.locationSelect == "" ? 
                            <p>Step 2: Select the type of Location <IconButton aria-label="Help" className={classes.tableActionButton} onClick={() => this.handleClose()}><Help className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton></p> :
                                                     location_type}
                            </legend>
                    </GridItem>
                  </GridContainer>
              }
            
              {this.state.listingSelect == "2" &&
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                    {/* <legend>{this.state.simpleSelect == "" ? "Select type of Listing": listing_type} </legend> */}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Type of Program
                          </InputLabel>
            <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.programSelect}
                            onChange={this.handleSimple}
                            inputProps={{
                              defaultValue: this.state.programSelect,
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                              name: "programSelect",
                              id: "simple-select"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              Type of Program
                            </MenuItem>
                            
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="0"
                            >
                            Resource Contact Information
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="1"
                            >
                            Other
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="2"
                            >
                            Government Packaging Curbside
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="3"
                            >
                            Household Hazardous Waste
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="4"
                            >
                            Mail-In
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="5"
                            >
                            Commercial Curbside
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="6"
                            >
                            Government Organics Curbside
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="7"
                            >
                            Government Christmas Tree Curbside
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="8"
                            >
                            Government Bulky Waste Curbside
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="9"
                            >
                            Not found - Suggest a Program type
                            </MenuItem>
                          </Select>
                          </FormControl>
                        </GridItem>
                        
            
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                    <legend>{this.state.programSelect == "" ? 
                    
                    
                    
                    <p>Step 2: Select type of Program
                            <IconButton aria-label="Help" className={classes.tableActionButton} onClick={() => this.handleClose()}><Help className={classes.tableActionButtonIcon + " " + classes.edit}/>
                                                     </IconButton></p> 
                    
                    : program_type} </legend>
                    </GridItem>
                  </GridContainer>
              }

              

{this.state.locationSelect == "9" && this.state.listingSelect == "1" && this.state.requestListingAddition &&
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4} lg={3}>
                      <CustomInput
                        labelText="Location Type Name"
                        id="listing-name-location"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // disabled: true,
                          required: true,
                          defaultValue: "",
                          name: "location-type-name"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={7}>
                      <CustomInput
                        labelText="Location Type Description"
                        id="listing-description-location"
                        multiline
                        rowsMax="3"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // disabled: true,
                          required: true,
                          defaultValue: "",
                          name: "location-type-description"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}>
                    <Button simple={true} color="warning" onClick={()=>this.requestListing(false)}>
                      Request Location Type   <Send className={classes.tableActionButtonIcon + " " + classes.edit}/>  
                  </Button>
                    </GridItem>
                    </GridContainer> 
              }
              
        <Snackbar
                    place="tr"
                    color="success"
                    icon={AddAlert}
                    autoHideDuration={5000}
                    message={suggestion ? (<span>Selected address: {suggestion.description}</span>) : ''}
                    open={open}
                    closeNotification={this.onClose}
                    close
                  />


                 {/* <Snackbar
          onClose={this.onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={5000}
          open={open}
          message=
          style={{ width: '70vw' }}
        /> */}

{this.state.programSelect == "9" && this.state.listingSelect == "2" && this.state.requestListingAddition &&
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4} lg={3}>
                      <CustomInput
                        labelText="Program Type Name"
                        id="listing-name-program"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // disabled: true,
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          required: true,
                          defaultValue: "",
                          name: "listing-type-name"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={7}>
                      <CustomInput
                        labelText="Program Type Description"
                        id="listing-description-program"
                        multiline
                        rowsMax="3"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // disabled: true,
                          required: true,
                          defaultValue: "",
                          name: "listing-type-description"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}>
                    <Button simple={true} color="warning" onClick={()=>this.requestListing(true)}>
                      Request program Type   <Send className={classes.tableActionButtonIcon + " " + classes.edit}/>  
                  </Button>
                    </GridItem>
                    </GridContainer> 
              }
              
              
              {/* https://search.earth911.com/suggest/materials/?q=%231 */}

           

{(this.state.programSelect !== "" || this.state.locationSelect !== "") &&
              <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText={listingSelect == "2" ?  "Program Name": "Location Name"}
                        id="listing-name"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          required: true,
                          defaultValue: this.state.listing_name,
                          name: "listing_name"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
}
{this.state.listing_name !== "" && this.state.materialsSelection !== "finalize" &&
<GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                  {this.state.search}
                    </GridItem>
                    </GridContainer>
}
{this.state.listing_name !== "" && this.state.materialsSelection !== "finalize" &&
                    <GridContainer>
                    
                    <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
        control={
          <Switch
            checked={this.state.enterAddressManually}
            onChange={()=>this.enterAddressManually()}
            value="enter_manually"
            color="warning"
          />
        }
        label={this.state.enterAddressManually? "Select to search for a place from Google": "Enter Manually" }
      />
          {/* <Button 
                  onClick={(e)=>this.enterAddressManually()} color="warning">
                  or enter it manually <Edit className={classes.tableActionButtonIcon + " " + classes.edit}/>  
                  </Button>
                   */}
                  
                    </GridItem>
                  </GridContainer>
                  
                }
             
              {this.state.suggestedPlace.length > 0 &&
              
<div>
<div>            
                  <div style={{padding: "20px"}}><h3>
                  <Typography className={classes.heading}>Contact Information</Typography>
                  </h3>
                  </div>
                    <GridContainer> 
                    { this.state.materialsSelection !== "finalize" && this.state.listings_count.new_total > 0 &&
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
           /* style={{width:"100%"}} */
                    labelPlacement="start"
        control={
           <Button simple={true}  color="warning" onClick={(e)=>this.getContactListings()}>
                    Select Listing Contact Information
                  </Button>
        }
        label="Use Existing Listing Contact Information:  "
      />
                    
                    </GridItem>
                    }
                    {contact_listings_select.length > 0 && this.state.materialsSelection !== "finalize" &&
                    <GridItem xs={12} sm={12} md={6}>
                     <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select a Contact
                          </InputLabel>
                    <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    value={this.state.contactListingSelect}
    onChange={(e)=>{this.loading('contact', true);this.handleContactListing(e)}}
    fullWidth={true}
    inputProps={{
      name: "contact_listingSelect",
      id: "simple-select"
    }}
  >
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      Contact Listing
    </MenuItem>
    {contact_listings_select}
    </Select>
    
    </FormControl>
                       </GridItem>
                    }
            <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Phone *"
                        id="listing-phone"
                        fullWidth={true}
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          required: true,
                          defaultValue: this.state.listing_phone,
                          value: this.state.listing_phone,
                          name: "listing_phone"
                        }}
                      />
                      </GridItem>
      
        <GridItem xs={12} sm={12} md={12}>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={selectedDate}
          onChange={this.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider> */}
                        {this.state.listing_hours !== "" &&
                       <CustomInput
                        labelText="Hours"
                        id="listing-hours"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          disabled: true,
                          required: true,
                          defaultValue: this.state.listing_hours,
                          value: this.state.listing_hours,
                          name: "listing_hours"
                        }}
                      /> 
                        }
                     {hours}
                     
                     <CustomInput
                        labelText="Special Hours Notes"
                        id="listing-hours"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}                  
                        inputProps={{
                          
                          placeholder:"Please call for hours on Saturday...",
                          // disabled: true,
                          required: true,
                          // defaultValue:,
                          // value: this.state.listing_hours,
                          name: "listing_hours_notes"
                        }}
                      /> 
                      </GridItem>
                    
        
        <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Listing URL"
                        id="listing-URL"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          onChange: this.handleChange,
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // disabled: true,
                          required: true,
                          value: this.state.listing_url,
                          defaultValue: this.state.listing_url,
                          name: "listing_url"
                        }}
                      />
                      </GridItem>
                    </GridContainer> 

              
                  </div>
                  <GridContainer>
                  {formattedAddress.street_number !== undefined && 
                    <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="Street Number"
                        id="address_street_number"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // required: true,
//                          disabled: true,
                          value: formattedAddress.street_number.l,
                          // value: ""_name,
                          name: "address_street_number"
                        }}
                      />
                    </GridItem>               
                  }
                  
                  {formattedAddress.route !== undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="Street"
                        id="address_route"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // required: true,
                          //disabled: true,
                          value: formattedAddress.route.l,
                          // value: ""_name,
                          name: "address_route"
                        }}
                      />
                    </GridItem>        
                  }  
                  {formattedAddress.street_number !== undefined && formattedAddress.route !== undefined && 
                  
                  <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="Suite/Unit"
                        id="address_suite"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          
                          onChange: this.handleChangeAddress,
                          // required: true,
                          // disabled: tr,
                          // value: formattedAddress.route.l,
                          // value: ""_name,
                          name: "address_suite"
                        }}
                      />
                    </GridItem>   
                  }        
                  </GridContainer>
                  <GridContainer>
                  
                  {formattedAddress.locality !== undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="City"
                        id="address_locality"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // required: true,
                          //disabled: true,
                          value: formattedAddress.locality.l,
                          // value: ""_name,
                          name: "address_locality"
                        }}
                      />
                    </GridItem>       
                  }
                  
                  {formattedAddress.administrative_area_level_1 !== undefined && 
                    <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="State"
                        id="address_administrative_area_level_1"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // required: true,
                          //disabled: true,
                          value: formattedAddress.administrative_area_level_1.l,
                          // value: ""_name,
                          name: "address_administrative_area_level_1"
                        }}
                      />
                    </GridItem>      
                  }

                  {formattedAddress.postal_code !== undefined && 
                    <GridItem xs={12} sm={12} md={4}>
                    
                      <CustomInput
                        labelText="Postal Code"
                        id="address_postal_code"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // required: true,
                          //disabled: true,
                          value: formattedAddress.postal_code_suffix !== undefined ? formattedAddress.postal_code.l+"-"+formattedAddress.postal_code_suffix.l : formattedAddress.postal_code.l,
                          name: "address_postal_code"
                        }}
                      />
                    </GridItem>       
                  }
                  
                  </GridContainer>
                  
                  <GridContainer>
                  {formattedAddress.administrative_area_level_2 !== undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="County"
                        id="address_administrative_area_level_2"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // required: true,
                          //disabled: true,
                          value: formattedAddress.administrative_area_level_2.l,
                          // value: ""_name,
                          name: "address_administrative_area_level_2"
                        }}
                      />
                    </GridItem>          
                  } 
                  {formattedAddress.country !== undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="Country"
                        id="address_country"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          // required: true,
                          //disabled: true,
                          value: formattedAddress.country.s,
                          // value: ""_name,
                          name: "address_country"
                        }}
                      />
                    </GridItem>      
                  }
                               
                  </GridContainer>
                  
                                 
<GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                  <TextField
          id="filled-multiline-static"
          label="Notes * - Visible to the Public"
          multiline
          rows="4"
          placeholder="Public Notes are used to provide information to the public, such as directions to the site or certification information."
          variant="filled"
          fullWidth="true"
          name="address_notes"
          disabled = {this.state.materialsSelection == "finalize" ? true: false}
          onChange= {this.handleChange}
        />
                    </GridItem>
                    {this.state.materialsSelection !== "finalize" &&
                    <GridItem xs={12} sm={12} md={2}>
                    <Button color="success" onClick={()=>this.moveOnToMaterials("yes")}>
                    Move on to Materials selection

                      </Button>

                    </GridItem>
                    }
                  </GridContainer>
                  </div>}
                  
              {this.state.suggestedPlace.length == 0 && this.state.enterAddressManually &&

<div>
<div>            
                  <div style={{padding: "20px"}}><h3>
                  <Typography className={classes.heading}>Contact Information Manually entered</Typography>
                  </h3>
                  </div>
                    <GridContainer> 
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
           /* style={{width:"100%"}} */
                    labelPlacement="start"
        control={
           <Button simple={true}  color="warning" onClick={(e)=>this.getContactListings()}>
                    Select Listing Contact Information
                  </Button>
        }
        label="Existing:  "
      />
                    
                    </GridItem>
                    {contact_listings_select.length > 0 &&
                    <GridItem xs={12} sm={12} md={6}>
                    
                     <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select a Contact
                          </InputLabel>
                    <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    value={this.state.contactListingSelect}
    onChange={(e)=>{this.loading('contact', true);this.handleContactListing(e)}}
    fullWidth={true}
    inputProps={{
      name: "contact_listingSelect",
      id: "simple-select"
    }}
  >
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      Contact Listing
    </MenuItem>
    {contact_listings_select}
    </Select>
    
    </FormControl>
                       </GridItem>
                    }
            <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Phone *"
                        id="listing-phone"
                        fullWidth={true}
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          defaultValue: this.state.listing_phone,
                          value: this.state.listing_phone,
                          name: "listing_phone"
                        }}
                      />
                      </GridItem>
      
        <GridItem xs={12} sm={12} md={12}>
                    {this.state.loading.contact &&
                    <LinearProgress variant="query" />
                    }
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={selectedDate}
          onChange={this.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider> */}
                     
                     {this.state.listing_hours !== "" &&
                       <CustomInput
                        labelText="Hours"
                        id="listing-hours"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          required: true,
                          defaultValue: this.state.listing_hours,
                          value: this.state.listing_hours,
                          name: "listing_hours"
                        }}
                      /> 
                        }
                        
                      {hours}
                      
                      <CustomInput
                        labelText="Special Hours Notes"
                        id="listing-hours"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}                  
                        inputProps={{
                          
                          placeholder:"Please call for hours on Saturday...",
                          // disabled: true,
                          required: true,
                          // defaultValue:,
                          // value: this.state.listing_hours,
                          name: "listing_hours_notes"
                        }}
                      /> 
                      </GridItem>
                    
        
        <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                      labelText="Listing URL"
                        id="listing-URL"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          
                          onChange: this.handleChange,
                          // disabled: true,
                          required: true,
                          value: this.state.listing_url,
                          defaultValue: this.state.listing_url,
                          name: "listing_url"
                        }}
                      />
                      </GridItem>
                    </GridContainer> 

              
                  </div>
                  <GridContainer>
                  {formattedAddress.street_number == undefined && 
                    <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="Street Number"
                        id="address_street_number"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}                        
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          onChange: this.handleChangeAddress,
                          
                          // required: true,
                          
                          //value: formattedAddress.street_number.l,
                          //// value: ""_name,
                          name: "address_street_number"
                        }}
                      />
                    </GridItem>               
                  }
                  
                  {formattedAddress.route == undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="Street"
                        id="address_route"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          onChange: this.handleChangeAddress,
                          // required: true,
                          
                          //value: formattedAddress.route.l,
                          //// value: ""_name,
                          name: "address_route"
                        }}
                      />
                    </GridItem>        
                  }  
                  
                  <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="Suite/Unit"
                        id="address_suite"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          onChange: this.handleChangeAddress,
                          // required: true,
                          // disabled: tr,
                          //// value: formattedAddress.route.l,
                          //// value: ""_name,
                          name: "address_suite"
                        }}
                      />
                    </GridItem>   
                  </GridContainer>
                  <GridContainer>
                  
                  {formattedAddress.locality == undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="City"
                        id="address_locality"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          onChange: this.handleChangeAddress,
                          // required: true,
                          
                          //value: formattedAddress.locality.l,
                          //// value: ""_name,
                          name: "address_locality"
                        }}
                      />
                    </GridItem>       
                  }
                  
                  {formattedAddress.administrative_area_level_1 == undefined && 
                    <GridItem xs={12} sm={12} md={2}>
                    
                      <CustomInput
                        labelText="State"
                        id="address_administrative_area_level_1"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          onChange: this.handleChangeAddress,
                          // required: true,
                          
                          //value: formattedAddress.administrative_area_level_1.l,
                          //// value: ""_name,
                          name: "address_administrative_area_level_1"
                        }}
                      />
                    </GridItem>      
                  }

                  {formattedAddress.postal_code == undefined && 
                    <GridItem xs={12} sm={12} md={4}>
                    
                      <CustomInput
                        labelText="Postal Code"
                        id="address_postal_code"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          onChange: this.handleChangeAddress,
                          // required: true,
                          
                          //value: formattedAddress.postal_code_suffix == undefined ? formattedAddress.postal_code.l+"-"+formattedAddress.postal_code_suffix.l : formattedAddress.postal_code.l,
                          name: "address_postal_code"
                        }}
                      />
                    </GridItem>       
                  }
                  
                  </GridContainer>
                  
                  <GridContainer>
                  {formattedAddress.administrative_area_level_2 == undefined && 
                    <GridItem xs={12} sm={12} md={6}>
                    
                      <CustomInput
                        labelText="County (optional)"
                        id="address_administrative_area_level_2"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.materialsSelection == "finalize" ? true: false,
                          onChange: this.handleChangeAddress,
                          // required: true,
                          
                          //value: formattedAddress.administrative_area_level_2.l,
                          //// value: ""_name,
                          name: "address_administrative_area_level_2"
                        }}
                      />
                    </GridItem>          
                  } 
                 
                               
                  </GridContainer>
                                 
<GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                  <TextField
          id="address_notes"
          label="Notes * - Visible to the Public"
          multiline
          rows="4"
          placeholder="Public Notes are used to provide information to the public, such as directions to the site or certification information."
          variant="filled"
          fullWidth="true"
          name="address_notes"
            onChange= {this.handleChange}
        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                    <Button  onClick={()=>this.moveOnToMaterials("yes")} color="danger">
                    Move on to Materials selection

                      </Button>

                    </GridItem>
                  </GridContainer>
                  </div>
              }
* Denotes optional field
              </div>
              }
              {this.state.materialsSelection == "yes" &&
              <GridContainer>
   
                  <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                    <FormControlLabel
           style={{width:"100%"}}
                    labelPlacement="start"
        control={
           <DebounceInput
           style={{width:"100%", height:"30px", margin: "30px"}}
           minLength={2}
           debounceTimeout={700}
           onKeyPress={(ev) => {
    // console.log(`Pressed keyCode ${ev.key}`);
    if (ev.key === 'Enter') {
      // Do code here
      ev.preventDefault();
    }
  }}
           onChange={event => this.searchMaterials(event)} 
           element={TextField}
           /* element={CustomInput} */
           />
        }
        label="Search for a material:  "
      />
      
      {this.state.loading.materials_search &&
                    <LinearProgress variant="query" />
                    }
      {!this.state.nothing_found &&
      <small style={{fontStyle: 'italic'}}>
                  Can't find your material? <Button color="primary" simple={true} onClick={()=>this.setState({requestMaterialAddition: true})}>
Request it be added.
                                                         <AddAlert className={classes.tableActionButtonIcon + " " + classes.edit}/>
    </Button>
                    </small>
      }
      {this.state.nothing_found &&
                    <p>
                      The search didn't return any results. Please try another search term. <Button color="primary" simple={true} onClick={()=>this.setState({requestMaterialAddition: true})}>
Request Material to be added.
                                                         <AddAlert className={classes.tableActionButtonIcon + " " + classes.edit}/>
    </Button>
                    </p>
                    }  
                    {this.state.requestMaterialAddition && 
                    
                    <div>
                      <h4>
                      Please enter the material name and description that you would like added
                      </h4>
                        </div>
                    }
                    {this.state.requestMaterialAddition && 
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={4} lg={3}>
                      <CustomInput
                        labelText="Material Name"
                        id="material-name"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // disabled: true,
                          required: true,
                          defaultValue: "",
                          name: "program-type-name"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={7}>
                      <CustomInput
                        labelText="Material Description"
                        id="material-description"
                        multiline
                        rowsMax="3"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // disabled: true,
                          required: true,
                          defaultValue: "",
                          name: "program-type-description"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}>
                    
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<Send size={"large"}className={classes.tableActionButtonIcon + " " + classes.edit}/>}
        onClick={()=>this.requestMaterial()}>
                    Send Request   
                                                     </Button>
                    </GridItem>
                    </GridContainer> 
                    }  
                   
                      {/*  */}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
           style={{width:"100%"}}
                    labelPlacement="start"
        control={
           <Button simple={true}  color="warning" onClick={(e)=>this.getListings()}>
                    Select Listing
                  </Button>
        }
        label="Or you preset through another listing:  "
      />
                    
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
           style={{width:"100%"}}
                    labelPlacement="start"
        control={
           <Button simple={true}  color="warning" onClick={(e)=>this.getFamilies()}>
                    Select through category
                  </Button>
        }
        label="Or you can select through the categories:  "
      />
                    
                    </GridItem>
                    {listings_select.length > 0 && this.state.currentlySelecting == "listings" &&
                    <GridItem xs={12} sm={12} md={4}>
                     <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select a Listing
                          </InputLabel>
                    <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    value={this.state.listingContactSelect}
    onChange={this.handleListing}
    fullWidth={true}
    inputProps={{
      name: "listingContactSelect",
      id: "simple-select"
    }}
  >
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      Listing
    </MenuItem>
    {listings_select}
    </Select>
    
    </FormControl>
    
    {this.state.listingContactSelect != 0 &&
    <Button onClick={()=>this.addAll()} color="primary">
    Add all from listing
    </Button>
    }
                       </GridItem>
                       
                    }
                    {families.length > 0 && this.state.currentlySelecting == "families" &&
                    <GridItem xs={12} sm={12} md={4}>
                     <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select a Category
                          </InputLabel>
                    <Select
    MenuProps={{
      className: classes.selectMenu
    }}
    classes={{
      select: classes.select
    }}
    value={this.state.familySelect}
    onChange={this.handleFamily}
    fullWidth={true}
    inputProps={{
      name: "familySelect",
      id: "simple-select"
    }}
  >
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      Type of Category
    </MenuItem>
    {families_select}
    </Select>
    
    </FormControl>
                       </GridItem>
                    }
                     
                  </GridContainer>
                  <GridContainer>
                 
                  </GridContainer>
                  </div>
                  

                  <div>
                  
              <GridContainer>
                    {materials.map(m=>{                      
                    return <GridItem xs={12} sm={12} md={4} key={m}>
                    {m}
                    </GridItem>
                    })}
                   
                  </GridContainer>

                  {family.length == 0 && materials.length == 0 &&
                  
                  <div>
                  <GridContainer>

                    
                    {families.map(m=>{                      
                    return <GridItem xs={12} sm={12} md={4} key={m}>
                    {m}
                    </GridItem>
                    })}
                   
                       
                  </GridContainer>
                  </div>
                  }
                  {family.length > 0 &&
                  <GridContainer>
                    {family.map(m=>{                      
                    return <GridItem xs={12} sm={12} md={4} key={m}>
                    {m}
                    </GridItem>
                    })}
                    <Button simple={true} onClick={this.backToAllFamilies} color="warning">Back to all Categories</Button>
                  </GridContainer>
                  }
                       
                       </div>
                       <div>
                       {added_materials.length > 0 &&
                <h3>Materials Added</h3>
                       }
              <GridContainer>
                    {added_materials.map(m=>{                      
                    return <GridItem xs={12} sm={12} md={4} key={m}>
                    {m}
                    </GridItem>
                    })}
                  </GridContainer>
                  </div>
                      
                  
                  </GridContainer>
                  }
                   <div>
                           <GridContainer>
                       <GridItem xs={12} sm={12} md={12}>
                       {this.state.added_materials.length > 0 && this.state.materialsSelection == "yes" &&
                    <Button  onClick={() => this.moveOnToMaterials("options")} color="success">
                    Move on to Material(s) options

                      </Button>
                       }

                    </GridItem>
                         
                  </GridContainer>
                       </div>
                   {(this.state.materialsSelection == "options" || this.state.materialsSelection == "finalize")  &&
              <GridContainer>
                
                {this.state.materialsSelection !== "finalize" &&
   <GridItem xs={12} sm={12} md={12}>
                <Button color="success" onClick={()=>this.moveOnToMaterials("finalize")}>
                    Review and Complete
                  </Button>
   </GridItem>
                }
                {this.state.materialsSelection !== "finalize" &&
   <GridItem xs={12} sm={12} md={3}>
   <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Set All Residential
                          </InputLabel>
            <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.residentialSelect}
                            onChange={this.handleSimpleResidential}
                            inputProps={{
                              name: "residentialSelect",
                              id: "simple-select"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              Select
                            </MenuItem>
                            
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="none"
                          >
                          None
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="pickup"
                          >
                          Pick-up
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="dropoff"
                          >
                          Drop-off
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="both"
                          >
                          Both
                          </MenuItem>
                          </Select>
                            </FormControl>
                            </GridItem>
                }
                
                {this.state.materialsSelection !== "finalize" &&
   <GridItem xs={12} sm={12} md={3}>
   <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Set All Business
                          </InputLabel>
            <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.businessSelect}
                            onChange={this.handleSimpleBusiness}
                            inputProps={{
                              name: "businessSelect",
                              id: "simple-select"
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                            Select
                            </MenuItem>
                            
                            
                            
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="none"
                          >
                          None
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="pickup"
                          >
                          Pick-up
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="dropoff"
                          >
                          Drop-off
                          </MenuItem>
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="both"
                          >
                          Both
                          </MenuItem>
                            </Select>
                            </FormControl>
   </GridItem>
                }
                  
   <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    // "Name", "City", "Status", 
                    "Material", "Residential", "Business", "Special Notes"]}
                  tableData={ added_materials_table } 
                />
                
                {this.state.materialsSelection !== "finalize" &&
                <Button color="success" onClick={()=>this.moveOnToMaterials("finalize")}>
                    Review and Complete
                  </Button>
                }
                  </GridContainer>
                  }
                   {this.state.materialsSelection == "finalize" &&
              <GridContainer>
   
   <GridContainer>
   <GridItem xs={12} sm={12} md={3}>
   <Button color="success" onClick={(e)=>this.markTaskAsValid()}>
   Done
 </Button>
 </GridItem>
   <GridItem xs={12} sm={12} md={3}>
   <Button color="warning" onClick={(e)=>this.validateListing()}>
   Edit
 </Button>
 
 </GridItem>
   <GridItem xs={12} sm={12} md={3}>
   <Button color="danger" onClick={(e)=>this.destroyListing()}>
   Delete
 </Button>
 
 </GridItem>
 
 <GridItem xs={12} sm={12} md={3}>
 <Button color="success" onClick={()=>this.addAnotherListing()}>
    Add another
  </Button> 
                    </GridItem>
   
                    </GridContainer>
                  </GridContainer>
                  }

                
                <CardFooter>
                  
                  { this.state.completedAddress &&
                <Button type="submit" color="success" onClick={(e)=>this.markAsValid(e, 1)}>
                    Add Location
                  </Button>
                  }
                  {/* <Link to={`/update/Location`} activeClassName="active">
                  <Button type="submit" color="warning">
                    Update Profile
                  </Button>
                  </Link> */}
                </CardFooter>
              
            </form>
          </GridItem>
        </GridContainer>


              </CardBody>
            </Card>
          </GridItem>
        
          
        </GridContainer>
        }
<div id="map">
                      <ul id="places">
                        </ul>
                      </div>
      </div>
    );
  }
}

AddListing.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(AddListing);
