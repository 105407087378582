import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Checkbox from "@material-ui/core/Checkbox";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Tooltip from "@material-ui/core/Tooltip";

import IconButton from "@material-ui/core/IconButton";
import Table from "components/Table/Table.jsx";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import avatar from "assets/img/faces/marc.jpg";
import {apiSetupHeaders, apiStoreHeaders} from "global/functions.js"

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const { REACT_APP_SERVER_URL } = process.env;

class SupportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_user: false,
      listings: [],
      addUser:{
        next: "Skip",
        add: "Add"
      },
      errors: {},
      groups: [],
      your_groups: [],
      logo: '',
      new_logo: '',
      fromTask: false,
      access_all: true,
      admin: true,
      profile: {
        first_name: "",
        last_name: "",
        organization: "",
        phone: "",
        email: ""
        // activation_key: "ALREADY_ACTIVATED"
        // affiliations: ""
        // affiliations_other: ""
        // crm_id: ""
        // email: "amandar@ddsd.org"
        // first_name: "Amanda"
        // id: 31
        // last_name: "Roa"
        // organization: "Delta Diablo Sanitation District"
        // phone: "(925) 756-1940"
        // tos_agreed: 5
        // user_id: 45
      }
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.updateProfileInfo = this.updateProfileInfo.bind(this);
  }
  
  onChangeHandler=event=>{
    // console.log("CHANBGE")
        this.setState({new_logo: event.target.files[0]})
    
    }
  componentDidMount(){
    // this.getProfileData()
    
    // let token =this.props.location.search;
    // if(token.indexOf('task=true') !== -1)    
    // if(token.indexOf('new=true') !== -1)
    // this.setState({fromTask: true, new_user: true})
    // else
    // this.setState({fromTask: true, new_user: false})

  }
  updateProfile = async(e) => {
    e.preventDefault();
    const { history } = this.props;

    // const fields = ["new_email"];
    // const formElements = e.target.elements;
    // const formValues = fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    
    const data = new FormData() 
    data.append('picture', this.state.new_logo)
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/org_profile/logo`, data,
        // {
        //   profile: formValues
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    
    this.getProfileData()
    // if(registerRequest.status > 205){
    //     console.log(registerRequestData) // do something here
    // }else{
        
    //     apiStoreHeaders(registerRequest.headers)
    //     return history.push("/update/earth911");
        
    // }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  async updateProfileInfo(e) {
    e.preventDefault();
    apiSetupHeaders()
    const { history } = this.props;

    const fields = ["subject", "message"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));
    // let payload = formValues
    let profileRequest;
    try {
      profileRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/organization/inquiry`,
        {
          payload: formValues
        },
        {
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    
    if (profileRequest.status >= 200 && profileRequest.status < 299)  {
     
    //   let tasks = JSON.parse(localStorage.getItem('tasks'))
    //   tasks.contact =  profileRequestData.tasks
    // localStorage.setItem('tasks', JSON.stringify(tasks));
    
    // if(!profileRequestData.completed){    
    // if(this.state.new_user)
    //   return history.push("/update/tasks/organization-task?new=true");
    // else      
    //   return history.push("/update/tasks/organization-task");
    // }
    // else
    // {
    //   if(this.state.fromTask)
    //   this.props.history.push("/update/tasks/listing-task");
    //   else
      this.props.history.push("/update/earth911");
    // }
    
    
    // this.setState({profile: profileRequestData.profile})
    }

    // if (!registerRequestData.success) {
    //   this.setState({
    //     errors:
    //       registerRequestData.messages && registerRequestData.messages.errors
    //   });
    // }
  }
  getProfileData = async e => {
    apiSetupHeaders()
    // e.preventDefault();

    // const { history } = this.props;

    // // const fields = ["email", "password"];
    // // const formElements = e.target.elements;

    // // const formValues = fields
    // //   .map(field => ({
    //     // [field]: formElements.namedItem(field).value
    // //   }))
    // //   .reduce((current, next) => ({ ...current, ...next }));

    // if(!apiSetupHeaders()){
    //     return history.push("/auth/login-page");
    // }

    let profileRequest;
    
    try {
      profileRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/profile`,
        {
        //   ...formValues
        },
        {
            // headers: {'access-token': 'test2'},
          // withCredentials: true
        }
      );
    } catch ({ response }) {
      profileRequest = response;
    }
    const { data: profileRequestData } = profileRequest;
    if (profileRequest.status == 200) {
      // console.log("updated headers", profileRequest.headers)
        apiStoreHeaders(profileRequest.headers)
        this.setState({profile: profileRequestData.profile, logo: "http://listing-api.earth911.com"+profileRequestData.org, groups: profileRequestData.org_groups, your_groups: profileRequestData.your_groups })
    }else{
        // return history.push("/auth/login-page");
    }
    // this.setState({
    // //   errors: loginRequestData.messages && loginRequestData.messages.errors
    // });
  };
  
  resetAddUser = () =>{
    this.getListings()
    const fields = ["first_name", "last_name", "added_email"];
    fields.forEach((id)=>{
      document.getElementById(id).value = ""
    })
    this.setState({access_all: true, admin: true})
  }
  handleChange = (index, transfer) => {
    if(!transfer){
    let access_checked = this.state.accessChecked
    access_checked[index] = !access_checked[index]
    this.setState({accessChecked: access_checked});
    }else{
      let transfer_checked = this.state.transferChecked
      transfer_checked[index] = !transfer_checked[index]
      this.setState({transferChecked: transfer_checked});
    }
  };
  getListings = async () => {
    let taskRequest;
    try {
      taskRequest = await axios.get(
        `${REACT_APP_SERVER_URL}/v1/materials/listings`,
        // {
          // task: {id: task.task.id}
        // },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      taskRequest = response;
    }

    const { data: taskRequestData } = taskRequest;
    let outbound = []
    let listing_count = 0;
    let accessChecked = []
    let transferChecked = []

    for(let i = 0; i < taskRequestData.listings.programs.length; i++){
      taskRequestData.listings.programs[i].index = listing_count
      listing_count = listing_count + 1
      taskRequestData.listings.programs[i].type = "program"
      outbound.push(taskRequestData.listings.programs[i])
      accessChecked.push(false)
      transferChecked.push(false)
    }
    for(let i = 0; i < taskRequestData.listings.locations.length; i++){
      taskRequestData.listings.locations[i].index = listing_count
      listing_count = listing_count + 1
      taskRequestData.listings.locations[i].type = "location"
      outbound.push(taskRequestData.listings.locations[i])
      accessChecked.push(false)
      transferChecked.push(false)
    }
    this.setState({listings: outbound, accessChecked: accessChecked, transferChecked: transferChecked})
    apiStoreHeaders(taskRequest.headers)

  }
  addUser = async (e) => {
    e.preventDefault();

    let access_all = this.state.access_all
    let access = []
    let transfer = []


    if(!access_all){
      for(let i = 0; i<this.state.listings.length; i++){
        if(this.state.accessChecked[this.state.listings[i].index])
          access.push(this.state.listings[i].uuid)      
        if(this.state.transferChecked[this.state.listings[i].index])
          transfer.push(this.state.listings[i].uuid)          
          // console.log(this.state.listings[i].index)
          // console.log(this.state.actualChecked[this.state.listings[i].index])
      }
    }else{
      access = 'all'
      transfer = 'none'
    }

    // console.log(access)

    const fields = ["first_name", "last_name", "added_email"];
    const formElements = e.target.elements;
    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${REACT_APP_SERVER_URL}/v1/profile/add_user`,
        {
          profile: formValues, access: access, transfer: transfer, admin: this.state.admin
        },
        {
        //   withCredentials: true
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;

    if (!registerRequestData.success) {
      this.setState({ errorAlert: true, alertMessage: registerRequestData.messages.errors.email})
      setTimeout(()=>this.setState({ errorAlert: false}), 6000)
      this.setState({
        errors:
          registerRequestData.messages && registerRequestData.messages.errors
          
      });
    }else{
      this.resetAddUser()
      this.setState({ successAlert: true, alertMessage: "Great, we've added that User! Feel free to either add another User or move-on if you are all set."})
      setTimeout(()=>this.setState({ successAlert: false}), 6000)
      
    }
  }
  
  toggleAccessAll = () =>{
    this.setState({access_all: !this.state.access_all})
    if(this.state.access_all)
    this.getListings()

  }
  toggleAdmin = () =>{
    this.setState({admin: !this.state.admin})
    // if(this.state.access_all)
    // this.getListings()

  }
  render() {
    const { classes, name, email } = this.props;
    const { errors } = this.state;

          
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <form onSubmit={this.updateProfileInfo}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Support</h4>
                  <p className={classes.cardCategoryWhite}>
                    Send us your questions
                  </p>
                </CardHeader>
                <CardBody>                             
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Subject"
                        id="email-address"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          defaultValue: this.state.profile.email,
                          name: "subject"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}
                      style={{backgroundColor:"rgba(255, 255, 255, 0.83)"}}>
                      <CustomInput
                      style={{backgroundColor:"rgba(255, 255, 255, 0.83)"}}
                        labelText="Message"
                        id="email-address"
                        error={errors.username}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          
          multiline:true,
                            rows:"6",
                            type: "textarea",
                          required: true,
                          defaultValue: this.state.profile.email,
                          name: "message"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="primary">
                    Send
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SupportForm.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
};

export default withStyles(styles)(SupportForm);
